<template>
    <div class="delete-event-form should-dim modify-form bg-gray-50 border rounded-sm border-solid border-gray-300 p-sm">
        <div>
            <template v-if="event.name">
                Are you sure you want to delete <strong>{{ event.name }}</strong>?
            </template>
            <template v-else>
                Are you sure you want to delete this event?
            </template>
        </div>
        <div v-if="recurring" class="m-t-sm">
            <p>This is a <strong>recurring event</strong>. What would you like to delete?</p>
            <div>
                <label>
                    <input type="radio" value="single" id="only_this_event" v-model="recurringPick"> Only this event
                </label>
            </div>
            <div>
                <label>
                    <input type="radio" value="all" id="all_related_events" v-model="recurringPick"> All events
                </label>
            </div>
        </div>
        <div class="flex flex-col justify-space-between gap-sm m-t-md">
            <div class="w-full">
                <ladda-button @lbClicked="deleteEventPressed" el-class="btn-danger w-full" :loading="loading">
                    Yes, Delete
                </ladda-button>
            </div>
            <button v-on:click="hideEventDeleteForm" class="btn btn-default w-full">
                Cancel
            </button>
            <div v-show="error" class="col-span-2">
                <small>
                    <span class="text-danger">{{ errorMessage }}</span>
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'delete-event-form',
  components: { LaddaButton },
  props: {
    event: {
      type: Object,
      required: true
    },
    recurring: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false, // Loading is a network variable (hitting the api)
      error: false,
      errorMessage: 'Error, please try again later or contact support@wrestlingiq.com',
      recurringPick: 'single',
    };
  },
  methods: {
    hideEventDeleteForm() {
      const vm = this;
      vm.$notificationManager.$emit('hide-delete-event-form', vm.event);
    },
    deleteEventPressed: _.throttle(function () {
      const vm = this;
      // If this is a duplicate request, just return
      if (vm.loading === true) {
        return;
      }
      vm.loading = true;

      let url = vm.$apiService.eventUrl(vm.event.id);
      const deleteRecurring = vm.recurringPick === 'all';
      if (deleteRecurring) {
        // delete all recurring events
        url = vm.$apiService.deleteEventRecurringUrl(vm.event.id);
      }

      axios.delete(url)
        .then((response) => {
          vm.loading = false;
          
          vm.$notificationManager.$emit('show-toast', 'Event deleted', true);
          vm.$notificationManager.$emit('event-deleted', vm.event, deleteRecurring);
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = 'Error, please try again later or contact support.';
          vm.error = true;
        });
    }, 500),
  },
};
</script>
