<template>
  <div>
    <div class="flex flex-wrap gap-x-lg gap-x-xs-xs-only gap-y-sm align-items-end">
      <div class="form-group m-b-none">
        <label class="font-normal">Viewing</label>
        <select v-model="range" id="payment-chart-range" class="form-control" name="range">
          <option value="today">Today</option>
          <option value="7d">Last 7 days</option>
          <option value="4w">Last 4 weeks</option>
          <option value="3m">Last 3 months</option>
          <option value="12m">Last 12 months</option>
          <option value="year to date">Year to date</option>
        </select>
      </div>
      <div class="form-group m-b-none">
        <label class="font-normal">Grouped</label>
        <select v-model="interval" id="payment-interval" class="form-control" name="interval">
          <option v-for="i in availableIntervals" :value="i">{{ capitalize(i) }}</option>
        </select>
      </div>
      <div class="form-group m-b-none">
        <label class="font-normal">Compared to</label>
        <select id="payment-comparison" class="form-control" name="comparison">
          <option value="previous-period">Previous period</option>
        </select>
      </div>
    </div>
    <div class="relative grid grid-cols-3 gap-24 sm-grid-cols-1 md-grid-cols-1 border-t border-gray-200 border-solid m-t-lg p-t-md">
      <div>
        <h2 class="font-heavy m-t-sm">Charge Metrics</h2>
        <p>Basic revenue metrics for your team. Keep scrolling for the payment feed<span v-if="showRecurring"> and recurring subscription metrics</span>.</p>
      </div>
      <div class="grid grid-cols-2 md-grid-cols-1 sm-grid-cols-1 gap-8 col-span-2">
        <div>
          <metrics-chart :key="keyFor('gross')" chart-key="gross" :endpoint="this.grossUrl"></metrics-chart>
        </div>
        <div>
          <metrics-chart :key="keyFor('net')" chart-key="net" :endpoint="this.netUrl"></metrics-chart>
        </div>
        <div>
          <metrics-chart :key="keyFor('charge-count')" chart-key="charge-count" :endpoint="this.chargeCountUrl"></metrics-chart>
        </div>
        <div>
          <metrics-chart :key="keyFor('charge-avg')" chart-key="charge-avg" :endpoint="this.chargeAvgUrl"></metrics-chart>
        </div>
      </div>
    </div>
    <div v-if="showRecurring" class="relative grid grid-cols-3 gap-24 sm-grid-cols-1 md-grid-cols-1 border-t border-gray-200 border-solid m-t-lg p-t-md">
      <div>
        <h2 class="font-heavy m-t-sm">Recurring Subscription Metrics</h2> 
        <p>Understand your recurring subscription business model, including new subscriptions and cancellations.</p>
      </div>
      <div class="grid grid-cols-2 md-grid-cols-1 sm-grid-cols-1 gap-12 col-span-2">
        <div class="relative">
          <metrics-chart :key="keyFor('new-subscriptions')" chart-key="new-subscriptions" :endpoint="this.newSubscriptionsUrl"></metrics-chart>
          <div class="-m-t-sm z-10 relative">
            <a @click.prevent="openFeed('BillingSubscriptionEvent', 'event_name_eq', 'subscription.created')" class="underline font-heavy text-blue-700 flex align-items-center justify-center gap-sm">
              <span>View new subscriptions {{ humanizedRange }}</span> <i class="fa fa-angle-right"></i>
            </a>
          </div>
        </div>
        <div>
          <metrics-chart :key="keyFor('cancelled-subscriptions')" chart-key="cancelled-subscriptions" :endpoint="this.canceledSubscriptionsUrl"></metrics-chart>
          <div class="-m-t-sm z-10 relative">
            <a @click.prevent="openFeed('BillingSubscriptionEvent', 'event_name_eq', 'subscription.canceled')" class="underline font-heavy text-blue-700 flex align-items-center justify-center gap-sm">
              <span>View cancelled subscriptions {{ humanizedRange }}</span> <i class="fa fa-angle-right"></i>
            </a>
          </div>
        </div>
        <div v-if="showRenewed">
          <metrics-chart :key="keyFor('renewed-subscriptions')" chart-key="renewed-subscriptions" :endpoint="this.renewedSubscriptionsUrl"></metrics-chart>
          <div class="-m-t-sm z-10 relative">
            <a @click.prevent="openFeed('BillingSubscriptionEvent', 'event_name_eq', 'subscription.renewed')" class="underline font-heavy text-blue-700 flex align-items-center justify-center gap-sm">
              <span>View renewed subscriptions {{ humanizedRange }}</span> <i class="fa fa-angle-right"></i>
            </a>
          </div>
        </div>
        <div>
          <metrics-chart :key="keyFor('active-subscribers')" chart-key="active-subscribers" :endpoint="this.activeSubscribersUrl"></metrics-chart>
        </div>
        <div>
          <metrics-chart :key="keyFor('mrr')" chart-key="mrr" :endpoint="this.mrrUrl"></metrics-chart>
        </div>
        <div>
          <metrics-chart :key="keyFor('rev-per-sub')" chart-key="rev-per-sub" :endpoint="this.revSubscriberUrl"></metrics-chart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MetricsChart from './metrics_chart.vue';

export default {
  name: "payment-charts",
  components: { MetricsChart },
  props: {
    showRecurring: {
      type: Boolean,
      default: false,
    },
    showRenewed: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      range: "7d",
      interval: "daily",
      currentlyExploring: null,
    }
  },
  computed: {
    grossUrl() {
      return this.$apiService.grossVolume(this.range, this.interval);
    },
    netUrl() {
      return this.$apiService.netVolume(this.range, this.interval);
    },
    activeSubscribersUrl() {
      return this.$apiService.activeSubscribers(this.range, this.interval);
    },
    mrrUrl() {
      return this.$apiService.mrr(this.range, this.interval);
    },
    newSubscribersUrl() {
      // Deprecated
      return this.$apiService.newSubscribers(this.range, this.interval);
    },
    newSubscriptionsUrl() {
      return this.$apiService.newSubscriptions(this.range, this.interval);
    },
    canceledSubscriptionsUrl() {
      return this.$apiService.canceledSubscriptions(this.range, this.interval);
    },
    renewedSubscriptionsUrl() {
      return this.$apiService.renewedSubscriptions(this.range, this.interval);
    },
    revSubscriberUrl() {
      return this.$apiService.revPerSubscriber(this.range, this.interval);
    },
    chargeCountUrl() {
      return this.$apiService.chargeCount(this.range, this.interval);
    },
    chargeAvgUrl() {
      return this.$apiService.chargeAvg(this.range, this.interval);
    },
    availableIntervals() {
      if (this.range === 'today') {
        return ["hourly"]
      } else if (this.range === "7d") {
        return ["daily"]
      } else if (this.range === "4w") {
        return ["daily", "weekly"]
      } else {
        return ["monthly"]
      }
    },
    humanizedRange() {
      if (this.range === 'today') {
        return 'today';
      } else if (this.range === '7d') {
        return 'in the last 7 days';
      } else if (this.range === '4w') {
        return 'in the last 4 weeks';
      } else if (this.range === '3m') {
        return 'in the last 3 months';
      } else if (this.range === '12m') {
        return 'in the last 12 months';
      } else if (this.range === 'year to date') {
        return 'in the year to date';
      }
    }
  },
  watch: {
    range(newRange) {
      if (newRange === 'today') {
        this.interval = "hourly"
      } else if (newRange === "7d") {
        this.interval = "daily"
      } else if (newRange === "4w") {
        this.interval = "weekly"
      } else if (newRange === '3m' || newRange === '12m' || newRange === 'year to date') {
        this.interval = "monthly"
      }

      this.$notificationManager.$emit('chart-range-changed', newRange, this.interval);
    }
  },
  methods: {
    keyFor(chartName) {
      return `${chartName}-${this.range}-${this.interval}`;
    },
    capitalize(name) {
      return _.capitalize(name);
    },
    openFeed(objectType, primaryPredicateKey, primaryPredicateValue) {
      const createdAtStart = this.range === 'today' ? moment().startOf('day') :
                          this.range === '7d' ? moment().subtract(7, 'days').startOf('day') :
                          this.range === '4w' ? moment().subtract(4, 'weeks').startOf('day') :
                          this.range === '3m' ? moment().subtract(3, 'months').startOf('day') :
                          this.range === '12m' ? moment().subtract(12, 'months').startOf('day') :
                          moment().startOf('year');
      const createdAtEnd = moment().endOf('day');

      this.currentlyExploring = {
        objectType: objectType,
        primaryPredicateKey: primaryPredicateKey,
        primaryPredicateValue: primaryPredicateValue,
        createdAtStart: createdAtStart,
        createdAtEnd: createdAtEnd,
      }
      this.$notificationManager.$emit('open-feed', this.currentlyExploring);
    }
  }
};
</script>
