<template>
  <!-- :headers="['Wrestler(s)', 'Billed to', 'Amount', 'Extras', 'Actions']" -->
  <tbody class="border border-gray-100 border-b border-t-none border-solid">
  <tr class="relative" :class="{ 'bg-gray-50': showMarkPaidForm || showMarkVoidForm || showCancelForm || showRemoveWrestlerForm }">
    <td class="text-gray-700 cell-max-w-250-lg" data-title="Wrestler(s)">
      <div v-for="(registration, index) in result.registrations" :class="index > 0 ? 'm-t-xxs' : ''">
        <span v-if="registration.profile.type === 'Customer'">
          {{ registration.profile.display_name }}
          <span v-if="registration.profile.weight_class">
            ({{ registration.profile.weight_class }})
          </span>
        </span>
        <span v-else>
          <div class="flex flex-wrap gap-x-m" :class="(registration.status === 'canceled' && result.status !== 'canceled') ? 'text-red-700 text-strikethrough' : ''">
            <a :href="`/wrestlers/${registration.profile.id}`">
              {{ registration.profile.display_name }}
            </a>
            <wrestler-rosters :tags-only="true" :for-wrestler-profile="registration.profile" :can-edit="allowEdits"></wrestler-rosters>
          </div>
          <span v-if="(registration.status === 'canceled' && result.status !== 'canceled')">Canceled. Other registrant(s) in good standing.</span>
        </span>
      </div>
    </td>
    <td class="text-gray-700" data-title="Billed to">
      <div v-html="parentName"></div>
    </td>
    <td class="text-gray-700" data-title="Payment info">
      <div class="flex flex-col gap-y-xxs">
        <div>
          {{ paymentOptionName }}
        </div>
        <div class="flex flex-wrap gap-x-xs align-items-center" style="margin-bottom: -4px;" v-if="hasDonation || couponApplied || purchasedUpsell || insurancePolicies.length > 0">
          <div v-if="hasDonation">
            <VDropdown theme="info-tooltip">
              <!-- This will be the popover target (for the events and position) -->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-6 h-6 text-red-500">
                <path d="M2 6.342a3.375 3.375 0 0 1 6-2.088 3.375 3.375 0 0 1 5.997 2.26c-.063 2.134-1.618 3.76-2.955 4.784a14.437 14.437 0 0 1-2.676 1.61c-.02.01-.038.017-.05.022l-.014.006-.004.002h-.002a.75.75 0 0 1-.592.001h-.002l-.004-.003-.015-.006a5.528 5.528 0 0 1-.232-.107 14.395 14.395 0 0 1-2.535-1.557C3.564 10.22 1.999 8.558 1.999 6.38L2 6.342Z" />
              </svg>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>
                  <p class="header">
                    Donated {{ donationAmount }}
                  </p>
                  <p>
                    Via the WIQ Donation Assistant
                  </p>
                  <p>
                  </p>
                </div>
              </template>
            </VDropdown>
          </div>
          <div v-if="couponApplied">
            <VDropdown theme="info-tooltip">
              <!-- This will be the popover target (for the events and position) -->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-purple-600">
                <path fill-rule="evenodd" d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 0 1-.375.65 2.249 2.249 0 0 0 0 3.898.75.75 0 0 1 .375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 17.625v-3.026a.75.75 0 0 1 .374-.65 2.249 2.249 0 0 0 0-3.898.75.75 0 0 1-.374-.65V6.375Zm15-1.125a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75Zm.75 4.5a.75.75 0 0 0-1.5 0v.75a.75.75 0 0 0 1.5 0v-.75Zm-.75 3a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-1.5 0v-.75a.75.75 0 0 1 .75-.75Zm.75 4.5a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-.75ZM6 12a.75.75 0 0 1 .75-.75H12a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 12Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clip-rule="evenodd" />
              </svg>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>
                  <p class="header">
                    Scholarship code {{ couponApplied.code }} applied
                  </p>
                  <p>
                    {{ couponAmountOff }} off
                  </p>
                </div>
              </template>
            </VDropdown>
          </div>
          <div v-if="purchasedUpsell">
            <VDropdown theme="info-tooltip">
              <!-- This will be the popover target (for the events and position) -->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-green-600">
                <path fill-rule="evenodd" d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 0 0 4.25 22.5h15.5a1.875 1.875 0 0 0 1.865-2.071l-1.263-12a1.875 1.875 0 0 0-1.865-1.679H16.5V6a4.5 4.5 0 1 0-9 0ZM12 3a3 3 0 0 0-3 3v.75h6V6a3 3 0 0 0-3-3Zm-3 8.25a3 3 0 1 0 6 0v-.75a.75.75 0 0 1 1.5 0v.75a4.5 4.5 0 1 1-9 0v-.75a.75.75 0 0 1 1.5 0v.75Z" clip-rule="evenodd" />
              </svg>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>
                  <p class="header">
                    Purchased {{ purchasedUpsell.description }}
                  </p>
                  <p>
                    Add-on was purchased during registration
                  </p>
                </div>
              </template>
            </VDropdown>
          </div>
          <div v-for="policy in insurancePolicies">
            <VDropdown theme="info-tooltip">
              <!-- This will be the popover target (for the events and position) -->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="h-6 w-6 text-blue-700">
                <path fill-rule="evenodd" d="M8.5 1.709a.75.75 0 0 0-1 0 8.963 8.963 0 0 1-4.84 2.217.75.75 0 0 0-.654.72 10.499 10.499 0 0 0 5.647 9.672.75.75 0 0 0 .694-.001 10.499 10.499 0 0 0 5.647-9.672.75.75 0 0 0-.654-.719A8.963 8.963 0 0 1 8.5 1.71Zm2.34 5.504a.75.75 0 0 0-1.18-.926L7.394 9.17l-1.156-.99a.75.75 0 1 0-.976 1.138l1.75 1.5a.75.75 0 0 0 1.078-.106l2.75-3.5Z" clip-rule="evenodd" />
              </svg>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>
                  <p class="header">
                    Purchased {{ policy.product_friendly_name }}
                  </p>
                  <p>
                    Policy # {{ policy.policy_number }}
                  </p>
                  <p>
                  </p>
                </div>
              </template>
            </VDropdown>
          </div>
        </div>
        <div v-for="invoice in result.manual_invoices" class="">
          {{ subhead(invoice) }}
          <template v-if="invoice.receipt !== null">
            <a :href="invoice.receipt.cloudfront_url" target="_blank"><i class="fa fa-file-text-o"></i></a>
          </template>
        </div>
      </div>
    </td>
    <td class="cell-max-w-250-lg" data-title="Actions">
      <div v-if="allowEdits" class="flex flex-col gap-y-xs">
        <div v-if="notShowingEditForms && eligibleForCash">
          <button @click="showMarkPaidForm = true"
                  class="btn no-underline btn-compact bg-green-50 border border-green-400 text-green-800 flex justify-space-between align-items-center gap-x-xxs">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3Zm9 3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-6.25-.75a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM11.5 6A.75.75 0 1 1 13 6a.75.75 0 0 1-1.5 0Z" clip-rule="evenodd" />
              <path d="M13 11.75a.75.75 0 0 0-1.5 0v.179c0 .15-.138.28-.306.255A65.277 65.277 0 0 0 1.75 11.5a.75.75 0 0 0 0 1.5c3.135 0 6.215.228 9.227.668A1.764 1.764 0 0 0 13 11.928v-.178Z" />
            </svg>

            <span class="text-12">
              Mark as paid
              </span>
          </button>
        </div>
        <div v-if="notShowingEditForms && eligibleForVoid">
          <button @click="showMarkVoidForm = true"
                  class="btn no-underline btn-compact bg-red-50 border border-red-400 text-red-700 flex justify-space-between align-items-center gap-x-xxs">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm2.78-4.22a.75.75 0 0 1-1.06 0L8 9.06l-1.72 1.72a.75.75 0 1 1-1.06-1.06L6.94 8 5.22 6.28a.75.75 0 0 1 1.06-1.06L8 6.94l1.72-1.72a.75.75 0 1 1 1.06 1.06L9.06 8l1.72 1.72a.75.75 0 0 1 0 1.06Z" clip-rule="evenodd" />
            </svg>
            <span class="text-12">
              Void next invoice
              </span>
          </button>
        </div>
        <div v-if="notShowingEditForms && eligibleForCancel">
          <button @click="showCancelForm = true"
                  class="bg-white btn no-underline btn-compact border border-red-200 text-red-600 flex justify-space-between align-items-center gap-x-xxs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
            </svg>
            <span class="text-12">
              Cancel registration
              </span>
          </button>
        </div>
        <div v-if="notShowingEditForms && eligibleForRemoveWrestler">
          <button @click="showRemoveWrestlerForm = true"
                  class="bg-white btn no-underline btn-compact border border-red-200 text-red-600 flex justify-space-between align-items-center gap-x-xxs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512" stroke="currentColor" class="w-4 h-4">
              <path d="M497.94 273.94a48 48 0 0 0 0-67.88l-160-160a48 48 0 0 0-67.88 0l-256 256a48 48 0 0 0 0 67.88l96 96A48 48 0 0 0 144 480h356a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12H339.88l158.06-158.06zM304 80l160 160-103 103-160-160zM144 432l-96-96 119-119 160 160-55 55z"/>
            </svg>
            <span class="text-12">
              Remove wrestler
              </span>
          </button>
        </div>
      </div>
    </td>
  </tr>
  <tr v-if="showMarkPaidForm">
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="cell-max-w-250-lg" colspan="2">
      <div class="m-b-lg">
        <mark-paid-form v-if="showMarkPaidForm" :manual-invoice="invoiceToPay"></mark-paid-form>
      </div>
    </td>
  </tr>
  <tr v-if="showMarkVoidForm">
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="cell-max-w-250-lg" colspan="2">
      <div class="m-b-lg">
        <void-invoice-form v-if="showMarkVoidForm" :invoice="invoiceToPay"></void-invoice-form>
      </div>
    </td>
  </tr>
  <tr v-if="showCancelForm">
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="cell-max-w-250-lg" colspan="2">
      <div class="m-b-lg">
        <cancel-payment-plan v-if="showCancelForm" :plan="result"></cancel-payment-plan>
      </div>
    </td>
  </tr>
  <tr v-if="showRemoveWrestlerForm">
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="spacer-td"></td>
    <td class="cell-max-w-250-lg" colspan="2">
      <div class="m-b-lg">
        <remove-wrestler-payment-plan v-if="showRemoveWrestlerForm" :plan="result"></remove-wrestler-payment-plan>
      </div>
    </td>
  </tr>
  </tbody>
</template>
<script>
import VoidInvoiceForm from './void_invoice_form.vue';
import MarkPaidForm from './mark_paid_form.vue';
import CancelPaymentPlan from './cancel_payment_plan.vue';
import RemoveWrestlerPaymentPlan from './remove_wrestler_payment_plan.vue';
import { formattingMixin } from './mixins/formatting_mix';
import WrestlerRosters from './wrestler_rosters.vue';

export default {
  name: 'payment-plan',
  mixins: [formattingMixin],
  components: {
    CancelPaymentPlan,
    MarkPaidForm,
    VoidInvoiceForm,
    WrestlerRosters,
    RemoveWrestlerPaymentPlan,
  },
  props: {
    result: {
      type: Object,
      required: true
    },
    allowEdits: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paid: false,
      showMarkPaidForm: false,
      showMarkVoidForm: false,
      showCancelForm: false,
      showRemoveWrestlerForm: false,
      invoiceToPay: null,
      eligibleForVoid: false,
      eligibleForCash: false,
    };
  },
  mounted() {
    const vm = this;
    // By default we do not allow void or cash collection unless attributes change
    vm.eligibleForVoid = false;
    vm.eligibleForCash = false;
    vm.paid = (vm.result.status === 'paid');

    vm.invoiceToPay = vm.firstUnpaidNotVoidInvoice;
    if (vm.invoiceToPay) {
      const cashMethods = ['cash', 'check', 'all'];
      vm.eligibleForCash = _.includes(cashMethods, vm.invoiceToPay.payment_method);

      const { status } = vm.invoiceToPay;
      vm.eligibleForVoid = _.includes(['pending', 'overdue'], status);
    }

    vm.$notificationManager.$on('invoice-paid', vm.refreshListener);
    vm.$notificationManager.$on('hide-mark-paid-form', vm.hideListener);

    vm.$notificationManager.$on('invoice-voided', vm.refreshListener);
    vm.$notificationManager.$on('hide-void-form', vm.hideListener);

    vm.$notificationManager.$on('payment-plan-canceled', vm.refreshListenerForPlan);
    vm.$notificationManager.$on('hide-plan-cancel-form', vm.hideListenerForPlan);

    vm.$notificationManager.$on('payment-plan-updated', vm.refreshListenerForPlan);
    vm.$notificationManager.$on('hide-remove-wrestler-form', vm.hideListenerForPlan);

  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('invoice-paid', vm.refreshListener);
    vm.$notificationManager.$off('hide-mark-paid-form', vm.hideListener);

    vm.$notificationManager.$off('invoice-voided', vm.refreshListener);
    vm.$notificationManager.$off('hide-void-form', vm.hideListener);

    vm.$notificationManager.$off('payment-plan-updated', vm.refreshListenerForPlan);
    vm.$notificationManager.$off('hide-remove-wrestler-form', vm.hideListenerForPlan);
  },
  computed: {
    eligibleForCancel() {
      return this.result.status !== 'canceled';
    },
    // This is only available for multi-kid payment plans
    eligibleForRemoveWrestler() {
      // Cannot remove wrestler if the plan is canceled
      if (this.result.status === 'canceled') {
        return false;
      }

      // Cannot remove wrestler if the plan is paid and there is only one wrestler
      return this.result.registrations.filter(r => r.profile.type === 'WrestlerProfile' && r.status !== 'canceled').length > 1;
    },
    notShowingEditForms() {
      return !this.showMarkPaidForm && !this.showMarkVoidForm && !this.showCancelForm && !this.showRemoveWrestlerForm;
    },
    couponItem() {
      let items = _.flatMap(this.result.manual_invoices, 'invoice_items');
      return _.head(_.filter(items, ['transactable_type', 'Coupon']));
    },
    couponApplied() {
      return this.couponItem ? this.couponItem.transactable : null;
    },
    purchasedUpsell() {
      let items = _.flatMap(this.result.manual_invoices, 'invoice_items');
      return  _.head(_.filter(items, ['transactable_type', 'BillingPlan']));
    },
    couponAmountOff() {
      return this.couponItem ? this.formatAsCurrency(this.couponItem.amount * -1) : '';
    },
    hasDonation() {
      for (let i = 0; i < this.result.manual_invoices.length; i++) {
        const invoice = this.result.manual_invoices[i];
        for (let j = 0; j < invoice.invoice_items.length; j++) {
          const item = invoice.invoice_items[j];
          if (item.description === 'Donation') {
            return true;
          }
        }
      }

      return false;
    },
    donationAmount() {
      var amount = 0
      for (let i = 0; i < this.result.manual_invoices.length; i++) {
        const invoice = this.result.manual_invoices[i];
        for (let j = 0; j < invoice.invoice_items.length; j++) {
          const item = invoice.invoice_items[j];
          if (item.description === 'Donation') {
            amount += item.amount;
          }
        }
      }

      return this.formatAsCurrency(amount);
    },
    insurancePolicies() {
      const policies = [];
      this.result.registrations.forEach((registration) => {
        registration.insurance_policies.forEach((policy) => {
          policies.push(policy);
        });
      });

      return policies;
    },
    firstUnpaidNotVoidInvoice() {
      const vm = this;
      let nextInvoice = null;
      for (let i = 0; i < vm.result.manual_invoices.length; i++) {
        const invoice = vm.result.manual_invoices[i];
        const unPaid = invoice.status !== 'paid' && invoice.status !== 'void';
        const isBefore = (nextInvoice == null) || moment(invoice.due_on)
            .isBefore(nextInvoice.due_on);

        if (unPaid && isBefore) {
          nextInvoice = invoice;
        }
      }

      return nextInvoice;
    },
    paymentOptionName() {
      const paymentOptions = [];

      this.result.registrations.forEach((registration) => {
        if (registration.payment_option) {
          paymentOptions.push(registration.payment_option.name);
        }
      });
      return _.uniq(paymentOptions).join(', ');
    },
    registrantNames() {
      const registrants = [];
      this.result.registrations.forEach((registration) => {
        const { profile } = registration;
        if (profile.type === 'Customer') {
          let name = profile.display_name;
          if (profile.weight_class) {
            name = `${name} (${profile.weight_class})`;
          }
          registrants.push(name);
        } else {
          const link = `<a href='/wrestlers/${profile.id}'>${profile.display_name}</a>`;
          registrants.push(link);
        }
      });
      return registrants.join(', ');
    },
    parentName() {
      const billable = _.get(this, 'result.billing_profile.billable');
      if (billable) {
        if (billable.user_status === 'inactive') {
          return `${billable.display_name} (inactive)`;
        }

        if (billable.type === 'ParentProfile') {
          return `<a href='/parents/${billable.id}'>${billable.display_name}</a>`;
        }
        if (billable.type === 'CoachProfile') {
          return `<a href='/coaches/${billable.id}'>${billable.display_name}</a>`;
        }
        if (billable.type === 'Customer') {
          return _.get(this, 'result.billing_profile.email');
        }
      }

      return 'Unknown';
    },
  },
  methods: {
    hideAllForms() {
      this.showMarkPaidForm = false;
      this.showMarkVoidForm = false;
      this.showCancelForm = false;
      this.showRemoveWrestlerForm = false;
    },
    hideListener(invoice, type) {
      if (!this.invoiceToPay) {
        return;
      }

      if (this.invoiceToPay.id === invoice.id) {
        this.hideAllForms();
      }
    },
    refreshListener(invoice) {
      if (this.invoiceToPay && (this.invoiceToPay.id === invoice.id)) {
        this.hideAllForms();
        window.location.reload();
      }
    },
    refreshListenerForPlan(plan) {
      if (this.result.id === plan.id) {
        this.hideAllForms()
        window.location.reload();
      }
    },
    hideListenerForPlan(plan) {
      if (this.result.id === plan.id) {
        this.hideAllForms();
      }
    },
    subhead(manual_invoice) {
      const method = manual_invoice.payment_method;
      const invoiceDue = moment(manual_invoice.due_on)
          .format('M/D/YYYY');
      let due = this.formatAsCurrency(manual_invoice.amount_due);
      if (manual_invoice.status === 'paid') {
        const invoiceDate = moment(manual_invoice.paid_at)
            .format('M/D/YYYY');
        due = this.formatAsCurrency(manual_invoice.amount_paid);
        return `Paid ${due} on ${invoiceDate} with ${method}`;
      }
      if (manual_invoice.status === 'overdue') {
        if (method === 'card') {
          return `Charge failed for ${due} on ${invoiceDue}. Parent needs to update card.`;
        }
        return `Owes ${due} since ${invoiceDue} with ${method}`;
      }
      if (manual_invoice.status === 'void') {
        return `Voided invoice for ${due}`;
      }
      if (method === 'card') {
        return `Will be charged ${due} on ${invoiceDue} automatically`;
      }
      return `Owes ${due} on ${invoiceDue} with ${method}`;
    },
  },
};
</script>
