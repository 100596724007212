// This file is the source of truth for the native message types.
// It is used to define the message types and structures for the native app.
// It is also used to validate the messages that are sent from the native app to the webview.
// You SHOULD NOT add any new message types here. 

// If you need to add a new message type, we need to rev the app versions and expire the old ones
// OR only target specific versions of the app.

export const configureSaveMessage = (showSave) => ({
  type: 'configureSave',
  data: {
    showSave
  }
});

export const validationErrorMessage = (errorMessage) => ({
  type: 'validationError',
  data: {
    errorMessage: errorMessage
  }
});

export const navigateMessage = (url, title) => ({
  type: 'navigate',
  data: {
    url: url,
    title: title
  }
});

export const pushMessage = (url, title) => ({
  type: 'push',
  data: {
    url: url,
    title: title
  }
});

/* Notificadtion payloads look like this
notification: {
  name: 'event_saved',
  data: {
    event_id: event.id,
  }
}*/

export const popNotificationMessage = (notification) => ({
  type: 'pop',
  data: {
    notification: notification
  }
});