<template>
    <div id="repeated-event-update" role="form">
        <div class="">
            <label class="font-normal">
                This is a recurring event. Would you like to update all other linked events, or just this one?
            </label>
            <div class="m-t-xs">
                <label class="radio-inline">
                    <input type="radio" value="single" id="only_this_block" v-model="updateAll"> Only update this event
                </label>
            </div>
            <div class="m-t-xs">
                <label class="radio-inline">
                    <input type="radio" value="all" id="all_related_blocks" v-model="updateAll"> Update all events
                </label>
            </div>
        </div>
        <div class="flex flex-col gap-y-sm m-t-m">
            <ladda-button @lbClicked="pingServer" el-class="btn-primary w-full" :loading="loading">
                <span v-if="updateAll === 'single'">Only update this event</span>
                <span v-else-if="updateAll === 'all'">Update all events</span>
            </ladda-button>
            <div v-show="error">
                <small>
                    <span class="text-danger">{{ errorMessage }}</span>
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  components: { LaddaButton },
  name: 'repeated-event-update',
  props: [
    // Event is the full calendar weird event
    // params is what we should be sending to the backend during the update call
    'event',
    'params',
  ],
  data() {
    return {
      updateAll: 'single',

      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',
    };
  },
  methods: {
    eventName() {
      return this.event.title || this.params.event.name;
    },
    eventTimeDetails() {
      const vm = this;
      const { event } = vm.params;

      let start;
      let end;

      if (event.allDay) {
        const ambiguousStart = event.start_at.split('T')[0];
        const ambiguousEnd = event.end_at.split('T')[0];

        start = moment(ambiguousStart);
        // Subtract one day for 'human speak' UI display
        end = moment(ambiguousEnd).subtract(1, 'day');
      } else {
        start = moment(event.start_at);
        end = moment(event.end_at);
      }

      if (event.allDay) {
        if (end.diff(start, 'days') >= 1) {
          return `All day ${start.format('M/D/YYYY')} to ${end.format('M/D/YYYY')}`;
        }
        return `All day ${start.format('M/D/YYYY')}`;
      }
      return `${start.format('M/D/YYYY hh:mm a')} to ${end.format('M/D/YYYY hh:mm a')}`;
    },
    buttonText() {
      if (this.creating) {
        return 'Create Saved Block';
      }
      return 'Update Saved Block';
    },
    hideForm() {
      const vm = this;
      vm.$notificationManager.$emit('repeated-event-update-cancel', vm.event);
    },
    buildParams() {
      const vm = this;
      const { params } = vm;
      if (vm.updateAllSelected()) {
        params.event.update_recurring = true;
      }

      return params;
    },
    updateAllSelected() {
      return this.updateAll === 'all';
    },
    errorHandler() {
      const vm = this;
      vm.loading = false;

      vm.errorMessage = 'Error, please try again later or contact support.';
      vm.error = true;
    },
    pingServer() {
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.loading = true;

      const updateUrl = vm.$apiService.eventUrl(vm.event.id);
      const params = vm.buildParams();
      axios.put(updateUrl, params)
        .then((response) => {
          if (vm.updateAllSelected()) {
            vm.$notificationManager.$emit('show-toast', `${vm.eventName()} and all linked events updated`, true);
          } else {
            vm.$notificationManager.$emit('show-toast', `${vm.eventName()} updated`, true);
          }
          vm.loading = false;
          vm.$notificationManager.$emit('repeated-event-updated', vm.event, vm.updateAllSelected());
        })
        .catch((error) => {
          vm.loading = false;
          const errorM = `Failed to update, reverting to previous time. ${error.toString()}`;
          vm.$notificationManager.$emit('show-toast', errorM, false);
          vm.hideForm();
        });
    },
  },
};
</script>
