<template>
  <div id="event-create" role="form" :class="getVisibilityClass(null)">
    <h1 class="border-bottom m-t-none m-b-m p-b-sm text-20" v-if="!forAppEmbed">
      <span v-if="!updateEventId">New Event</span>
      <span v-else>Edit Event</span>
    </h1>
    <div :class="getVisibilityClass('form-group')">
      <label>Name</label>
      <input v-model="eventName" type="text" class="form-control">
    </div>
    <div v-if="!updateEventId" :class="getVisibilityClass('form-group')">
      <label>Event Type</label>
      <select v-model="eventType" id="eventTypeSelect" class="form-control m-b" name="eventType">
        <option selected disabled>Choose event type...</option>
        <option value="dual_meet">Dual Meet</option>
        <option value="scramble">Scramble</option>
        <template v-if="forCalendar">
          <option value="other">Other</option>
          <option value="practice">Practice</option>
        </template>
        <option value="tournament">Tournament</option>
      </select>
    </div>
    <div :class="getVisibilityClass('form-group')">
      <label for="event_date_time">Date & Time</label>
      <div id="event_date_time" class="gap-x-xs">
        <div class="relative flex flex-col gap-y-xxs">
          <label for="event_date_start" class="text-12 m-y-none tracking-wide">Start date</label>
          <input type="text" id="event_date_start" class="date start form-control cursor-pointer"/>
        </div>
        <div v-show="!allDay" class="relative flex flex-col gap-y-xxs">
          <label for="event_start_time" class="text-12 m-y-none tracking-wide">Start time</label>
          <input type="text" id="event_time_start" class="time start form-control"/>
        </div>
        <div v-show="!allDay" class="relative flex flex-col gap-y-xxs align-self-flex-end">
          <label for="event_date_time" class="text-12 m-y-none tracking-wide">End time</label>
          <input type="text" id="event_time_end" class="time end form-control"/>
        </div>
        <div class="relative flex flex-col gap-y-xxs align-self-flex-end">
          <label for="event_date_end" class="text-12 m-y-none tracking-wide">End date</label>
          <input type="text" id="event_date_end" class="date end form-control"/>
        </div>
      </div>
      <div>
        <label class="checkbox-inline">
          <input type="checkbox" id="all_day_checkbox" v-model="allDay"> All Day
        </label>
      </div>
      <div v-show="showRepeatForm()" class="m-t-sm">
        <label class="checkbox-inline">
          <input type="checkbox" id="repeat_checkbox" v-model="repeat"> Repeat
        </label>
        <div v-show="repeat" class="p-x-md p-y-sm m-t-sm border border-solid border-gray-200 rounded-sm">
          <div>
            <label>
              Repeat Every
            </label>
          </div>
          <label class="checkbox-inline">
            <input type="checkbox" id="sun_check" v-model="sun"> Sun
          </label>
          <label class="checkbox-inline">
            <input type="checkbox" id="mon_check" v-model="mon"> Mon
          </label>
          <label class="checkbox-inline">
            <input type="checkbox" id="tue_check" v-model="tue"> Tue
          </label>
          <label class="checkbox-inline">
            <input type="checkbox" id="wed_check" v-model="wed"> Wed
          </label>
          <label class="checkbox-inline">
            <input type="checkbox" id="thu_check" v-model="thu"> Thu
          </label>
          <label class="checkbox-inline">
            <input type="checkbox" id="fri_check" v-model="fri"> Fri
          </label>
          <label class="checkbox-inline">
            <input type="checkbox" id="sat_check" v-model="sat"> Sat
          </label>
          <div id="event_repeat_until" class="m-t-sm">
            <label for="event_repeat_until_date">Until</label>
            <input type="text" id="event_repeat_until_date" class="date repeat form-control"/>
          </div>
        </div>
      </div>
    </div>
    <div :class="getVisibilityClass('form-group')">
      <div class="m-t-md">
        <label>Color</label>
        <div v-bind:style="circleStyles"></div>
        <i class="fa fa-caret-down" @click="showColorPicker = !showColorPicker"></i>
      </div>
      <div v-if="showColorPicker">
        <div v-for="cOption in colorOptions"
             class="color-pick"
             :style="{ 'background-color': cOption }"
             @click="setColor(cOption)"
        >
        </div>
      </div>
    </div>
    <div v-if="showOpposingTeamForm()" class="form-group">
      <label>Opposing Team</label>
      <div v-if="opposingTeam">
        <div class="pull-left m-l-sm">
          <h3>
            {{ opposingTeam.name }}
          </h3>
          <p class='search-type-label'>
            {{ opposingTeam.conference }}
          </p>
          <small class="no-l-pad">
            <a @click="resetOpposingTeam()">Change...</a>
          </small>
        </div>
        <div class="clearfix"></div>
      </div>
      <search v-show="!opposingTeam"
              readable-model-name="opposing team"
              :allow-add="true"
              only-dismiss-model="OpposingTeam"
              class="search-form"
              response-key="teams"
              v-on:adding="dimForOpposingTeamCreate()"
              v-on:doneAdding="showAll()"
              :mutate-history="false"
              :small-input="true"
              placeholder="Type opposing team name..."
              base-url="/api/v1/opposing_teams">
        <template v-slot:list="slotProps">
          <div class="owrestler-oteam-wrapper" @click="opposingTeamClicked(slotProps.result)">
            <opposing-team-search-result
                v-bind:result="slotProps.result"
                v-bind:is-selectable="true"
                v-bind:key="slotProps.result.id"
            >
            </opposing-team-search-result>
          </div>
        </template>
        <template v-slot:addform="slotProps">
          <opposing-team-create
              v-bind:name="slotProps.eventName"
          >
          </opposing-team-create>
        </template>
      </search>
    </div>
    <div v-if="forCalendar">
      <h1 class="border-bottom m-t-md m-b-m p-b-sm text-20">Optional details</h1>
      <div :class="getVisibilityClass('form-group')">
        <div class="flex gap-x-xs">
          <label>Roster(s)</label>
          <VDropdown theme="info-tooltip">
            <!-- This will be the popover target (for the events and position) -->
            <i class="fa fa-question"></i>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>
                <p class="header">
                  Why link to specific rosters?
                </p>
                <p>
                  Linking rosters to events lets parents/wrestlers quickly filter events relevant to them.
                </p>
                <p>
                  Linked rosters will also be pre-selected when taking attendance for this event.
                </p>
              </div>
            </template>
          </VDropdown>
        </div>
        <div class="-m-t-xs">
          <div class="flex flex-wrap gap-xxs m-b-xs m-t-xs">
            <button @click="toggleRoster(er.roster)" class="btn btn-success btn-outline rounded-lg font-small"
                    style="padding: 2px 6px;" v-for="er in notDestroyedRosterEvents">
              {{ er.roster.name }}
              <i class="fa fa-times"></i>
            </button>
            <button @click="toggleRoster(er.roster)" class="btn btn-danger btn-outline rounded-lg font-small"
                    style="padding: 2px 6px;" v-for="er in destroyedRosterEvents">
              {{ er.roster.name }} (will be removed)
              <i class="fa fa-times"></i>
            </button>
          </div>
          <search
              class="search-form"
              response-key="rosters"
              placeholder="Type the name of a roster..."
              :mutate-history="false"
              :allow-add="false"
              only-dismiss-model="Roster"
              :small-input="true"
              :display-all-on-empty="false"
              readable-model-name="roster"
              base-url="/api/v1/rosters">
            <template v-slot:list="slotProps">
              <div
                  class="border-top m-t-sm m-b-xs p-t-sm p-b-xs cursor-pointer flex justify-space-between align-items-center p-r-sm"
                  @click="toggleRoster(slotProps.result)">
                <div>
                  {{ slotProps.result.name }}
                </div>
                <div class="border rounded-sm border-blue-200 border-solid font-small"
                     style="padding: 2px 6px;">
                  Link
                </div>
              </div>
            </template>
          </search>
        </div>
      </div>
      <div v-if="paidSessionTagging" :class="getVisibilityClass('form-group')">
        <div class="flex gap-x-xs">
          <label>Registration</label>
          <VDropdown theme="info-tooltip">
            <!-- This will be the popover target (for the events and position) -->
            <i class="fa fa-question"></i>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>
                <p class="header">
                  Why link a registration?
                </p>
                <p>
                  A registration button will show on the event, so the team can quickly sign up for the event.
                </p>
              </div>
            </template>
          </VDropdown>
        </div>
        <div>
          <select id="session_select" v-model="paidSession" class="form-control m-b">
            <option selected disabled>Choose registration...</option>
            <option v-for="session in paidSessions" :value="session">{{ session.name }}</option>
          </select>
        </div>
      </div>
      <div :class="getVisibilityClass('form-group')">
        <label>Location</label>
        <input v-model="eventLocation" type="text" class="form-control">
      </div>
      <div :class="getVisibilityClass('form-group')">
        <label>Notes</label>
        <input id="x-editor" type="hidden" name="content">
        <trix-editor input="x-editor" class="trix-content"></trix-editor>
      </div>
    </div>
    <div :class="getVisibilityClass('')">
      <div class="flex flex-col flex-row-lg justify-space-between gap-md">
        <div class="order-last-lg w-fit-lg">
          <ladda-button @lbClicked="postEvent" el-class="btn-primary w-full" :loading="loading">
            <i class="fa fa-save"></i>&nbsp;&nbsp;{{ buttonText() }}
          </ladda-button>
          <p v-if="eventForUpdate" class="text-center"><small><i class="fa fa-bell-o"></i>&nbsp;&nbsp;Notifications about this change can be sent after saving</small></p>
        </div>
        <button v-show="!loading && !updateEventId" type="button" v-on:click="cancel" class="btn btn-white w-fit-lg">
          Cancel
        </button>
        <div v-if="eventForUpdate" class="w-fit-lg">
          <a class="btn btn-danger w-full" v-on:click="showDeleteForm" v-show="!deleteEventForm"><i class="fa fa-trash-o"></i>&nbsp;&nbsp;Delete Event</a>
          <p class="text-center" v-show="!deleteEventForm"><small><i class="fa fa-bell-o"></i>&nbsp;&nbsp;A cancellation notification can be sent after deleting</small></p>
          <delete-event-form v-show="deleteEventForm" :event="eventForUpdate"
                            :recurring="recurring"></delete-event-form>
        </div>
      </div>
      <div v-if="error" class="col-span-2">
        <small>
          <span class="text-danger">{{ errorMessage }}</span>
        </small>
      </div>
    </div>
  </div>
</template>
<script>
import 'timepicker/jquery.timepicker.js';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'datepair.js/dist/datepair';
import OpposingTeamSearchResult from './opposing_team_search_result.vue';
import Search from '../../shared/search.vue';
import OpposingTeamCreate from './opposing_team_create.vue';
import DeleteEventForm from './delete_event_form.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { trixFileAttachmentMix } from './mixins/trix_file_attachment_mix';
import { appEmbedMix } from './mixins/app_embed_mix';
export default {
  name: 'event-create',
  components: {
    DeleteEventForm,
    OpposingTeamCreate,
    Search,
    OpposingTeamSearchResult,
    LaddaButton,
  },
  mixins: [trixFileAttachmentMix, appEmbedMix],
  props: {
    name: {
      type: String,
      required: false,
    },
    // if this is for calendar we show more options.
    // video manager only gets tournament and dual options
    forCalendar: {
      type: Boolean,
      default: false
    },
    start: {
      type: Object,
      required: false,
    },
    end: {
      type: Object,
      required: false,
    },
    updateEventId: {
      type: Number,
      required: false,
    },
    paidSessionTagging: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support.',

      eventForUpdate: null,
      eventName: '',
      eventLocation: '',
      eventNotes: '',
      eventType: '',
      eventStart: null,
      eventEnd: null,
      eventColor: '',
      rosterEvents: {},
      opposingTeam: null,
      dimSections: false,
      trixEditor: null,

      allDay: true,
      repeat: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,

      paidSessions: [],
      paidSession: null,
      // temp variable for holding event edit sessions
      eventPaidSessionId: null,

      deleteEventForm: false,
      recurring: false,

      showColorPicker: false,
      colorOptions: [
        '#27aa83',
        '#4299d7',
        '#663dae',
        '#c8992d',
        '#003E6B',
        '#D64345',
        '#FFC978',
        '#7C6DE8',
        '#147B63',
        '#23c6c8',
      ],
    };
  },
  computed: {
    circleStyles() {
      return {
        'background-color': this.eventColor,
        width: '10px',
        height: '10px',
        'border-radius': '50%',
        display: 'inline-block',
        'margin-left': '4px',
      };
    },
    notDestroyedRosterEvents() {
      return _.flatMap(this.rosterEvents).filter(re => !re._destroy);
    },
    destroyedRosterEvents() {
      return _.flatMap(this.rosterEvents).filter(re => re._destroy);
    },
  },
  created() {
    const vm = this;
    vm.$notificationManager.$on('opposing-team-created', vm.opposingTeamCreated);
    vm.$notificationManager.$on('hide-delete-event-form', vm.handleHideDeleteFormEvent);

    if (vm.updateEventId) {
      vm.getEvent();
    } else {
      // start and end are given by full calendar and contain end exclusive days
      if (vm.start && !vm.start.hasTime()) {
        // This is an all day event, for UI purposes subtract a day from the end
        // This is added back in to the internal storage within recalculateDateRange
        // so the internal database stays consistent
        vm.eventStart = vm.start;
        vm.eventEnd = vm.end.subtract(1, 'day');
        vm.allDay = true;
      } else {
        vm.eventStart = vm.start;
        vm.eventEnd = vm.end;
        vm.allDay = false;
      }
      vm.eventName = vm.name;
      vm.getPaidSessions();
    }
  },
  mounted() {
    const vm = this;
    vm.prefillEventHTML();
    vm.recalculateDateRange();
    document.addEventListener('trix-initialize', vm.trixInit);
    console.log('event create mounted called');
    vm.setupNativeSaveButton(vm.postEvent);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('opposing-team-created', vm.opposingTeamCreated);
    vm.$notificationManager.$off('hide-delete-event-form', vm.handleHideDeleteFormEvent);
    document.removeEventListener('trix-initialize', vm.trixInit);
    vm.removeNativeSaveListener();
  },
  watch: {
    // whenever allDay changes, this function will run
    allDay(newAllDay) {
      this.recalculateDateRange();
    },
    eventType(newEventType) {
      const vm = this;
      // for now, in the update case do not auto update based on type because type is immutable
      // and because it was fucking with the overwritten colors
      if (!vm.updateEventId) {
        if (newEventType === 'practice') {
          vm.eventColor = '#27aa83';
        } else if (newEventType === 'dual_meet' || newEventType === 'scramble') {
          vm.eventColor = '#4299d7';
        } else if (newEventType === 'tournament') {
          vm.eventColor = '#663dae';
        } else if (newEventType === 'other') {
          vm.eventColor = '#c8992d';
        }
      }
    },
  },
  methods: { 
    setColor(color) {
      this.eventColor = color;
      this.showColorPicker = false;
    },
    trixInit(event) {
      const { editor } = event.target;
      this.trixEditor = editor;
      this.loadTrixContent();
    },
    loadTrixContent() {
      const vm = this;
      if (vm.trixEditor && vm.eventNotes) {
        vm.trixEditor.loadHTML(vm.eventNotes);
      }
    },
    opposingTeamCreated(opposingTeam) {
      const vm = this;
      vm.opposingTeam = opposingTeam;
      vm.showAll();
    },
    showDeleteForm() {
      this.deleteEventForm = true;
    },
    handleHideDeleteFormEvent(event) {
      const vm = this;
      if (event.id === vm.updateEventId) {
        vm.hideDeleteForm();
      }
    },
    hideDeleteForm() {
      this.deleteEventForm = false;
    },
    toggleRoster(roster) {
      // Clear search
      this.$notificationManager.$emit('profile-clicked', 'Roster');
      if (this.rosterEvents[roster.id]) {
        // If we already have a roster event check to see if we have an id
        let re = this.rosterEvents[roster.id];
        if (re.id) {
          // We have an existing roster event on the backend, so we toggle the destroy flag
          re._destroy = !re._destroy;
        } else {
          // We do not have an existing event on the backend, so we simply drop it from the payload
          this.$delete(this.rosterEvents, roster.id);
        }

        return;
      }

      // In this world we do not have a roster id so we set it
      this.$set(this.rosterEvents, roster.id, {
        _destroy: false,
        roster: {
          id: roster.id,
          name: roster.name,
        },
      });
    },
    getRosterEventParams() {
      return _.flatMap(this.rosterEvents, function (r) {
        if (r.id) {
          return {
            id: r.id,
            roster_id: r.roster.id,
            _destroy: r._destroy,
          };
        }

        return {
          roster_id: r.roster.id,
          _destroy: r._destroy,
        };
      });
    },
    buttonText() {
      if (this.updateEventId) {
        return 'Update Event';
      }

      return 'Create Event';
    },
    prefillEventHTML() {
      const vm = this;
      if (vm.eventStart) {
        // Prefill the value of the UI with these passed in props
        $('#event_date_start')
            .val(vm.eventStart.format('M/D/YYYY'));
        $('#event_time_start')
            .val(vm.eventStart.format('hh:mm a'));

        $('#event_date_end')
            .val(vm.eventEnd.format('M/D/YYYY'));
        $('#event_time_end')
            .val(vm.eventEnd.format('hh:mm a'));
      }

      // initialize input widgets first
      $('#event_date_time .time')
          .timepicker({
            showDuration: true,
            timeFormat: 'g:i a',
          });

      $('#event_date_time .date')
          .datepicker({
            format: 'm/d/yyyy',
            autoclose: true,
            orientation: 'bottom',
          });

      $('#event_repeat_until_date')
          .datepicker({
            format: 'm/d/yyyy',
            autoclose: true,
            orientation: 'bottom',
          });

      // initialize datepair
      const basicExampleEl = document.getElementById('event_date_time');
      const datepair = new Datepair(basicExampleEl);
      datepair.refresh();

      $('#event_date_time')
          .on('rangeSelected', () => {
            vm.recalculateDateRange();
          })
          .on('rangeIncomplete', () => {
            vm.recalculateDateRange();
            console.log('range incomplete');
          })
          .on('rangeError', () => {
            console.log('range error');
            vm.recalculateDateRange();
          });
    },
    recalculateDateRange() {
      const vm = this;
      const dateStart = $('#event_date_start')
          .val();
      const timeStart = $('#event_time_start')
          .val();
      const timeEnd = $('#event_time_end')
          .val();
      const dateEnd = $('#event_date_end')
          .val();

      const tz = moment.tz.guess();

      if (vm.allDay) {
        // Subtracting 1 day here because internally fullcalendar is date exclusive
        // Although in human speak we say all day from 2/7 to 2/7 we really mean from midnight 2/7 to
        // midnight 2/8. This is more apparent in multi day spans when we say "monday through wednesday"
        // we really mean all day wednesday, not up until midnight on wednesday.
        // https://github.com/fullcalendar/fullcalendar/issues/3679 see jhwinters answer
        // Not using time zones here because allday events are time ambiguous
        vm.eventStart = moment(dateStart, 'M/D/YYYY');
        vm.eventEnd = moment(dateEnd, 'M/D/YYYY')
            .add(1, 'day');
      } else if (dateStart && timeStart && timeEnd && dateEnd) {
        // valid attempt to parse
        const startString = `${dateStart} ${timeStart}`;
        vm.eventStart = moment.tz(startString, 'M/D/YYYY hh:mm a', tz);

        const endString = `${dateEnd} ${timeEnd}`;
        vm.eventEnd = moment.tz(endString, 'M/D/YYYY hh:mm a', tz);
      } else {
        vm.eventStart = null;
        vm.eventEnd = null;
      }
    },
    getVisibilityClass(extraClasses) {
      if (this.dimSections) {
        if (extraClasses) {
          return `${extraClasses} should-dim`;
        }
        return 'dim';
      }
      return extraClasses;
    },
    dimForOpposingTeamCreate() {
      this.dimSections = true;
    },
    showAll() {
      this.dimSections = false;
    },
    showOpposingTeamForm() {
      const vm = this;
      return vm.eventType === 'dual_meet';
    },
    showRepeatForm() {
      const vm = this;
      return (vm.eventType === 'practice' || vm.eventType === 'other') && vm.updateEventId === undefined;
    },
    resetOpposingTeam() {
      this.opposingTeam = null;
    },
    opposingTeamClicked(opposingTeam) {
      this.opposingTeam = opposingTeam;
    },
    cancel() {
      this.$notificationManager.$emit('cancel-event-create');
      this.sendMessageToNativeApp({type: 'pop'});
    },
    validate() {
      const vm = this;

      let errorMessage = 'Please ensure ';
      let error = false;
      if (!vm.eventName) {
        errorMessage += 'name, ';
        error = true;
      }
      if (!vm.eventType) {
        errorMessage += 'event type, ';
        error = true;
      }
      if (vm.eventType && vm.eventType === 'dual_meet' && !vm.opposingTeam) {
        errorMessage += 'opposing team, ';
        error = true;
      }
      if (vm.eventStart === null) {
        errorMessage += 'event start, ';
        error = true;
      }

      if (vm.eventEnd === null) {
        errorMessage += 'event end, ';
        error = true;
      }

      if (vm.repeat) {
        if (!vm.sun && !vm.mon && !vm.tue && !vm.wed && !vm.thu && !vm.fri && !vm.sat) {
          errorMessage += 'repeat dates, ';
          error = true;
        }

        const repeatEnd = $('#event_repeat_until_date')
            .val();
        if (repeatEnd === undefined || repeatEnd.length === 0) {
          errorMessage += 'repeat end date, ';
          error = true;
        }
      }

      if (error) {
        errorMessage = errorMessage.slice(0, -2);
        errorMessage += ' is not empty.';
        vm.errorMessage = errorMessage;
        vm.error = true;
        this.notifyAppOfValidationError(errorMessage);

        return false;
      }
      return true;
    },
    buildParams() {
      const vm = this;
      const params = {
        event: {
          name: vm.eventName,
          event_type: vm.eventType,
          color: vm.eventColor,
          start_at: vm.eventStart.toISOString(),
          end_at: vm.eventEnd.toISOString(),
          all_day: vm.allDay,
          location: vm.eventLocation,
          notes: $('#x-editor').val(),
          roster_events_attributes: vm.getRosterEventParams(),
        },
      };

      if (vm.repeat) {
        const untilDate = $('#event_repeat_until_date')
            .val();
        const tz = moment.tz.guess();
        // This is an attempt to normalize the string. Before this, sometimes the end of the until loop
        // would get dropped because the until was a generic date, and with utc offsets pacific time
        // could move into the next day entirely!
        const untilString = moment.tz(untilDate, 'M/D/YYYY', tz)
            .endOf('day')
            .utc()
            .toISOString();
        params.event.repeat = {
          until: untilString,
          sun: vm.sun,
          mon: vm.mon,
          tue: vm.tue,
          wed: vm.wed,
          thu: vm.thu,
          fri: vm.fri,
          sat: vm.sat,
        };
      }

      if (vm.opposingTeam) {
        params.event.opposing_team_id = vm.opposingTeam.id;
      }

      if (vm.paidSession) {
        params.event.paid_session_id = vm.paidSession.id;
      }
      return params;
    },
    postEvent: _.throttle(function () {
      const vm = this;

      if (vm.validate()) {
        const params = vm.buildParams();
        if (vm.updateEventId) {
          if (vm.recurring) {
            // Send message for the repeated event modal to show
            vm.$notificationManager.$emit('show-recurring-event-update', vm.eventForUpdate, params);
          } else {
            if (vm.loading === true) {
              return;
            }
            vm.loading = true;
            const url = vm.$apiService.eventUrl(vm.updateEventId);
            axios.put(url, params)
                .then((response) => {
                  vm.loading = false;
                  vm.$notificationManager.$emit('event-updated', vm.eventForUpdate);
                  vm.$notificationManager.$emit('show-toast', `${vm.eventName} Updated`, true);
                })
                .catch((error) => {
                  vm.errorHandler(error);
                });
          }
        } else {
          if (vm.loading === true) {
            return;
          }
          vm.loading = true;
          const url = vm.$apiService.eventsUrl('');
          axios.post(url, params)
              .then((response) => {
                vm.loading = false;
                const event = response.data;
                vm.$notificationManager.$emit('event-created', event);
                vm.$notificationManager.$emit('show-toast', `${event.name} created`, true);
                // This tell the native app that an event was created, to pop to the root, and to notify the app to reload the calendar
                vm.askAppToPop({
                  name: 'event_saved',
                  data: {
                    event_id: event.id,
                  }
                });
              })
              .catch((error) => {
                vm.errorHandler(error);
              });
        }
      }
    }, 500),
    errorHandler(error) {
      const vm = this;
      vm.loading = false;
      vm.errorMessage = 'Error, please try again later or contact support.';
      vm.error = true;
    },
    getPaidSessions() {
      const vm = this;

      if (!vm.paidSessionTagging) {
        return;
      }

      vm.loading = true;

      const url = vm.$apiService.futureSessionsUrl();
      vm.$apiService.loadAllPages(url, 'paid_sessions')
          .then((sessions) => {
            vm.paidSessions = sessions;
            if (vm.eventPaidSessionId != null) {
              sessions.forEach((ps) => {
                if (ps.id === vm.eventPaidSessionId) {
                  vm.paidSession = ps;
                }
              });
            }

            vm.loading = false;
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = `Error retrieving paid sessions ${error.toString()}`;
            vm.error = true;
          });
    },
    getEvent() {
      const vm = this;
      const getUrl = vm.$apiService.eventUrl(vm.updateEventId);
      vm.loading = true;
      axios.get(getUrl)
          .then((response) => {
            vm.loading = false;

            const tz = moment.tz.guess();
            const { data } = response;
            // Now prefill the data with the event json
            vm.eventName = data.name;
            vm.eventLocation = data.location;
            vm.eventNotes = data.notes;
            vm.eventType = data.event_type;
            vm.eventColor = data.color;
            vm.allDay = data.all_day;
            vm.eventPaidSessionId = _.get(data, 'paid_session.id', null);
            vm.recurring = data.has_repeated_events || data.parent_id != null;
            vm.eventForUpdate = data;
            // Why do I need clonedeep here? I don't understand something important here
            const rEvents = _.cloneDeep(data.roster_events);
            vm.rosterEvents = _.keyBy(_.map(rEvents, (re) => {
              re._destroy = false;
              return re;
            }), 'roster.id');
            // This needs to be the same as the data coming in from full calendar
            if (vm.allDay) {
              const ambiguousStart = data.start_at.split('T')[0];
              const ambiguousEnd = data.end_at.split('T')[0];
              vm.eventStart = moment(ambiguousStart);
              // Subtracting one day for display purposes, this gets added back in before posting to the server
              vm.eventEnd = moment(ambiguousEnd)
                  .subtract(1, 'day');
            } else {
              vm.eventStart = moment(data.start_at);
              vm.eventEnd = moment(data.end_at);
            }

            if (vm.eventType === 'dual_meet') {
              data.team_scores.forEach((score) => {
                if (score.team.type === 'OpposingTeam') {
                  vm.opposingTeam = score.team;
                  vm.showAll();
                }
              });
            }
            vm.loadTrixContent();
            vm.prefillEventHTML();
            vm.recalculateDateRange();
            vm.getPaidSessions();
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = `Error retrieving event ${error.toString()}. Please try again by refreshing the page or contact support.`;
            vm.error = true;
          });
    },
  },
};
</script>
