<template>
    <div>
        <event-create
                :for-calendar="true"
                :update-event-id="eventId"
                :paid-session-tagging="showPaidSessionTagging"
                :endpoint="endpoint"
                :access-key="accessKey"
                :auth-token="authToken"
                :cloudfront-base-url="cloudfrontBaseUrl"
                :for-app-embed="forAppEmbed"
        >
        </event-create>
        <div id="event-update-modal" class="modal fade" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content p-lg">
                    <div>
                        <div class="flex gap-sm align-items-center justify-space-between m-b-sm">
                            <h2 class="font-heavy m-t-none m-b-none">Update Repeated Events?</h2>
                            <div class="flex h-12 w-12 align-items-center justify-center rounded-full bg-green-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-green-700">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
                                </svg>
                            </div>
                        </div>
                        <repeated-event-update v-if="updatingRepeatedEvent" :event="repeatedEventForUpdate" :params="repeatingEventForUpdateParams"></repeated-event-update>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
        <div id="event-notify-modal" class="modal fade" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content p-lg">
                  <div>
                    <div class="flex gap-sm align-items-center justify-space-between m-b-sm">
                      <h2 class="font-heavy m-t-none m-b-none">Notifications</h2>
                      <div class="flex h-12 w-12 align-items-center justify-center rounded-full bg-green-100">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-green-700">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
                        </svg>
                      </div>
                    </div>
                    <event-change-notify v-if="eventNotify" :event="eventForNotify" :recurring-change="recurringChanged"></event-change-notify>
                  </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</template>

<script>
import EventCreate from './event_create.vue';
import EventChangeNotify from './event_change_notify.vue';
import RepeatedEventUpdate from './repeated_event_update.vue';
import { appEmbedMix } from './mixins/app_embed_mix';

export default {
  name: 'event-edit',
  mixins: [appEmbedMix],
  components: {
    EventChangeNotify,
    EventCreate,
    RepeatedEventUpdate,
  },
  props: {
    eventId: {
      type: Number,
      required: true
    },
    showPaidSessionTagging: {
      type: Boolean,
      default: false,
    },
    accessKey: {
      type: String,
      required: false,
    },
    authToken: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    endpoint: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      eventNotify: false,
      eventForNotify: false,
      recurringChanged: false,
      eventForDelete: null,

      repeatedEventForUpdate: null,
      repeatingEventForUpdateParams: null,
      revertUpdateFunc: null,
      updatingRepeatedEvent: false,
    };
  },
  mounted() {
    const vm = this;
    vm.$notificationManager.$on('repeated-event-update-cancel', vm.hideRecurringModal);
    vm.$notificationManager.$on('repeated-event-updated', vm.recurringEventUpdated);
    vm.$notificationManager.$on('event-notify-close', vm.closeNotifyEvent);
    vm.$notificationManager.$on('show-recurring-event-update', vm.showRecurringModal);
    vm.$notificationManager.$on('event-deleted', vm.eventDeleted);
    vm.$notificationManager.$on('event-updated', vm.showNotifyModal);
  },
  destroyed() {
    const vm = this;

    vm.$notificationManager.$off('repeated-event-update-cancel', vm.hideRecurringModal);
    vm.$notificationManager.$off('repeated-event-updated', vm.recurringEventUpdated);
    vm.$notificationManager.$off('event-notify-close', vm.closeNotifyEvent);
    vm.$notificationManager.$off('show-recurring-event-update', vm.showRecurringModal);
    vm.$notificationManager.$off('event-deleted', vm.eventDeleted);
    vm.$notificationManager.$off('event-updated', vm.showNotifyModal);
  },
  methods: {
    eventDeleted(event, recurring) {
      this.eventForDelete = event;
      this.showNotifyModal(event, recurring)
    },
    recurringEventUpdated(event, needsRefetch) {
      const vm = this;
      vm.hideRecurringModal();
      vm.showNotifyModal(event, needsRefetch);
    },
    hideRecurringModal() {
      const vm = this;
      vm.updatingRepeatedEvent = false;
      vm.repeatedEventForUpdate = null;
      vm.repeatingEventForUpdateParams = null;
      $(vm.updateModalId()).modal('hide');
    },
    showRecurringModal(event, params) {
      // trigger the update recurring modal
      const vm = this;
      vm.repeatedEventForUpdate = event;
      vm.repeatingEventForUpdateParams = params;
      vm.updatingRepeatedEvent = true;
      $(vm.updateModalId()).modal('show');
    },
    updateModalId() {
      return '#event-update-modal';
    },
    notifyModalId() {
      return '#event-notify-modal';
    },
    showNotifyModal(event, recurring) {
      const vm = this;
      vm.eventForNotify = event;
      vm.recurringChanged = recurring;
      vm.eventNotify = true;
      
      // Show modal immediately but delay modal-open class
      $(vm.notifyModalId()).modal('show');
      setTimeout(() => {
        document.body.classList.add('modal-open');
      }, 400);

      // Also add a timeout after 1 second, checking to make sure body has modal-open class
      setTimeout(() => {
        if (!document.body.classList.contains('modal-open')) {
          document.body.classList.add('modal-open');
        }
      }, 1000);
    },
    closeNotifyEvent(event) {
      const vm = this;
      vm.eventNotify = false;
      vm.eventForNotify = null;
      $(vm.notifyModalId()).modal('hide');

      // Now we need to refetch the event or notify the app
      if (vm.forAppEmbed) {
        let eventName = vm.eventForDelete?.id === event.id ? 'event_deleted' : 'event_saved';
        vm.askAppToPop({
          name: eventName,
          data: {
            event_id: vm.eventId,
          }
        });
      } else {
        if (event.id === this.eventForDelete?.id) {
          window.location.assign('/calendar');
        } else {
          window.location.assign(`/events/${vm.eventId}`);
        }
      }
    },
  },
};
</script>
