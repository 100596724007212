<template>
  <div role="form">
    <spinner v-if="retrieving"></spinner>
    <div v-if="preferences">
      <div class="row" :class="{ 'p-t-lg': !forAppEmbed }">
        <div class="col-md-4">
          <h1 class="form-section-title">Notification Preferences for {{ teamName }}</h1>
        </div>
        <div class="col-md-8">
          <div class="form-group flex align-items-start m-t-m">
            <input type="checkbox" class="m-t-none" id="email_checkbox" v-model="email">
            <div class="m-l-sm">
              <label for="email_checkbox" class="m-b-none" style="margin-top: 1px;">Email</label>
              <p v-if="!email" class="text-gray-700 m-t-xxs">
                <strong>Note</strong><br/>
                Turning off email notifications will mute team related notifications sent via email (new message, practice changed, etc).<br/>
                Transactional emails—such as receipts—will still be sent by email for your records.
              </p>
            </div>
          </div>

          <div class="form-group flex align-items-start">
            <input type="checkbox" class="m-t-none" id="sms_checkbox" v-model="sms">
            <div class="m-l-sm">
              <label for="sms_checkbox" class="m-b-none" style="margin-top: 1px;">Text message (SMS)</label>
            </div>
          </div>

          <div class="form-group" v-if="sms">
            <label :for="this.$srHelper.idFor('phone_number', 1)" class="m-b-none">
              Phone number
            </label>
            <the-mask mask="(###) ### - ####"
                      v-model="phoneNumber"
                      class="form-control"
                      :id="this.$srHelper.idFor('phone_number', 1)"
                      @blur.native="validate()"
                      ref="phone"
                      type="tel">
            </the-mask>
            <p v-show="phoneError" class="text-danger m-t-xxs">
              Please enter a valid phone number
            </p>
          </div>

          <div class="form-group flex align-items-start">
            <input type="checkbox" class="m-t-none" id="push_checkbox" v-model="effectivePushUserPref" :disabled="!push">
            <div class="m-l-sm">
              <label for="push_checkbox" class="m-b-none" style="margin-top: 1px;">Push notifications (iOS or Android app)</label>
              <p v-if="!push" class="text-gray-600 m-t-xxs">
                <strong>Note</strong><br/>Make sure you have downloaded the WrestlingIQ <a href="https://apple.co/3wMcv92" target="_blank" class="text-underline">iOS <i class="fa fa-external-link"></i></a> or 
                <a href="https://play.google.com/store/apps/details?id=com.athleticsiq.android.wrestlingiq" class="text-underline" target="_blank">Android <i class="fa fa-external-link"></i></a> app, signed in, and given WrestlingIQ permission to send push notifications.
                After that, you can opt out of push notifications for a specific team here.
              </p>
            </div>
          </div>

          <div class="button-area text-right m-t-md">
            <div v-show="error">
              <small>
                <span class="text-danger">{{ errorMessage }}</span>
              </small>
            </div>
            <ladda-button @lbClicked="pingServer" el-class="btn-primary w-full-xs-only" :loading="saving">
              Save
            </ladda-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TheMask } from 'vue-the-mask';
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { appEmbedMix } from './mixins/app_embed_mix';

export default {
  name: 'notification-preferences',
  mixins: [appEmbedMix],
  components: {
    Spinner,
    TheMask,
    LaddaButton
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    teamName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      retrieving: false,
      phoneNumber: '',
      preferences: null,
      valid: true,

      email: false,
      sms: false,
      push: false,
      pushUserPref: false,
      /*
      Okay so we have push, which is the 'does the operating system allow push notifications'
      We have pushUserPref, which is the 'did the user opt out of push notifications'

      That means we can disable the push checkbox if the push setting is false.
      Push User Pref becomes the new user config setting. 
      */

      phoneError: false,
      error: false,
      errorMessage: 'Error syncing info',
      saving: false,
    };
  },
  mounted() {
    this.getPreferences();
  },
  watch: {
    phoneNumber() {
      this.calculateValid();
    },
  },
  computed: {
    // Basically if push is off we can never send push notifications because we have no record of them being registered for apple/google notifications
    effectivePushUserPref: {
      get() {
        return this.push ? this.pushUserPref : false;
      },
      set(value) {
        this.pushUserPref = value;
      }
    }
  },
  methods: {
    calculateValid() {
      let answerLength = 0;
      if (this.phoneNumber) {
        answerLength = this.phoneNumber.length;
      }
      this.valid = (answerLength === 10);
    },
    validate() {
      this.phoneError = !this.valid;
    },
    getPreferences() {
      const vm = this;
      vm.retrieving = true;
      const url = vm.$apiService.notificationPreferencesUrl();
      axios.get(url)
          .then((response) => {
            vm.preferences = response.data.notification_preferences[0];
            vm.email = vm.preferences.email;
            vm.sms = vm.preferences.sms;
            vm.push = vm.preferences.push;
            vm.pushUserPref = vm.preferences.push_user_pref;
            vm.getPhoneNumber();
          })
          .catch((error) => {
            vm.retrieving = false;
            vm.error = true;
            vm.errorMessage = 'Error retrieving notification preferences';
          });
    },
    getPhoneNumber() {
      const vm = this;
      const url = vm.$apiService.userUrl(vm.userId);
      axios.get(url)
          .then((response) => {
            const number = response.data.phone_number;
            if (number) {
              // hard code the removal of the +1
              vm.phoneNumber = number.substring(2);
            }
            vm.retrieving = false;
          })
          .catch((error) => {
            vm.retrieving = false;
            vm.error = true;
            vm.errorMessage = 'Error retrieving notification preferences (phone number)';
          });
    },
    pingServer: _.throttle(function () {
      const vm = this;
      if (vm.saving) {
        return;
      }

      if (vm.sms && !vm.valid) {
        return;
      }

      vm.saving = true;

      if (vm.sms) {
        // In this case we first update the user, then if successful, update prefs in success handler
        vm.updateUser();
      } else {
        vm.updatePreferences();
      }
    }, 500),
    updatePreferences() {
      const vm = this;
      const params = {
        notification_preference: {
          email: vm.email,
          sms: vm.sms,
          push: vm.push,
          push_user_pref: vm.pushUserPref,
        },
      };
      const url = vm.$apiService.notificationPreferenceUrl(vm.preferences.id);
      axios.put(url, params)
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Preferences updated', true);
            if (vm.forAppEmbed) {
              vm.askAppToPop({
                name: "notification_preferences_updated",
                data: {
                  email: vm.email,
                  sms: vm.sms,
                  push_user_pref: vm.pushUserPref,
                }
              });
            } else {
              window.location.assign('/settings');
            }
          })
          .catch((error) => {
            vm.saving = false;
            vm.error = true;
            vm.error = 'Error saving preferences';
          });
    },
    updateUser() {
      const vm = this;
      const params = {
        user: {
          phone_number: `+1${vm.phoneNumber}`,
        },
      };
      const url = vm.$apiService.userUrl(vm.userId);

      axios.put(url, params)
          .then((response) => {
            vm.updatePreferences();
          })
          .catch((error) => {
            vm.saving = false;
            vm.error = true;
            vm.error = 'Error saving preferences';
          });
    },
  },
};
</script>
