<template>
    <div class="ibox">
        <div class="ibox-title">
            <div class="row">
                <div class="col-xs-6 col-md-8">
                    <h1>
                       <span v-if="eventName !== ''">{{ eventName }} </span> Stats Report, <span v-if="rosterName !== ''">{{ rosterName }}, </span>{{ reportSubTitle() }}
                    </h1>
                    <p class="m-b-none"><small>
                        Report generated on {{ processedAt() }}
                    </small></p>
                </div>
                <div class="col-xs-6 col-md-4 text-right">
                    <div class="ibox-tools">
                        <a class="btn btn-primary btn-compact m-t-sm" @click="downloadCSV()"><i class="fa fa-download"></i> <span class="hidden-xs">Download CSV</span></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="ibox-content">
            <div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <template v-for="(statName, index) in headers">
                                <template v-if="index === 0">
                                    <th ref="lnameHeader" class="first-col" v-bind:style="firstColStyles">Last name</th>
                                </template>
                                <template v-else-if="index === 1">
                                    <th v-bind:style="secondColStyles">First name</th>
                                </template>
                                <template v-else>
                                    <th v-bind:style="secondColStyles">{{ readableStatName(statName) }}</th>
                                </template>
                            </template>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row, index) in statLines">
                            <td class="first-col"
                                v-bind:style="firstColStyles"
                            >
                                {{ row.wrestler.last_name }}
                            </td>
                            <td v-bind:style="secondColStyles">{{ row.wrestler.first_name }}</td>
                            <td v-for="stat in statHeaders" class="text-align-right">
                                {{ countForStat(index, stat) }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { downloadMix } from '../mixins/download_mix';

export default {
  name: 'roster-stats-report',
  mixins: [downloadMix],
  props: [
    'report',
  ],
  data() {
    return {
      statLines: [],
      statHeaders: [],
      headers: [],

      firstColStyles: {
        width: '32px',
        position: 'relative',
        zIndex: '999',
      },
      secondColStyles: {
        paddingLeft: '36px',
      },

    };
  },
  computed: {
    rosterName() {
      return this.report.result.roster_name ?? "";
    },
    eventName() {
      return this.report.result.event_name ?? "";
    }
  },
  mounted() {
    const vm = this;
    // bad... implicit logic on the front end
    vm.headers = _.get(vm.report, 'result.stat_lines.headers', []);
    vm.statHeaders = _.slice(vm.headers, 2);

    const statLines = _.get(vm.report, 'result.stat_lines.objects', null);
    vm.statLines = _.sortBy(statLines, [function (sl) { return sl.wrestler.last_name; }]);

    vm.$nextTick(function () {
      const header = this.$refs.lnameHeader;
      if (header) {
        const rect = header.getBoundingClientRect();
        this.$set(this.firstColStyles, 'width', `${rect.width}px`);
        this.$set(this.firstColStyles, 'position', 'absolute');

        this.$set(this.secondColStyles, 'paddingLeft', `${rect.width + 4}px`);
      }
    });
  },
  methods: {
    countForStat(wrestlerAtIndex, statName) {
      const vm = this;
      const statObj = vm.statLines[wrestlerAtIndex];
      return _.get(statObj, `stat_lines.${statName}`, 0);
    },
    readableStatName(name) {
      return _.capitalize(name.replace('_', ' '));
    },
    processedAt() {
      const vm = this;
      return moment(vm.report.processed_at).format('M/D/YYYY, hh:mm a');
    },
    reportSubTitle() {
      const vm = this;
      const start = moment(vm.report.start_at).format('MMM D, YYYY');
      const end = moment(vm.report.end_at).format('MMM D, YYYY');
      return `${start} to ${end}`;
    },
    downloadCSV() {
      const vm = this;

      const rows = [];
      const nameHeaders = [
        'Last name',
        'First name',
      ];
      const readableStatHeaders = _.map(vm.statHeaders, (sh) => vm.readableStatName(sh));
      rows.push(nameHeaders.concat(readableStatHeaders));

      vm.statLines.forEach((sl, index) => {
        const wp = sl.wrestler;
        const values = [
          wp.last_name,
          wp.first_name,
        ];
        const stats = _.map(vm.statHeaders, (sh) => vm.countForStat(index, sh));
        rows.push(values.concat(stats));
      });

      const prefix = vm.eventName ? `${vm.eventName}` : 'Roster';
      const fileName = `${prefix} Stats Report ${vm.rosterName} - ${vm.reportSubTitle()} - ${vm.processedAt()}.csv`;
      this.downloadCSVFile(fileName, rows);
    },
  },
};
</script>
