<template>
  <tbody class="border border-gray-100 border-b border-t-none border-solid">
    <tr class="relative">
      <td class="p-r-sm" data-title="Event">
        <div class="font-heavy text-gray-700 m-t-xxs">
          <div :class="labelClass" class="label label-primary font-normal border border-solid">
            <i :class="eventIcon" class="fa"></i>&nbsp;{{ eventTypeDisplay }}
          </div>
        </div>
      </td>
      <td class="text-gray-700" data-title="Date">
        {{ formatWithMoment(row.created_at, 'M/D/YYYY hh:mm a') }}
      </td>
      <td class="text-gray-700" data-title="Wrestler(s)">
        <div v-for="wrestler in wrestlerProfiles">
          <a :href="`/wrestlers/${wrestler.id}`" target="_blank">{{ wrestler.full_name }}</a>
        </div>
      </td>
      <td class="text-gray-700" data-title="Billed to">
        <div v-html="parentName(true, row.billing_subscription)"></div>
      </td>
      <td class="text-gray-700" data-title="Amount">
        <div>
          {{ formatAsCurrency(subscriptionTotal(row.billing_subscription)) }}{{ subInterval(row.billing_subscription) }}
        </div>
        <div class="flex flex-wrap gap-x-xs align-items-center">
          <div v-if="coupon" style="margin-bottom: -4px;">
            <VDropdown theme="info-tooltip">
              <!-- This will be the popover target (for the events and position) -->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-purple-600">
                <path fill-rule="evenodd" d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 0 1-.375.65 2.249 2.249 0 0 0 0 3.898.75.75 0 0 1 .375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 17.625v-3.026a.75.75 0 0 1 .374-.65 2.249 2.249 0 0 0 0-3.898.75.75 0 0 1-.374-.65V6.375Zm15-1.125a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75Zm.75 4.5a.75.75 0 0 0-1.5 0v.75a.75.75 0 0 0 1.5 0v-.75Zm-.75 3a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-1.5 0v-.75a.75.75 0 0 1 .75-.75Zm.75 4.5a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-.75ZM6 12a.75.75 0 0 1 .75-.75H12a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 12Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clip-rule="evenodd" />
              </svg>
              <!-- This will be the content of the popover -->
              <template v-slot:popper>
                <div>
                  <p class="header">
                    Scholarship code {{ coupon.code }} applied
                  </p>
                </div>
              </template>
            </VDropdown>
          </div>  
          <div>{{ cleanPlanNames(row.billing_subscription) }}</div>
        </div>
      </td>
      <td class="cell-max-w-250-lg cell-min-w-225-lg" data-title="Actions">
        <div class="flex flex-col gap-y-xs">
          <a v-if="showViewSubscription" :href="`/subscriptions/${row.billing_subscription.billing_partner_id}`" target="_blank"
            class="btn no-underline btn-compact bg-white border border-gray-400 text-gray-700">
              <span class="text-12">View Subscription</span>
          </a>
          
          <div v-if="noteEditable && !editingNotes" class="flex flex-col gap-xxs">
            <button @click="editingNotes = true" 
                    class="btn btn-default btn-compact text-12">
              <i class="fa fa-sticky-note-o"></i> {{ row.notes ? 'Edit Note' : 'Add Note' }}
            </button>
            <p v-if="row.notes" class="m-t-xxs text-gray-600 m-t-none">
              <span class="text-semi">Note:</span> {{ row.notes }}
            </p>
          </div>

          <div v-else-if="noteEditable && editingNotes" class="w-full">
            <div class="form-group w-full">
              <textarea v-model="draftNotes"
                        class="form-control"
                        rows="2"
                        style="resize: none"
                        placeholder="Add notes here..">
              </textarea>
              <div class="flex flex-col gap-x-xs m-t-xs">
                <ladda-button @lbClicked="save"
                             el-class="btn-primary w-full"
                             :loading="saving">
                  Save
                </ladda-button>
                <button v-show="!saving"
                        @click="editingNotes = false"
                        class="btn btn-white w-full">
                  Cancel
                </button>
              </div>
              <p v-if="error" class="text-danger m-t-xs text-12">
                Error saving note
              </p>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { formattingMixin } from './mixins/formatting_mix';
import { billingPlanMix } from './mixins/billing_plan_mix';
import LaddaButton from "../../shared/ladda_button.vue";

export default {
  name: 'subscription-event-row',
  components: {
    LaddaButton
  },
  mixins: [formattingMixin, billingPlanMix],
  props: {
    row: {
      type: Object,
      required: true
    },
    noteEditable: {
      type: Boolean,
      default: false
    },
    showViewSubscription: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      editingNotes: false,
      draftNotes: this.row.notes || '',
      saving: false,
      error: false
    }
  },
  computed: {
    coupon() {
      return _.first(this.row.billing_subscription?.coupons);
    },
    wrestlerProfiles() {
      let items = _.filter(this.row.billing_subscription.billing_plan_subscription_items, (si) => {
        const plan = this.planForItem(si, this.row.billing_subscription);
        return plan && !plan.is_wiq_fee && !plan.is_tax_item;
      });

      return _.uniqBy(_.flatMap(items, (si) => {
        return si.wrestler_profiles;
      }), 'id');
    },
    billableUrl() {
      const billable = this.row.billing_subscription.billing_profile.billable;
      switch (billable?.type) {
        case 'ParentProfile':
          return `/parents/${billable.id}`;
        case 'CoachProfile':
          return `/coaches/${billable.id}`;
        default:
          return null;
      }
    },
    displayNameOrEmail() {
      const profile = this.row.billing_subscription.billing_profile;
      return profile.billable?.display_name || profile.email;
    },
    eventTypeDisplay() {
      const displays = {
        'subscription.created': 'Created',
        'subscription.renewed': 'Renewed',
        'subscription.canceled': 'Canceled',
        'bill_date.changed': 'Bill Date Changed',
        'payments.paused': 'Payments Paused',
        'payments.resumed': 'Payments Resumed'
      }
      return displays[this.row.event_name] || this.row.event_name
    },
    labelClass() {
      const classes = {
        'subscription.created': 'bg-green-50 text-green-800 border-green-500',
        'subscription.renewed': 'bg-green-50 text-green-800 border-green-500',
        'subscription.canceled': 'bg-red-50 text-red-800 border-red-800',
        'bill_date.changed': 'bg-yellow-50 text-yellow-800 border-yellow-500',
        'payments.paused': 'bg-yellow-50 text-yellow-800 border-yellow-500',
        'payments.resumed': 'bg-blue-50 text-blue-800 border-blue-500'
      }
      return classes[this.row.event_name] || 'bg-gray-50 text-gray-800 border-gray-500'
    },
    eventIcon() {
      const icons = {
        'subscription.created': 'fa-plus',
        'subscription.renewed': 'fa-refresh',
        'subscription.canceled': 'fa-times',
        'bill_date.changed': 'fa-pencil',
        'payments.paused': 'fa-pause',
        'payments.resumed': 'fa-play'
      }
      return icons[this.row.event_name] || 'fa-circle'
    }
  },
  methods: {
    save: _.throttle(function() {
      const vm = this;
      if (vm.saving) return;

      vm.saving = true;
      vm.error = false;

      axios.put(`/api/v1/billing_subscription_events/${vm.row.id}`, {
        billing_subscription_event: {
          notes: vm.draftNotes
        }
      }).then(() => {
        vm.row.notes = vm.draftNotes;
        vm.editingNotes = false;
        vm.saving = false;
        vm.$notificationManager.$emit('show-toast', 'Note saved successfully', true);
      }).catch(() => {
        vm.error = true;
        vm.saving = false;
      });
    }, 500)
  }
}
</script>