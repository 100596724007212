<template>
    <div>
        <paged-list response-key="wrestlers" object-type="WrestlerProfile" v-if="wrestlersUrl" :base-url="wrestlersUrl">
            <template v-slot:list="slotProps">
                <roster-card
                        v-bind:no-hr="slotProps.index === 0"
                        v-bind:wrestler="slotProps.result"
                        v-bind:show-invite="showInvite"
                        v-bind:show-email="showEmail"
                        :allow-edit="allowEdit"
                        v-bind:key="slotProps.result.id">
                    ></roster-card>
            </template>
        </paged-list>
    </div>
</template>
<script>
import PagedList from './paged_list.vue';
import RosterCard from './roster_card.vue';

export default {
  name: 'filtered-wrestler-list',
  components: { RosterCard, PagedList },
  props: {
    showInvite: {
      type: Boolean,
      default: false,
    },
    showEmail: {
      type: Boolean,
      default: false,
    },
    pauseInitialLoad: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      wrestlersUrl: null,
      rosterIds: [],
      sort: 'weight',
      rosterFilterStrategy: 'union',
    };
  },
  computed: {
    rosterFilterEngaged() {
      return this.rosterIds.length > 0;
    }
  },
  created() {
    const sort = localStorage.getItem('roster_sort_order');
    if (sort) {
      this.sort = sort;
    }

    if (!this.pauseInitialLoad) {
      this.recalculateUrl();
    }

    this.$notificationManager.$on('roster-filter-applied', this.changeRoster);
    this.$notificationManager.$on('sort-filter-applied', this.changeSort);
  },
  destroyed() {
    this.$notificationManager.$off('sort-filter-applied', this.changeRoster);
    this.$notificationManager.$off('sort-filter-applied', this.changeSort);
  },
  methods: {
    recalculateUrl() {
      let newUrl = this.$apiService.filteredWrestlers(this.rosterFilterEngaged, this.rosterIds, this.sort, 100, this.rosterFilterStrategy);
      if (newUrl !== this.wrestlersUrl) {
        this.wrestlersUrl = newUrl;
      }
    },
    changeRoster(rosters, strategy) {
      this.rosterIds = _.map(rosters, 'id');
      this.rosterFilterStrategy = strategy;
      this.recalculateUrl();
    },
    changeSort(sort) {
      this.sort = sort;
      this.recalculateUrl();
    }
  },
};
</script>
