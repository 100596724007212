<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <div class="ibox">
          <div class="ibox-title m-b-none">
            <div class="flex justify-space-between align-items-baseline">
              <div class="flex">
                <h1>Print jobs</h1>
                <spinner v-if="loading.print_jobs"></spinner>
              </div>
              <div>
                <a @click.prevent="loadPrintJobs"><i class="fa fa-refresh"></i> Refresh</a>
              </div>
            </div>
          </div>
          <div class="ibox-content p-n">
            <div v-if="!loading.print_jobs" class="print-job-grid grid grid-cols-3 sm-grid-cols-1 md-grid-cols-2 border">
              <div v-for="job in this.printJobs" class="flex justify-space-between align-items-baseline">
                <div class="flex">
                  <h3>
                    Print job {{ job.id }}
                  </h3>
                </div>
                <div class="flex align-items-baseline">
                  <p class="upper-label">
                    {{ job.status }}
                  </p>
                  <a :href="job.doc_to_print.url" v-if="job.status === 'ready'" class="m-l-sm"><i class="fa fa-download"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="ibox">
          <div class="ibox-title flex justify-space-between align-items-baseline">
            <h1>Build a print job</h1>
            <a @click.prevent="clearWrestlers"><i class="fa fa-eraser"></i> Clear selection</a>
          </div>
          <div class="ibox-content">
            <div v-if="loading.wrestlers">
              <spinner></spinner>
            </div>
            <div v-if="!loading.wrestlers">
              <div class="row m-t-m">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label>Wrestlers</label>
                    <div class="" style="position:relative;">
                      <input v-model="search" type="text" id="wrestler_search" placeholder="Find by name or weight.."
                             class="form-control" maxlength="25"/>
                      <i @click="search = ''" v-if="search && search.length > 0"
                         class="cursor-pointer fa fa-times color-gray-400"
                         style="position: absolute; top:0; bottom:0; right: 0; transform: translate(-66%, 29%)"></i>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6" v-if="showFilters">
                  <p style="margin-bottom: 5px; font-weight:bold">Filters</p>
                  <div class="form-group">
                    <label class="checkbox-inline">
                      <input type="checkbox" id="gues_checkbox" v-model="showGuests"> Show guest wrestlers
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 m-t-m">
                  <div>
                    <p v-if="wrestlersRemaining > 0">
                      Select up to {{ wrestlersRemaining }} more wrestlers
                    </p>
                    <p v-else-if="wrestlersRemaining === 0">
                      You're all set, click generate to generate the PDF for these 10 wrestlers.
                    </p>
                    <p v-else>
                      You've selected too many wrestlers. Please only select up to 10 wrestlers.
                    </p>
                  </div>
                  <div v-for="wp in searchedProfiles"
                       class="profile-wrapper col-sm-4">
                    <div class="d-inline-block">
                      <input
                          type="checkbox"
                          v-model="wp.checked"
                      />
                    </div>
                    <div class="d-inline-block m-l-xs">
                      <div class='search-result'>
                        <div class='hr-line-dashed'></div>
                        <h3>
                          {{ wp.full_name }}
                          <small class="roster-weight">
                            {{ wp.weight_class }}
                          </small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-t-lg">
                <div class="col-xs-12 text-right">
                  <ladda-button @lbClicked="createPrintJob" el-class="btn-primary" :loading="loading.creating" :disabled="selectedWrestlers.length > 10">
                    Generate
                  </ladda-button>
                  <div>
                    <small v-show="tooManyWrestlers"><span class="text-danger">Too many wrestlers selected. Max of 10 id cards per print job.</span></small>
                    <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { errorableMix } from './mixins/errorable_mix';
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'print-wiq-ids',
  components: {
    LaddaButton,
    Spinner
  },
  mixins: [errorableMix],
  props: {
    showFilters: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      wrestlers: [],
      printJobs: [],
      search: '',
      loading: {
        wrestlers: false,
        print_jobs: false,
        creating: false,
      },
      showGuests: false,
    }
  },
  watch: {
    // whenever showGuests changes, this function will run
    showGuests() {
      this.loadProfiles();
    },
  },
  computed: {
    wrestlersRemaining() {
      return 10 - this.selectedWrestlers.length;
    },
    tooManyWrestlers() {
      return this.selectedWrestlers.length > 10;
    },
    searchedProfiles() {
      if (!this.search || this.search === '') {
        return this.wrestlers;
      }

      if (isNaN(this.search)) {
        return this.wrestlers.filter(wp => wp.full_name.toLowerCase()
            .includes(this.search));
      } else {
        return this.wrestlers.filter(wp => wp.weight_class.includes(this.search));
      }
    },
    selectedWrestlers() {
      return this.wrestlers.filter(wp => wp.checked);
    },
    printJobParams() {
      return {
        name: "WIQ Batch Print Job",
        version: "v1",
        type: "WiqIdPrintJob",
        args: {
          wrestler_profile_ids: _.map(this.selectedWrestlers, 'id')
        }
      }
    }
  },
  created() {
    this.loadProfiles();
    this.loadPrintJobs();
  },
  methods: {
    clearWrestlers() {
      let vm = this;
      this.wrestlers.forEach( (wp)=> { vm.$set(wp, 'checked', false) })
    },
    createPrintJob() {
      const url = `${this.$apiService.printJobsUrl()}`;
      this.loading.creating = true;
      axios.post(url, this.printJobParams)
          .then((response) => {
            this.$notificationManager.$emit('show-toast', `Print job ${response.data.id} queued`, true);
            this.loading.creating = false;
            this.loadPrintJobs();
          })
          .catch((error) => {
            this.loading.creating = false;
            this.error = true;
            this.errorMessage = 'Error creating print job';
          });
    },
    loadPrintJobs() {
      this.loading.print_jobs = true;
      axios.get(this.$apiService.printJobsUrl())
      .then((response) => {
        this.printJobs = response.data.print_jobs;
        this.loading.print_jobs = false;
      }).catch((error) => {
        this.loading.print_jobs = false;
        this.error = true;
        this.errorMessage = 'Error loading print jobs';
      });
    },
    loadProfiles() {
      const url = this.$apiService.filteredWrestlers(this.showGuests, [], null, 100, 'union');
      this.loading.wrestlers = true;
      this.$apiService.loadAllPages(url, 'wrestlers')
          .then((objects) => {
            this.wrestlers = objects;
            this.loading.wrestlers = false;
          })
          .catch((error) => {
            this.loading.wrestlers = false;
            this.errorMessage = `Error retrieving wrestlers ${error.toString()}`;
            this.error = true;
          });
    },
  }
};
</script>
