<template>
  <div class="ibox">
    <div class="ibox-content p-t-m">
      <div class="row">
        <div class="col-xs-12 col-md-2 hidden-print">
          <div class="m-t-xl m-t-only-xs-m">
            <a href="/event_invites" class="btn btn-block btn-outline btn-primary">
              <i class="fa fa-inbox m-r-xs"></i>
                Invites
                <template v-if="pendingEventInviteCount > 0">
                  <span class="badge" style="background-color: #27AA83; color: white; margin-right: 1px;">{{ pendingEventInviteCount }} pending</span>
                </template>
            </a>
            <p class="text-10 text-center m-t-xxs">Respond to events you've been invited to</p>
          </div>
          <hr/>
          <div role="form" class="calendar-filter p-t-xs">
            <h3 class="m-t-none font-semi m-b-md">Filters</h3>
            <vertical-expander expand-for="EventType" :default-open="true">
              <template v-slot:title>
                <p class="m-t-none font-heavy m-b-xs">Event type</p>
              </template>
              <template v-slot:content>
                <div class="grid grid-cols-1 gap-y-3">
                  <div class="form-group m-b-none">
                    <label class="checkbox-inline matches">
                      <input type="checkbox" v-model="matchesVisible"> Matches
                      <spinner v-if="matchesLoading"></spinner>
                    </label>
                  </div>
                  <div class="form-group m-b-none">
                    <label class="checkbox-inline other">
                      <input type="checkbox" v-model="otherVisible"> Other
                      <spinner v-if="otherLoading"></spinner>
                    </label>
                  </div>
                  <div class="form-group m-b-none">
                    <label class="checkbox-inline practice">
                      <input type="checkbox" v-model="practiceVisible"> Practices
                      <spinner v-if="practiceLoading"></spinner>
                    </label>
                  </div>
                  <div class="form-group m-b-none" v-if="showPrivateLessons">
                    <label class="checkbox-inline private_lessons">
                      <input type="checkbox" v-model="privateLessonsVisible"> Private lessons
                      <spinner v-if="privateLessonsLoading"></spinner>
                    </label>
                  </div>
                  <div class="form-group m-b-none">
                    <label class="checkbox-inline tournament">
                      <input type="checkbox" v-model="tournamentVisible"> Tournaments
                      <spinner v-if="tournamentLoading"></spinner>
                    </label>
                  </div>
                </div>
              </template>
            </vertical-expander>
            <vertical-expander expand-for="RosterFilter" :default-open="true">
              <template v-slot:title>
                <p class="m-t-md font-heavy m-b-xs">Roster</p>
              </template>
              <template v-slot:content>
                <div class="grid grid-cols-1 gap-y-1">
                  <div>
                    <label>
                      <input type="radio" value="all" id="all-rosters" v-model="rosterFilter"> All rosters
                    </label>
                  </div>
                  <div>
                    <label>
                      <input type="radio" value="specific" id="specific-rosters" v-model="rosterFilter"> Specific roster(s)
                    </label>
                    <div class="-m-t-xs" v-if="rosterFilter === 'specific'">
                      <div class="flex flex-wrap gap-xxs m-b-xs m-t-xs">
                        <button @click="toggleRoster(r)" class="btn btn-success btn-outline rounded-lg font-small"
                                style="padding: 2px 6px;" v-for="r in rostersForFilterArray">
                          {{ r.name }}
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                      <search
                          class="search-form"
                          response-key="rosters"
                          placeholder="Type the name of a roster..."
                          :mutate-history="false"
                          :allow-add="false"
                          only-dismiss-model="Roster"
                          :small-input="true"
                          :display-all-on-empty="false"
                          readable-model-name="roster"
                          base-url="/api/v1/rosters">
                        <template v-slot:list="slotProps">
                          <div
                              class="border-top m-t-sm m-b-xs p-t-sm p-b-xs cursor-pointer flex justify-space-between align-items-center p-r-sm"
                              @click="toggleRoster(slotProps.result)">
                            <div>
                              {{ slotProps.result.name }}
                            </div>
                            <div class="border rounded-sm border-blue-200 border-solid font-small"
                                 style="padding: 2px 6px;">
                              Filter
                            </div>
                          </div>
                        </template>
                      </search>
                    </div>
                  </div>
                </div>
              </template>
            </vertical-expander>
          </div>
          <div id="calendar-delete">
            <p class="text-danger" v-show="dragging">
              <i class="fa fa-trash-o m-r-xs"></i><span>Delete Event</span>
            </p>
            <delete-event-form v-if="deleteForm" :event="eventForDeletion" :recurring="deleteEventRecurring">
            </delete-event-form>
          </div>
          <div v-if="error">
            <small><span class="text-danger">{{ errorMessage }}</span></small>
          </div>
          <hr/>
          <div>
            <button class="btn btn-success btn-outline btn-block" @click="toggleSubscribeModal()">Subscribe...</button>
            <p class="text-10 text-center m-t-xxs">Sync to your personal calendar</p>
          </div>
        </div>
        <div class="col-xs-12 col-md-10">
          <div>
            <div ref="calendar">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="event-create-modal" class="modal fade hidden-print" tabindex="-1" role="dialog" data-keyboard="false"
         data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <event-create v-if="creatingEvent"
                          :start="potentialStartDate"
                          :end="potentialEndDate"
                          :for-calendar="true"
                          :paid-session-tagging="showPaidSessionTagging"
                          :endpoint="endpoint"
                          :access-key="accessKey"
                          :auth-token="authToken"
                          :cloudfront-base-url="cloudfrontBaseUrl"
            ></event-create>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
    <div id="event-update-modal" class="modal fade hidden-print" tabindex="-1" role="dialog" data-keyboard="false"
         data-backdrop="static">
      <div class="modal-dialog" role="document">
            <div class="modal-content p-lg">
                <div>
                    <div class="flex gap-sm align-items-center justify-space-between m-b-sm">
                        <h2 class="font-heavy m-t-none m-b-none">Update Repeated Events?</h2>
                        <div class="flex h-12 w-12 align-items-center justify-center rounded-full bg-green-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-green-700">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
                            </svg>
                        </div>
                    </div>
                    <repeated-event-update v-if="updatingRepeatedEvent" :event="repeatedEventForUpdate" :params="repeatingEventForUpdateParams"></repeated-event-update>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
    <div id="event-notify-modal" class="modal fade hidden-print" tabindex="-1" role="dialog" data-keyboard="false"
         data-backdrop="static">
         <div class="modal-dialog" role="document">
            <div class="modal-content p-lg">
              <div>
                <div class="flex gap-sm align-items-center justify-space-between m-b-sm">
                  <h2 class="font-heavy m-t-none m-b-none">Notifications</h2>
                  <div class="flex h-12 w-12 align-items-center justify-center rounded-full bg-green-100">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-green-700">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
                    </svg>
                  </div>
                </div>
                <event-change-notify v-if="eventNotify" :event="eventForNotify" :recurring-change="recurringChanged"></event-change-notify>
              </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
    <div id="subscribe-modal" class="modal fade hidden-print" tabindex="-1" role="dialog" data-keyboard="false"
         data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Subscribe to the team calendar</h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-info">
              <strong>Note:</strong> If you subscribe to the team calendar in another system, it will update once every
              5 minutes to 24 hours depending on the app used.<br/><br/>
              If you need real-time calendar updates please be sure to check the calendar in WrestlingIQ or pay
              attention to the email notifications when events change.
            </div>
            <div class="m-t-lg">
              <h2>
                On a mobile phone, using Apple iCal, or a desktop app?
              </h2>
              <p class="m-t-sm">
                Tap the button below to launch your calendar app, which will prompt you to follow a few steps to
                subscribe to the calendar.
              </p>
              <a class="btn btn-default" :href="webcal">Subscribe to calendar</a>
            </div>
            <div class="m-t-lg">
              <h2>
                Using Google Calendar or another web-based calendar?
              </h2>
              <p class="m-t-sm">
                Copy and paste the URL below into your calendar system. Depending on the system, this might be done
                either by
                "from URL" or "subscribe by URL". A step by step guide for <a
                  href="https://help.wrestlingiq.com/article/30-subscribe-to-a-team-calendar" target="_blank">Google
                Calendar can be found here</a>.
              </p>
              <code>{{ webcalHttp }}</code>
            </div>
            <div class="button-area text-right m-t-lg">
              <button type="button" class="btn btn-primary" @click="toggleSubscribeModal()">Done</button>
            </div>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </div>
</template>
<script>
import EventCreate from './event_create.vue';
import EventChangeNotify from './event_change_notify.vue';
import Spinner from './spinner.vue';
import DeleteEventForm from './delete_event_form.vue';
import RepeatedEventUpdate from './repeated_event_update.vue';
import VerticalExpander from './vertical_expander.vue';
import Search from '../../shared/search.vue';

export default {
  name: 'calendar',
  components: {
    DeleteEventForm,
    EventChangeNotify,
    EventCreate,
    RepeatedEventUpdate,
    Search,
    Spinner,
    VerticalExpander,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    webcal: {
      type: String,
      required: false,
    },
    webcalHttp: {
      type: String,
      required: false,
    },
    showPaidSessionTagging: {
      type: Boolean,
      default: false,
    },
    showPrivateLessons: {
      type: Boolean,
      default: false,
    },
    accessKey: {
      type: String,
      required: false,
    },
    authToken: {
      type: String,
      required: false,
    },
    cloudfrontBaseUrl: {
      type: String,
      required: false,
    },
    endpoint: {
      type: String,
      required: false,
    },
    teamId: {
      type: String,
      default: "default-key",
    },
    pendingEventInviteCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    const vm = this;
    return {
      dragging: false,
      deleteForm: false,
      eventForDeletion: null,
      deleteEventRecurring: false,

      potentialStartDate: null,
      potentialEndDate: null,
      creatingEvent: false,
      cal: null,
      error: false,
      errorMessage: '',

      eventNotify: false,
      eventForNotify: false,
      recurringChanged: false,

      repeatedEventForUpdate: null,
      revertUpdateFunc: null,
      repeatingEventForUpdateParams: null,
      updatingRepeatedEvent: false,

      matchesLoading: false,
      matchesVisible: true,
      matchesSource: {
        id: 'matches',
        events(start, end, timezone, callback) {
          let params = `?event_type[]=dual_meet&event_type[]=scramble&start=${start.toISOString()}&end=${end.toISOString()}&preventCache=${new Date()}`;
          if (vm.rosterIdParams.length > 0) {
            params = `${params}${vm.rosterIdParams}`;
          }
          const url = vm.$apiService.eventsUrl(params);
          vm.matchesLoading = true;
          vm.$apiService.loadAllPages(url, 'events')
              .then((events) => {
                const fcEvents = vm.formatFCEvents(events);
                callback(fcEvents);
                vm.matchesLoading = false;
              })
              .catch((error) => {
                vm.matchesLoading = false;
                vm.error = true;
                vm.errorMessage = error.toString();
              });
        },
      },

      otherLoading: false,
      otherVisible: true,
      otherSource: {
        id: 'other',
        events(start, end, timezone, callback) {
          let params = `?event_type=other&start=${start.toISOString()}&end=${end.toISOString()}&preventCache=${new Date()}`;
          if (vm.rosterIdParams.length > 0) {
            params = `${params}${vm.rosterIdParams}`;
          }
          const url = vm.$apiService.eventsUrl(params);
          vm.otherLoading = true;
          vm.$apiService.loadAllPages(url, 'events')
              .then((events) => {
                const fcEvents = vm.formatFCEvents(events);
                callback(fcEvents);
                vm.otherLoading = false;
              })
              .catch((error) => {
                vm.otherLoading = false;
                vm.error = true;
                vm.errorMessage = error.toString();
              });
        },
      },

      practiceLoading: false,
      practiceVisible: true,
      practiceSource: {
        id: 'practice',
        events(start, end, timezone, callback) {
          let params = `?event_type=practice&start=${start.toISOString()}&end=${end.toISOString()}&preventCache=${new Date()}`
          if (vm.rosterIdParams.length > 0) {
            params = `${params}${vm.rosterIdParams}`;
          }
          const url = vm.$apiService.eventsUrl(params);
          vm.practiceLoading = true;
          vm.$apiService.loadAllPages(url, 'events')
              .then((events) => {
                const fcEvents = vm.formatFCEvents(events);
                callback(fcEvents);
                vm.practiceLoading = false;
              })
              .catch((error) => {
                vm.practiceLoading = false;
                vm.error = true;
                vm.errorMessage = error.toString();
              });
        },
      },

      tournamentLoading: false,
      tournamentVisible: true,
      tournamentSource: {
        id: 'tournament',
        events(start, end, timezone, callback) {
          let params = `?event_type=tournament&start=${start.toISOString()}&end=${end.toISOString()}&preventCache=${new Date()}`;
          if (vm.rosterIdParams.length > 0) {
            params = `${params}${vm.rosterIdParams}`;
          }
          const url = vm.$apiService.eventsUrl(params);
          vm.tournamentLoading = true;
          vm.$apiService.loadAllPages(url, 'events')
              .then((events) => {
                const fcEvents = vm.formatFCEvents(events);
                callback(fcEvents);
                vm.tournamentLoading = false;
              })
              .catch((error) => {
                vm.tournamentLoading = false;
                vm.error = true;
                vm.errorMessage = error.toString();
              });
        },
      },

      privateLessonsLoading: false,
      privateLessonsVisible: true,
      privateLessonsSource: {
        id: 'private_lesson',
        events(start, end, timezone, callback) {
          let params = `?event_type=private_lesson&start=${start.toISOString()}&end=${end.toISOString()}&preventCache=${new Date()}`
          if (vm.rosterIdParams.length > 0) {
            params = `${params}${vm.rosterIdParams}`;
          }
          const url = vm.$apiService.eventsUrl(params);
          vm.privateLessonsLoading = true;
          vm.$apiService.loadAllPages(url, 'events')
              .then((events) => {
                const fcEvents = vm.formatFCEvents(events);
                callback(fcEvents);
                vm.privateLessonsLoading = false;
              })
              .catch((error) => {
                vm.privateLessonsLoading = false;
                vm.error = true;
                vm.errorMessage = error.toString();
              });
        },
      },

      rosterFilter: "all",
      rostersForFilter: {},
    };
  },
  computed: {
    rostersForFilterArray() {
      return _.flatMap(this.rostersForFilter);
    },
    rosterIdsForFilter() {
      // shortcircuit for all, so that you can keep the specific ones toggled if you want
      if (this.rosterFilter === "all") {
        return [];
      }
      return _.map(this.rostersForFilterArray, 'id');
    },
    rosterIdParams() {
      let params = ''
      this.rosterIdsForFilter.forEach((rId) => {
        params = `${params}&roster_ids[]=${rId}`;
      })

      return params;
    }
  },
  mounted() {
    const vm = this;
    let savedCalendarView = 'month';
    try {
      const savedRostersForFilter = localStorage.getItem(vm.keyForLocalTeamStorage('rosters_for_filter'));
      if (savedRostersForFilter) {
        this.rostersForFilter = JSON.parse(savedRostersForFilter);
      }

      const savedRosterFilter = localStorage.getItem(vm.keyForLocalTeamStorage('roster_filter'));
      if (savedRosterFilter) {
        this.rosterFilter = savedRosterFilter;
      }

      savedCalendarView = localStorage.getItem(vm.keyForLocalTeamStorage('calendar_view'));
    } catch (e) {
      // If we fail, assume this was corrupted client side, clear it out and return
      localStorage.removeItem(vm.keyForLocalTeamStorage('rosters_for_filter'));
      localStorage.removeItem(vm.keyForLocalTeamStorage('roster_filter'));
      localStorage.removeItem(vm.keyForLocalTeamStorage('calendar_view'));
    }

    // Event handlers
    vm.$notificationManager.$on('hide-delete-event-form', vm.hideDeleteForm);
    vm.$notificationManager.$on('event-deleted', vm.eventDeleted);
    vm.$notificationManager.$on('cancel-event-create', vm.cancelEventCreate);
    vm.$notificationManager.$on('event-created', vm.eventCreated);
    vm.$notificationManager.$on('repeated-event-update-cancel', vm.cancelUpdateEvent);
    vm.$notificationManager.$on('repeated-event-updated', vm.recurringEventUpdated);
    vm.$notificationManager.$on('event-notify-close', vm.closeNotifyEvent);

    // Calendar
    vm.cal = $(vm.$refs.calendar);

    let defaultView = savedCalendarView ? savedCalendarView : 'month';
    if ($(window).width() < 500) {
      defaultView = 'listWeek';
    }

    const args = {
      customButtons: {
        createEventBtn: {
          text: '+',
          icon: 'fc-icon-x',
          click() {
            if (vm.editable) {
              vm.potentialStartDate = null;
              vm.potentialEndDate = null;
              vm.creatingEvent = true;
              $(vm.modalId()).modal('show');
            }
          },
        },
      },
      header: {
        left: 'prev,next,createEventBtn',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listWeek',
      },
      dragRevertDuration: 250,
      longPressDelay: 500,
      navLinks: true,
      editable: vm.editable,
      selectable: true,
      selectHelper: true,
      timezone: 'local',
      contentHeight: 'auto',
      defaultView,
      minTime: '04:00:00',
      maxTime: '23:00:00',
      scrollTime: '06:00:00',
      eventClick(event, jsEvent, view) {
        window.location.assign(`/events/${event.id}`);
      },
      select(start, end) {
        if (vm.editable) {
          vm.potentialStartDate = start;
          vm.potentialEndDate = end;
          vm.creatingEvent = true;
          $(vm.modalId()).modal('show');
        }
      },
      eventResize(event, delta, revertFunc) {
        // Hit the API for updating an event end_at or revert if failed
        vm.updateEvent(event, revertFunc);
      },
      eventDrop(event, delta, revertFunc) {
        vm.updateEvent(event, revertFunc);
      },
      eventDragStart(event, jsEvent, ui, view) {
        vm.dragging = true;
        vm.hideDeleteForm();
      },
      eventDragStop(event, jsEvent, ui, view) {
        vm.dragging = false;
        const trashEl = $('#calendar-delete');
        const ofs = trashEl.offset();

        const x1 = ofs.left;
        const x2 = ofs.left + trashEl.outerWidth(true);
        const y1 = ofs.top;
        const y2 = ofs.top + trashEl.outerHeight(true);

        if (jsEvent.pageX >= x1 && jsEvent.pageX <= x2
            && jsEvent.pageY >= y1 && jsEvent.pageY <= y2) {
          vm.eventForDeletion = event;
          vm.deleteEventRecurring = event.recurring;
          vm.deleteForm = true;
        }
      },
      eventSources: [
        vm.matchesSource,
        vm.otherSource,
        vm.practiceSource,
        vm.tournamentSource,
        vm.privateLessonsSource,
      ],
      eventLimit: true,
      viewRender: function(view, element) {
        // This function is called when the view changes
        localStorage.setItem(vm.keyForLocalTeamStorage('calendar_view'), view.name);
      }
    };

    vm.cal.fullCalendar(args);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('hide-delete-event-form', vm.hideDeleteForm);
    vm.$notificationManager.$off('cancel-event-create', vm.eventDeleted);
    vm.$notificationManager.$off('event-created', vm.cancelEventCreate);
    vm.$notificationManager.$off('event-deleted', vm.eventCreated);
    vm.$notificationManager.$off('repeated-event-update-cancel', vm.cancelUpdateEvent);
    vm.$notificationManager.$off('repeated-event-updated', vm.recurringEventUpdated);
    vm.$notificationManager.$off('event-notify-close', vm.closeNotifyEvent);
  },
  watch: {
    practiceVisible(newPracticeVisible) {
      const vm = this;
      if (newPracticeVisible) {
        vm.cal.fullCalendar('addEventSource', vm.practiceSource);
      } else {
        vm.cal.fullCalendar('removeEventSource', 'practice');
      }
    },
    otherVisible(newOtherVisible) {
      const vm = this;
      if (newOtherVisible) {
        vm.cal.fullCalendar('addEventSource', vm.otherSource);
      } else {
        vm.cal.fullCalendar('removeEventSource', 'other');
      }
    },
    privateLessonsVisible(newPrivateLessonsVisible) {
      const vm = this;
      if (newPrivateLessonsVisible) {
        vm.cal.fullCalendar('addEventSource', vm.privateLessonsSource);
      } else {
        vm.cal.fullCalendar('removeEventSource', 'private_lesson');
      }
    },
    matchesVisible(newMatchesVisible) {
      const vm = this;
      if (newMatchesVisible) {
        vm.cal.fullCalendar('addEventSource', vm.matchesSource);
      } else {
        vm.cal.fullCalendar('removeEventSource', 'matches');
      }
    },
    tournamentVisible(newTournamentVisible) {
      const vm = this;
      if (newTournamentVisible) {
        vm.cal.fullCalendar('addEventSource', vm.tournamentSource);
      } else {
        vm.cal.fullCalendar('removeEventSource', 'tournament');
      }
    },
    rosterIdsForFilter() {
      let vm = this;
      vm.cal.fullCalendar('refetchEventSources', [
        vm.matchesSource,
        vm.otherSource,
        vm.practiceSource,
        vm.tournamentSource,
        vm.privateLessonsSource,
      ]);
      localStorage.setItem(vm.keyForLocalTeamStorage('rosters_for_filter'), JSON.stringify(this.rostersForFilter));
    },
    rosterFilter() {
      localStorage.setItem(this.keyForLocalTeamStorage('roster_filter'), this.rosterFilter);
    }
  },
  methods: {
    keyForLocalTeamStorage(name) {
      // method that scopes local storage to this team, so sticky calendar settings is unique across teams (i.e. rosters might not exist there).
      return `${name}-${this.teamId}`;
    },
    // Toggle roster filter
    toggleRoster(roster) {
      this.$notificationManager.$emit('profile-clicked', 'Roster');
      // Delete
      if (this.rostersForFilter[roster.id]) {
        // If we already have this, we should now remove it (this function is essentially a toggle)
        this.$delete(this.rostersForFilter, roster.id);

        return;
      }

      // Or set
      this.$set(this.rostersForFilter, roster.id, roster);
    },
    toggleSubscribeModal() {
      $('#subscribe-modal').modal('toggle');
    },
    eventCreated(event) {
      const vm = this;
      vm.creatingEvent = false;
      $(vm.modalId()).modal('hide');
      vm.refetchSources(event);
    },
    recurringEventUpdated(event, needsRefetch) {
      const vm = this;
      vm.hideRecurringModal();
      if (needsRefetch) {
        vm.refetchSources(event);
      }

      vm.showNotifyModal(event, needsRefetch);
    },
    hideRecurringModal() {
      const vm = this;
      vm.updatingRepeatedEvent = false;
      vm.repeatedEventForUpdate = null;
      vm.repeatingEventForUpdateParams = null;
      $(vm.updateModalId()).modal('hide');
    },
    refetchSources(event) {
      const vm = this;
      // We refetch sources, if the source is currently visible. Otherwise we toggle it on, which will refetch the source anyways via the watchers.
      switch (event.event_type) {
        case 'practice':
          vm.practiceVisible ? vm.cal.fullCalendar('refetchEventSources', 'practice') : vm.practiceVisible = true;
          break;
        case 'dual_meet':
        case 'scramble':
          vm.matchesVisible ? vm.cal.fullCalendar('refetchEventSources', 'matches') : vm.matchesVisible = true;
          break;
        case 'tournament':
          vm.tournamentVisible ? vm.cal.fullCalendar('refetchEventSources', 'tournament') : vm.tournamentVisible = true;
          break;
        case 'other':
          vm.otherVisible ? vm.cal.fullCalendar('refetchEventSources', 'other') : vm.otherVisible = true;
          break;
        case 'private_lesson':
          vm.privateLessonsVisible ? vm.cal.fullCalendar('refetchEventSources', 'private_lesson') : vm.privateLessonsVisible = true;
          break;
      }
    },
    cancelEventCreate() {
      const vm = this;
      vm.creatingEvent = false;
      $(vm.modalId()).modal('hide');
    },
    eventDeleted(event, recurringDeleted) {
      const vm = this;
      vm.refetchSources(event);
      vm.hideDeleteForm();
      vm.showNotifyModal(event, recurringDeleted);
    },
    hideDeleteForm() {
      const vm = this;
      vm.deleteForm = false;
      vm.eventForDeletion = null;
    },
    formatFCEvents(events) {
      const fcEvents = [];
      events.forEach((returnedEvent) => {
        let start = returnedEvent.start_at;
        let end = returnedEvent.end_at;

        const allDay = returnedEvent.all_day;

        // If it is an all day event, the time becomes 'ambigious' meaning that we only deal with dates.
        if (allDay) {
          start = returnedEvent.start_at.split('T')[0];
          end = returnedEvent.end_at.split('T')[0];
        }

        const { color } = returnedEvent;

        const recurring = returnedEvent.has_repeated_events || returnedEvent.parent_id != null;

        const fcEvent = {
          id: returnedEvent.id,
          title: returnedEvent.name,
          allDay,
          start,
          end,
          color,
          recurring,
          event_type: returnedEvent.event_type,
          roster_events: returnedEvent.roster_events,
        };
        fcEvents.push(fcEvent);
      });
      return fcEvents;
    },
    modalId() {
      return '#event-create-modal';
    },
    updateModalId() {
      return '#event-update-modal';
    },
    notifyModalId() {
      return '#event-notify-modal';
    },
    buildParams(event) {
      const start_at = event.start.toISOString();
      let end_at;
      if (event.end) {
        end_at = event.end.toISOString();
      } else {
        // this was dropped from a all day to timed, make it two hours long
        end_at = event.start.add(2, 'h').toISOString();
      }

      let allDay = false;
      if (!event.start.hasTime() || event.allDay) {
        allDay = true;
      }

      return {
        event: {
          start_at,
          end_at,
          all_day: allDay,
        },
      };
    },
    updateEvent(event, revertFunc) {
      const vm = this;
      const params = vm.buildParams(event);

      if (event.recurring) {
        // trigger the update recurring modal
        vm.repeatedEventForUpdate = event;
        vm.repeatingEventForUpdateParams = params;
        vm.revertUpdateFunc = revertFunc;
        vm.updatingRepeatedEvent = true;
        $(vm.updateModalId()).modal('show');
      } else {
        // just do the update
        const updateUrl = vm.$apiService.eventUrl(event.id);
        axios.put(updateUrl, params)
            .then((response) => {
              vm.$notificationManager.$emit('show-toast', `${event.title} updated`, true);
              vm.showNotifyModal(event, false);
            })
            .catch((error) => {
              const errorM = `Failed to update, reverting to previous time. ${error.toString()}`;
              vm.$notificationManager.$emit('show-toast', errorM, false);
              revertFunc();
            });
      }
    },
    cancelUpdateEvent(event) {
      const vm = this;
      if (vm.repeatedEventForUpdate.id === event.id) {
        vm.revertUpdateFunc();
        vm.hideRecurringModal();
        vm.recurringChanged = false;
      }
    },
    showNotifyModal(event, recurring) {
      const vm = this;
      vm.eventForNotify = event;
      vm.recurringChanged = recurring;
      vm.eventNotify = true;
      $(vm.notifyModalId()).modal('show');
    },
    closeNotifyEvent(event) {
      const vm = this;
      vm.eventNotify = false;
      vm.eventForNotify = null;
      $(vm.notifyModalId()).modal('hide');
    },
  },
};
</script>
