<template>
  <div class="archive-form should-dim modify-form m-t-sm">
    <div>
      Select the wrestler to remove:
    </div>
    
    <select v-model="selectedWrestlerId" class="form-control m-t-sm">
      <option value="" disabled>Select a wrestler</option>
      <option v-for="wrestler in eligibleWrestlers" 
              :key="wrestler.id" 
              :value="wrestler.id">
        {{ wrestler.display_name }}
      </option>
    </select>

    <ul class="m-t-sm">
      <li>
        All future invoices will be updated to remove the wrestler
      </li>
      <li>
        Sibling discount will be removed, if only one wrestler remains
      </li>
      <li>
        Refunds will need to be issued separately
      </li>
    </ul>
    <div class="flex flex-col gap-sm">
      <ladda-button @lbClicked="removeWrestler" 
                    el-class="btn-danger" 
                    :loading="loading"
                    :disabled="!selectedWrestlerId">
        Remove Wrestler
      </ladda-button>
      <button v-on:click="hideCancelForm" class="btn btn-default">Cancel</button>
      <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
      
    </div>
  </div>
</template>

<script>
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'remove-wrestler-payment-plan',
  components: { LaddaButton },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Error, please try again later or contact support@wrestlingiq.com',
      selectedWrestlerId: '',
    };
  },
  computed: {
    eligibleWrestlers() {
      return this.plan.registrations.filter(r => r.profile.type === 'WrestlerProfile' && r.status !== 'canceled').map(r => r.profile);
    },
  },
  methods: {
    hideCancelForm() {
      const vm = this;
      vm.$notificationManager.$emit('hide-plan-cancel-form', vm.plan);
    },
    removeWrestler: _.throttle(function () {
      const vm = this;
      if (vm.loading === true || !vm.selectedWrestlerId) {
        return;
      }
      vm.loading = true;

      const url = vm.$apiService.removeWrestlerPaymentPlanUrl(vm.plan.id, vm.selectedWrestlerId);
      axios.delete(url)
          .then((response) => {
            vm.loading = false;
            vm.$notificationManager.$emit('show-toast', 'Wrestler removed', true);
            vm.$notificationManager.$emit('payment-plan-updated', vm.plan);
          })
          .catch((error) => {
            vm.loading = false;
            vm.errorMessage = 'Error, please try again later or contact support@wrestlingiq.com';
            vm.error = true;
          });
    }, 500),
  },
};
</script>
