<template>
  <div>
    <div v-show="expanded" class="form-bordered">
      <div class="alert alert-danger" v-if="!active">
        <strong>This membership option is not active—new members cannot sign up for it.</strong>
        Existing members will keep paying the price they signed up for initially. If you need to do a price raise
        for your existing members please contact support@wrestlingiq.com for assistance processing the price raise.
      </div>
      <div class="form-group">
        <label for="price_name">Name</label>
        <input id="price_name" v-model="name" type="text" class="form-control">
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-sm-4">
            <div>
              <label>Recurring Amount</label>
              <money v-model="amount" v-bind="money" class="form-control" :disabled="existingId"></money>
            </div>
            <div class="m-t-sm">
              <label>One-time Registration Fee</label>
              <money v-model="oneTimeFeeAmount" v-bind="money" class="form-control" :disabled="currentBillingPartner !== 'justifi'"></money>
              <p v-if="currentBillingPartner === 'justifi'">
                <small>If you charge a one-time fee for enrollment/initiation, this amount will be added to the first charge.</small>
              </p>
              <div v-else class="alert alert-warning m-t-sm">
                <p>
                  <small>
                    One-time fees are only available to teams using WrestlingIQ Payments for billing. Please email support@wrestlingiq.com if you want to switch over to WrestlingIQ Payments.
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <label>Every</label>
            <div class="row">
              <div class="col-sm-3">
                <input v-model="intervalCount" type="number" class="form-control" :disabled="existingId"/>
              </div>
              <div class="col-sm-9">
                <select v-model="interval" class="form-control" :disabled="existingId">
                  <option selected disabled>Choose interval...</option>
                  <option value="week">Week(s)</option>
                  <option value="month">Month(s)</option>
                  <option value="year">Year(s)</option>
                </select>
              </div>
            </div>
            <div class="m-t-sm">
              <label>Until</label>
              <div class="row">
                <div class="col-sm-6">
                  <select v-model="membershipLength" class="form-control" :disabled="existingId">
                    <option value="indefinite">Cancelled</option>
                    <option value="limited">A set number of billing cycles...</option>
                  </select>
                </div>
                <div class="col-sm-6" v-if="!indefinite">
                  <div class="input-group">
                    <input v-model="intervalsToBill" class="form-control" type="number" :disabled="existingId">
                    <span class="input-group-addon">bill(s)</span>
                  </div>
                </div>
                <div class="col-sm-12 m-t-xs" v-if="!indefinite">
                  Memberships will expire {{ intervalCount * intervalsToBill }} {{ interval }}<span v-if="intervalCount * intervalsToBill > 1">(s)</span> after signing up.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12" v-if="existingId">
            <div class="alert alert-warning m-t-sm">
              <p class="">
                <small>
                  Most price details are not editable, because it could impact existing members.
                  If you need to change prices, we recommend creating a brand new price or membership option.
                  If no one is subscribed to this price, please contact support@wrestlingiq.com to get it removed.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-sm-4">
            <div class="flex align-items-start">
              <input type="checkbox" name="sibdiscount" v-model="siblingDiscountEnabled" :aria-checked="siblingDiscountEnabled">
              <div class="m-l-sm">
                <label for="sibdiscount" class="m-b-none">
                  Do you offer a sibling discount?
                </label>
                <p class="m-t-xs" v-if="siblingDiscountEnabled && legacyCouponBehavior">
                  This pricing tier is set to auto-apply coupons to all wrestlers (instead of just the 2nd or 3rd wrestler).
                  If you want that changed, please email support@wrestlingiq.com and we will handle it.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-8" v-if="siblingDiscountEnabled">
            <div>
              <label>Scholarship to automatically apply to 2nd wrestler signing up:</label>
              <select v-model="siblingCoupon" class="form-control" name="sibling_coupon">
                <option value="-1"></option>
                <option v-for="c in availableCoupons" :value="c.id">
                  {{ c.code }}
                  <template v-if="c.amount_off > 0">
                    ({{ formatAsCurrency(c.amount_off) }})
                  </template>
                  <template v-else>
                    ({{ c.percent_off * 100 }}% off)
                  </template>
                </option>
              </select>
            </div>
            <div class="m-t-sm">
              <label>Scholarship to automatically apply to 3rd+ sibling signing up:</label>
              <select v-model="thirdSiblingCoupon" class="form-control" name="third_sibling_coupon">
                <option value="-1"></option>
                <option v-for="c in availableCoupons" :value="c.id">
                  {{ c.code }}
                  <template v-if="c.amount_off > 0">
                    ({{ formatAsCurrency(c.amount_off) }})
                  </template>
                  <template v-else>
                    ({{ c.percent_off * 100 }}% off)
                  </template>
                </option>
              </select>
            </div>
            <p class="text=sm text-gray-600 m-t-xs">
              In most cases, these be the same, unless you do tiered discounts (e.g. 2nd wrestler gets $50 off, 3rd+ wrestler in a family gets $100 off.)
            </p>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-sm-12">
            <div class="form-group flex align-items-start">
              <input type="checkbox" name="active" v-model="active" :aria-checked="active">
              <div class="m-l-sm">
                <label for="active" class="m-b-none">
                  Is this membership option active?
                </label>
                <p class="text-gray-600">
                  If not active, new members cannot sign up for this price. Existing members will remain on this price, even if it is inactive.
                </p>
              </div>
            </div>
          </div>
        </div>
      <div class="button-area">
        <div v-show="error">
          <small>
            <span class="text-danger">{{ errorMessage }}</span>
          </small>
        </div>
        <button class="btn btn-default" @click.prevent="cancel">Cancel</button>
        <button class="btn btn-primary" @click.prevent="validate">
          Done
        </button>
      </div>
    </div>
    <div v-show="!expanded">
      <div class="card-bordered" :class="{ 'opacity-50 bg-gray-50': !active }">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            {{ name }}
            <span class="text-success" v-if="draft"> - Draft</span>
            <span class="text-danger" v-if="!active"> - Inactive</span>
            <br/>
            {{ formatAsCurrency(this.amount * 100) }} {{ prettyInterval }}
            <span v-if="oneTimeFeeAmount > 0"><br>+ {{ formatAsCurrency(oneTimeFeeAmount * 100) }} one-time registration fee</span>
            <span v-if="!indefinite">
              <br>
              Memberships expire after {{ intervalCount * intervalsToBill }} {{ interval }}<span v-if="intervalCount * intervalsToBill > 1">s</span>
            </span>
          </div>
          <div class="col-xs-12 col-sm-6 actions">
            <a @click.prevent="toggleExpanded">Edit</a>
            <a v-if="!existingId" class="text-danger" @click.prevent="removeDraft">Discard</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Money } from 'v-money';
import { formattingMixin } from './mixins/formatting_mix';
import { errorableMix } from './mixins/errorable_mix';

export default {
  name: 'subscription-price-form',
  mixins: [formattingMixin, errorableMix],
  components: {
    Money,
  },
  props: {
    price: {
      type: Object,
      required: true,
    },
    currentBillingPartner: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      draft: false,
      existingId: null,
      name: '',
      amount: 0,
      oneTimeFeeAmount: 0,
      active: true,
      interval: null,
      currency: 'usd',
      intervalCount: 1,
      isNewOption: false,
      membershipLength: 'indefinite',
      intervalsToBill: 1,
      siblingDiscountEnabled: false,
      couponStrategy: 'tiers',
      siblingCoupon: -1,
      thirdSiblingCoupon: -1,
      availableCoupons: [],
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },

      expanded: false,
    };
  },
  watch: {
    siblingCoupon(siblingCouponId) {
      if (siblingCouponId === -1 || siblingCouponId === null) {
        // Also reset the third coupon
        this.thirdSiblingCoupon = -1;
      } else if (this.thirdSiblingCoupon === -1) {
        this.thirdSiblingCoupon = siblingCouponId;
      }
    },
  },
  computed: {
    legacyCouponBehavior() {
        return this.couponStrategy === 'all';
    },
    selectedCouponId() {
      if (!this.siblingDiscountEnabled || this.siblingCoupon === -1) {
        return null;
      }

      return this.siblingCoupon;
    },
    selectedThirdCouponId() {
      if (!this.siblingDiscountEnabled || this.thirdSiblingCoupon === -1) {
        return null;
      }

      return this.thirdSiblingCoupon;
    },
    prettyInterval() {
      const plural = this.intervalCount > 1;
      const interval = plural ? `${this.interval}s` : this.interval;
      return `every ${this.intervalCount} ${interval}`;
    },
    indefinite() {
      return this.membershipLength === 'indefinite';
    },
  },
  mounted() {
    const vm = this;
    const { price } = this;

    vm.existingId = _.get(price, 'id', null);
    vm.name = _.get(price, 'name', '');
    vm.amount = _.get(price, 'amount', 0);
    vm.active = _.get(price, 'active', true);
    vm.currency = _.get(price, 'currency', 'usd');
    // v money expects a float, api returns cents
    if (vm.amount > 0) {
      vm.amount /= 100.00;
    }
    vm.couponStrategy = _.get(price, 'coupon_strategy', 'tiers');
    vm.siblingCoupon = _.get(price, 'sibling_coupon_id', -1);
    if (vm.siblingCoupon > 0) {
      vm.siblingDiscountEnabled = true;
    }
    vm.thirdSiblingCoupon = _.get(price, 'third_sibling_coupon_id', -1);
    vm.interval = _.get(price, 'interval', null);
    vm.intervalCount = _.get(price, 'interval_count', 1);
    let intervals = _.get(price, 'intervals_to_bill', null);
    if (intervals) {
      vm.membershipLength = 'limited';
      vm.intervalsToBill = intervals;
    }

    vm.oneTimeFeeAmount = _.get(price, 'one_time_fee_amount', 0);
    // v money expects a float, api returns cents
    if (vm.oneTimeFeeAmount > 0) {
      vm.oneTimeFeeAmount /= 100.00;
    }

    if (!vm.existingId) {
      vm.isNewOption = true;
      vm.toggleExpanded();
    }

    vm.loadCoupons();
  },
  methods: {
    loadCoupons() {
      const vm = this;

      const url = vm.$apiService.couponsUrl()
      vm.$apiService.loadAllPages(url, 'coupons')
          .then((objects) => {
            vm.availableCoupons = objects;
          })
          .catch((error) => {
            console.error(error);
          });
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$parent.$emit('editing-subscription-option');
    },
    cancel() {
      this.expanded = !this.expanded;
      this.$parent.$emit('editing-subscription-option-cancelled', this.price.newOptionId, this.isNewOption);
    },
    removeDraft() {
      // todo make remove work for existing payment prices
      if (!this.existingId) {
        this.$parent.$emit('discard-subscription-option-draft', this.price.newOptionId);
      }
    },
    validate() {
      const vm = this;

      if (!vm.name || !vm.amount || !vm.interval || !vm.intervalCount) {
        vm.showError('Please ensure name, amount, interval and interval count are not empty');
        return;
      }

      vm.expanded = !vm.expanded;
      // This is used to distinguish whether it's completely new or whether it's just updated
      vm.isNewOption = false;
      vm.draft = true;
      vm.$parent.$emit('subscription-option-updated', vm.getUpdatedPriceObject());
    },
    getUpdatedPriceObject() {
      const vm = this;
      return {
        draft: true,
        id: vm.existingId,
        active: vm.active,
        newOptionId: vm.price.newOptionId,
        name: vm.name,
        amount: _.round(vm.amount * 100),
        interval: vm.interval,
        currency: vm.currency,
        interval_count: vm.intervalCount,
        intervals_to_bill: vm.indefinite ? null : vm.intervalsToBill,
        sibling_coupon_id: vm.selectedCouponId,
        third_sibling_coupon_id: vm.selectedThirdCouponId,
        one_time_fee_amount: _.round(vm.oneTimeFeeAmount * 100),
      };
    },
  },
};
</script>
