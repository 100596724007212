<template>
  <div class="dim">
    <spinner v-if="loading"></spinner>
    <div v-show="!loading">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit, failed } ">
        <div id="payment_form" type="form">
          <div class="row">
            <div class="col-xs-12 col-md-8 col-md-offset-2 col-lg-5 col-lg-offset-2">
              <div v-if="showingReview" class="ibox">
                <div id="review-order" class="ibox-content">
                  <h1 class="p-t-m m-t-none font-heavy">Review order</h1>
                  <h2 class="m-t-md text-18 font-semi">Select
                    <span v-if="recurringSession">Subscription</span>
                    <span v-else>
                      Payment
                    </span>
                    Option
                  </h2>
                  <div id="payment-option-picker" class="grid sm-grid-cols-1 gap-y-3">
                    <div v-for="option in activePaymentOptions" @click="selectOption(option)"
                         class="border-solid rounded-xs p-m flex align-items-start gap-x-m"
                         :class="selectedPaymentOptionId === option.id ? selectedPillStyles : pillStyles">
                      <div>
                        <label>
                          <input type="radio" v-model="selectedPaymentOptionId" v-bind:value="option.id"
                                 :style="selectedPaymentOptionId === option.id ? 'accent-color: #199473;' : ''">
                        </label>
                      </div>
                      <div class="w-full h-full flex flex-col">
                        <div class="m-t-none text-semi text-14">
                          {{ option.name }}
                        </div>
                        <div v-html="optionDescription(option)"></div>
                        <div class="m-t-auto rounded-xxs p-y-m p-x-sm flex align-items-center"
                             :class="selectedPaymentOptionId === option.id ? 'bg-green-50' : 'bg-gray-50'">
                          <div class="flex flex-col">
                            <div class="text-14 font-semi">
                              Due now: {{
                                option.payment_strategy === 'installments' ? dueNowFor(option) : formatAsCurrency(option.price)
                              }}
                            </div>
                            <div class="m-t-xs" v-if="option.payment_strategy === 'installments'"
                                 v-html="futureInstallmentListFor(option)">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-for="plan in activeBillingPlans" @click="selectedBillingPlanId = plan.id"
                         class="border-solid rounded-xs p-m flex align-items-start gap-x-m"
                         :class="selectedBillingPlanId === plan.id ? selectedPillStyles : pillStyles">
                      <div>
                        <label>
                          <input type="radio" v-model="selectedBillingPlanId" v-bind:value="plan.id"
                                 :style="selectedBillingPlanId === plan.id ? 'accent-color: #199473;' : ''">
                        </label>
                      </div>
                      <div class="w-full h-full flex flex-col">
                        <div class="m-t-none text-semi text-14">
                          {{ plan.name }}
                        </div>
                        <div v-html="planDescription(plan)"></div>
                        <div class="m-t-auto rounded-xxs p-y-m p-x-sm flex align-items-center"
                             :class="selectedBillingPlanId === plan.id ? 'bg-green-50' : 'bg-gray-50'">
                          <div class="flex flex-col">
                            <div class="text-14 font-semi">
                              {{ formatAsCurrency(plan.amount) }} {{ planPriceExtra(plan) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-t-lg">
                    <h2 id="your-selection" class="text-18 font-semi">Your Selection</h2>
                    <div v-if="chosenPlanOrPaymentOption">
                      <spinner v-if="loadingPreview"></spinner>
                      <div v-if="!loadingPreview">
                        <div class="panel panel-default m-t-sm">
                          <div class="panel-body">
                            <h3 class="font-semi m-b-xxs">{{ session.name }}</h3>
                            <div
                                class="m-b-md"
                            >
                              {{ chosenPlanOrPaymentOption.name }}
                            </div>
                            <hr/>
                            <!-- for subscriptions -->
                            <template v-if="selectedBillingPlan">
                              <div class="m-b-md font-small" v-html="planDescription(selectedBillingPlan)">
                              </div>
                              <div class="font-small -m-t-sm m-b-md" v-if="selectedWrestlers.length > 1">You are signing
                                up for
                                <span class="font-semi">{{ selectedWrestlers.length }} recurring charges</span>, one for
                                each wrestler.
                              </div>
                              <div v-if="previewRequest && !loadingPreview" v-for="subRequest in subscriptionsRequested">
                                <hr/>
                                <h3 class="m-b-none font-semi m-b-sm text-16">
                                  {{ subRequest.wrestler_profile.first_name }}
                                  {{ subRequest.wrestler_profile.last_name }}
                                </h3>
                                <div v-for="plan in subRequest.plan_requests"
                                     class="flex justify-space-between align-items-baseline">
                                  <p class="m-b-none">{{ plan.billing_plan.name }}</p>
                                  <p class="m-b-none flex-shrink-0">
                                    {{ formatAsCurrency(plan.billing_plan.amount) }}
                                  </p>
                                </div>
                                <div v-if="subRequest.coupon != null"
                                     class="flex justify-space-between align-items-baseline m-t-xs">
                                  <div class="m-b-none flex align-items-center flex-wrap gap-x-xs">
                                    <p class="m-b-none">
                                      Scholarship
                                    </p>
                                    <div
                                        class="text-sm bg-gray-50 text-gray-600 rounded-xxs flex gap-x-xs align-items-center"
                                        style="padding: 2px 4px;">
                                      <div>
                                        {{ subRequest.coupon.code }}
                                      </div>
                                      <i class="fa fa-times text-gray-300 cursor-pointer"
                                         @click="removeCoupon(subRequest)"></i>
                                    </div>
                                  </div>
                                  <p class="m-b-none flex-shrink-0">
                                    - {{ formatAsCurrency(subRequest.discount_total) }}
                                  </p>
                                </div>
                                <div class="m-t-sm flex justify-space-between align-items-baseline text-heavy">
                                  <p class="m-b-none font-semi text-heavy text-14">Recurring total for
                                    {{ subRequest.wrestler_profile.first_name }}</p>
                                  <p class="m-b-none flex-shrink-0 text-14">
                                    {{ formatAsCurrency(subRequest.total_per_interval) }} {{
                                      planPriceExtra(subRequest)
                                    }}
                                  </p>
                                </div>
                                <div v-if="subRequest.one_time_fee_total > 0">
                                  <hr/>
                                  <div class="m-t-m flex justify-space-between align-items-baseline">
                                    <p class="m-b-none">One-time Registration Fee</p>
                                    <p class="m-b-none flex-shrink-0">
                                      {{ formatAsCurrency(subRequest.one_time_fee_total) }}
                                    </p>
                                  </div>
                                  <hr/>
                                  <div class="m-t-sm flex justify-space-between align-items-baseline text-heavy">
                                    <p class="m-b-none font-semi text-heavy text-14">Total due now for
                                      {{ subRequest.wrestler_profile.first_name }}</p>
                                    <p class="m-b-none flex-shrink-0 text-14">
                                      {{ formatAsCurrency(subRequest.total_per_interval + subRequest.one_time_fee_total) }}
                                    </p>
                                  </div>
                                </div>
                                <div class="m-t-m">
                                  <div role="form" v-if="subRequest.coupon == null">
                                    <div class="form-group">
                                      <label class="font-normal text-12">Add scholarship code for
                                        {{ subRequest.wrestler_profile.first_name }}</label>
                                      <div class="flex justify-space-between gap-x-xxs">
                                        <input
                                            v-model="wrestlerCoupons[subRequest.wrestler_profile.id].coupon.code"
                                            type="text"
                                            class="form-control"
                                            placeholder="Enter code (case sensitive)"
                                        />
                                        <ladda-button
                                            @lbClicked="applyCoupon(subRequest)"
                                            el-class="btn-primary flex-shrink-0"
                                            :el-identifier="`apply-coupon-button-${subRequest.wrestler_profile.id}`"
                                            :loading="loadingPreview"
                                        >
                                          Apply
                                        </ladda-button>
                                      </div>
                                      <p class="text-12 text-red-500 leading-5 m-l-xxs m-t-xs"
                                         v-if="wrestlerCoupons[subRequest.wrestler_profile.id].previously_tried_coupon_code !== '' && subRequest.coupon == null">
                                        {{
                                          wrestlerCoupons[subRequest.wrestler_profile.id].previously_tried_coupon_code
                                        }} not applied. Check your capitalization and spelling
                                        or contact your coach.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <!-- for installments -->
                            <template v-if="!recurringSession">
                              <template v-for="(invoice, index) in invoices">
                                <template v-if="invoices.length > 1">
                                  <div class="row m-b-xs">
                                    <div class="col-xs-12">
                                      <p class="m-b-none">
                          <span class="text-semi"
                          >Invoice #{{ index + 1 }}&nbsp;</span
                          >
                                        <small>{{ due(invoice) }}</small>
                                      </p>
                                    </div>
                                  </div>
                                </template>
                                <template v-for="(item, index) in invoice.invoice_items">
                                  <div
                                      :class="
                        index === invoice.invoice_items.length - 1
                          ? 'row m-b-lg'
                          : 'row'
                      "
                                  >
                                    <div class="col-xs-8">
                                      <p class="m-b-none">
                                        {{ item.description }}
                                      </p>
                                    </div>
                                    <div class="col-xs-4 text-right">
                                      <p class="m-b-none">
                                        {{ formatAsCurrency(item.amount) }}
                                      </p>
                                    </div>
                                  </div>
                                </template>
                              </template>
                              <hr/>
                              <div>
                                <div
                                    role="form"
                                    v-if="showCouponForm && !validCouponApplied"
                                >
                                  <div class="form-group">
                                    <label class="font-normal text-12">Add scholarship code</label>
                                    <div class="flex justify-space-between gap-x-xxs">
                                      <input
                                          v-model="couponCode"
                                          type="text"
                                          class="form-control"
                                          placeholder="Enter code (case sensitive)"
                                      />
                                      <ladda-button
                                          @lbClicked="attemptCoupon"
                                          el-class="btn-primary flex-shrink-0"
                                          el-identifier="apply-coupon-button"
                                          :loading="loadingPreview"
                                      >
                                        Apply
                                      </ladda-button>
                                    </div>
                                    <p class="text-12 text-red-500 leading-5 m-l-xxs m-t-xs"
                                       v-if="!loadingPreview && previouslyTriedCouponCode !== '' && !validCouponApplied">
                                      {{ previouslyTriedCouponCode }} not applied. Check your capitalization and
                                      spelling
                                      or contact your coach.
                                    </p>
                                  </div>
                                </div>
                                <p class="m-t-xxs">
                                  <small v-if="validCouponApplied">
                                    <span class="text-success text-semi text-12">{{ couponCode }} applied!</span>
                                    <a class="text-danger" @click.prevent="removeCoupon(null)"
                                    >(remove)</a
                                    >
                                  </small><br v-if="validCouponApplied">
                                </p>
                              </div>
                              <hr/>
                              <div class="row">
                                <div class="col-xs-8">
                            <span :class="installmentsPicked ? '' : 'text-heavy'">
                              Total Due
                            </span>
                                </div>
                                <div class="col-xs-4 text-right">
                            <span :class="installmentsPicked ? '' : 'text-heavy'">
                              {{ total(acceptedInsuranceTotal) }}
                            </span>
                                </div>
                              </div>
                              <div class="row text-heavy" v-if="installmentsPicked">
                                <div class="col-xs-8">Due now</div>
                                <div class="col-xs-4 text-right">
                                  {{ installmentTotalDueNow(acceptedInsuranceTotal) }}
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p>Please make a selection above to continue.</p>
                    </div>
                  </div>
                  <div id="billing-info" class="m-t-lg" v-if="chosenPlanOrPaymentOption">
                    <h2 class="text-18 font-semi">Billing Info</h2>
                    <div v-if="hasGuessBillingInfo" class="bg bg-gray-50 border-gray-200 rounded-sm flex gap-x-sm align-items-center p-sm m-b-sm">
                      <i class="fa fa-magic"></i>
                      <p class="m-y-none">
                        We've prefilled your billing address based on your previous answers. Please review and update as needed.
                        <a @click.prevent="clearBillingInfo" class="text-underline text-gray-600">Tap here to clear billing info fields.</a>
                      </p>
                    </div>
                    <div>
                      <ValidationProvider name="name on card" rules="required" v-slot="{ errors }">
                        <div class="form-group">
                          <label for="name_on_card" class="font-normal">Billing name</label>
                          <input type="text"
                                 autocomplete="cc-name"
                                 name="name on card"
                                 id="name_on_card"
                                 v-model="nameOnCard"
                                 data-cy="name_on_card"
                                 :class="inputClasses(errors[0])">
                          <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                            {{ errors[0] }}
                          </p>
                        </div>
                      </ValidationProvider>
                      <ValidationProvider name="address line 1" rules="required" v-slot="{ errors }">
                        <div class="form-group">
                          <label for="location-input" class="font-normal">
                            Address line 1
                          </label>
                          <input autocomplete="address-line1" v-model="addressLine1" id="location-input" type="text"
                                 data-cy="address_1"
                                 :class="inputClasses(errors[0])">
                          <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                            {{ errors[0] }}
                          </p>
                        </div>
                      </ValidationProvider>
                      <div class="form-group">
                        <label for="address_2" class="font-normal">
                          Address line 2 (optional)
                        </label>
                        <input autocomplete="address-line2" v-model="addressLine2" id="address_2" type="text"
                               class="form-control">
                      </div>
                      <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                        <div class="form-group">
                          <label for="locality-input" class="font-normal">
                            City
                          </label>
                          <input autocomplete="address-level2" v-model="city" id="locality-input" type="text" ref="city"
                                 :class="inputClasses(errors[0])" data-cy="city">
                          <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                            {{ errors[0] }}
                          </p>
                        </div>
                      </ValidationProvider>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-xs-12 col-sm-6 m-b-only-xs-m">
                            <ValidationProvider :name="isUSA ? 'state' : 'region'" rules="required" v-slot="{ errors }">
                              <label :for="isUSA ? 'administrative_area_level_1-input' : 'region-input'" class="font-normal">
                                {{ isUSA ? 'State' : 'Region/Province' }}
                              </label>
                              <the-mask
                                  v-if="isUSA"
                                  v-model="state"
                                  mask="AA"
                                  type="text"
                                  :class="inputClasses(errors[0])"
                                  ref="state"
                                  autocomplete="address-level1"
                                  id="administrative_area_level_1-input"
                                  data-cy="state"
                              ></the-mask>
                              <input
                                  v-else
                                  v-model="state"
                                  type="text"
                                  :class="inputClasses(errors[0])"
                                  ref="state"
                                  autocomplete="address-level1"
                                  id="region-input"
                                  data-cy="state"
                              >
                              <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                                {{ errors[0] }}
                              </p>
                            </ValidationProvider>
                          </div>
                          <div class="col-xs-12 col-sm-6 m-b-only-xs-m">
                            <ValidationProvider :name="isUSA ? 'zip' : 'postal code'" rules="required" v-slot="{ errors }">
                              <label for="postal_code-input" class="font-normal">
                                {{ isUSA ? 'Zip' : 'Postal Code' }}
                              </label>
                              <the-mask
                                  v-if="isUSA"
                                  v-model="zip"
                                  mask="#####"
                                  type="text"
                                  :class="inputClasses(errors[0])"
                                  ref="zip"
                                  autocomplete="postal-code"
                                  id="postal_code-input"
                                  data-cy="zip"
                              ></the-mask>
                              <input
                                  v-else
                                  v-model="zip"
                                  type="text"
                                  :class="inputClasses(errors[0])"
                                  ref="zip"
                                  autocomplete="postal-code"
                                  id="postal_code-input"
                                  data-cy="zip"
                              >
                              <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                                {{ errors[0] }}
                              </p>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                        <div class="form-group">
                          <label for="country-input" class="font-normal">
                            Country
                          </label>
                          <select v-model="country" class="form-control" id="country-input">
                            <option v-for="country in countries" :key="country.short_name" :value="country.short_name">{{ country.long_name }}</option>
                          </select>
                          <p class="m-t-xxs font-small text-red-600" v-if="errors[0]">
                            {{ errors[0] }}
                          </p>
                        </div>
                      </ValidationProvider>
                      <div class="form-group">
                        <label class="checkbox-inline">
                          <input type="checkbox" id="save_billing_info" data-cy="save_billing_info" v-model="reusePaymentMethodInFuture"> Save
                          billing & card info for fast future checkout
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="upsells" v-else-if="showingUpsells">
                <div v-if="showDonation && !recurringSession" class="ibox">
                  <div class="ibox-content relative">
                    <div
                        class="absolute w-full bg-green-500 rounded-t-xxs"
                        style="top: 0; left: 0;"
                        :style="{ backgroundColor: brandColor }"
                    >
                      <div class="upper-label text-14 text-heavy p-y-sm p-l-md" :style="{ color: brandTextColor }">We
                        need your support
                      </div>
                    </div>
                    <div class="p-t-xl">
                      <h1 class="m-t-md">Donate to {{ teamName }}</h1>
                      <template v-if="isWrestleLikeAGirl">
                        <p class="m-t-m">
                          Wrestle Like A Girl's mission is to empower girls and women
                          using the sport of wrestling to become leaders in life.
                        </p>
                        <p>
                          Your donations help to continue this mission and grow the
                          sport for all through state sanctioning efforts, empowerment
                          camps and clinics, collegiate growth and development,
                          amplifying and uniting the voice of the wrestling community.
                        </p>
                        <p class="m-b-lg">
                          Without your continuous contribution and support, none of
                          this is possible. We greatly appreciate you. Thank you!
                        </p>
                      </template>
                      <template v-else>
                        <p class="m-t-m">
                          It is our goal to give every child in our community the
                          chance to participate.
                        </p>
                        <p>
                          Your donations help to pay for facilities, insurance,
                          equipment, travel, and give the chance for underpriviledged
                          athletes to compete. If you can spare a few extra dollars,
                          consider adding a donation to your registration.
                        </p>
                        <p class="m-b-lg" style="font-style: italic;">
                          The following examples are for illustrative purposes only. In accordance with IRS guidelines, {{ teamName }} maintains discretion and sole control over
                          the use of all donated funds.
                        </p>
                      </template>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div>
                          <div v-for="tier in donationTiers"
                               @click="donationAmount = tier.amount"
                               class="card-bordered donation-card m-y-m flex gap-x-sm"
                               :class="donationAmount === tier.amount ? selectedPillStyles : pillStyles"
                               :style="donationAmount === tier.amount ? 'accent-color: #199473;' : ''"
                          >
                            <div>
                              <input type="radio" :value="tier.amount" v-model="donationAmount">
                            </div>
                            <div class="flex flex-col">
                              <div class="text-semi text-16">
                                {{ formatAsCurrency(tier.amount) }}
                              </div>
                              <p class="text-gray-600 m-b-xxs">
                                {{ tier.example }}
                              </p>
                            </div>
                          </div>
                          <div
                              @click="donationAmount = 0"
                              class="card-bordered donation-card donation-decline-card m-y-m"
                              :class="donationAmount === 0 ? 'border-1 border-gray-600' : pillStyles"
                              :style="donationAmount === 0 ? 'accent-color: #48647F;' : ''"
                          >
                            <input type="radio" value="0" v-model="donationAmount"><span class="text-16 m-l-sm">No thanks, I do not want to donate</span>
                          </div>
                        </div>
                        <p class="m-t-xxs text-red-600" v-if="errors[0]">
                          Please select a donation amount or decline to continue.
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div v-if="hasCustomUpsellProduct" class="ibox">
                    <div class="ibox-content relative">
                      <div
                          class="absolute w-full bg-gray-200 rounded-t-xxs"
                          style="top: 0; left: 0;"
                          :style="{ backgroundColor: brandColor }"
                      >
                        <div class="upper-label text-14 text-heavy p-y-sm p-l-md text-gray-800" :style="{ color: brandTextColor }">Add to your order</div>
                      </div>
                      <div class="p-t-xl">
                        <h1 class="m-t-md">{{ customUpsellProduct.name }}</h1>
                        <div class="mx-auto mt-12">
                          <div class="grid grid-cols-1 sm-grid-cols-1 gap-x-sm m-t-sm">
                            <!-- Image gallery -->
                            <div class="grid-cols-1">
                              <h2 class="sr-only">Images</h2>
                              <div class="aspect-h-1 aspect-w-1 w-full">
                                <img :src="upsellProduct.activePhoto.url" alt="Product photo"
                                     class="h-full w-full object-cover object-center rounded-sm">
                              </div>
                              <!-- Image selector -->
                              <div class="mx-auto m-t-sm w-full">
                                <div class="grid sm-grid-cols-2 grid-cols-4 gap-6" aria-orientation="horizontal" role="tablist">
                                  <div v-for="photo in upsellProduct.photos" id="tabs-1-tab-1" class="relative flex cursor-pointer align-items-center justify-center rounded-sm bg-white" style="height: 12rem;" aria-controls="tabs-1-panel-1" role="tab" type="button"
                                       @click.prevent="upsellProduct.activePhoto = photo"
                                  >
                                  <span class="absolute inset-0 overflow-hidden rounded-sm">
                                    <img :src="photo.url" alt="Product photo" class="h-full w-full object-cover object-center">
                                  </span>
                                    <!-- Selected: "ring-green-500", Not Selected: "ring-transparent" -->
                                    <span class="pointer-events-none absolute inset-0 rounded-sm border border-solid"
                                          :class="[photo === upsellProduct.activePhoto ? 'border-blue-200' : 'border-gray-200']"
                                          aria-hidden="true"></span>
                                  </div>
                                  <!-- More images... -->
                                </div>
                              </div>
                              <div class="m-t-m" v-html="customUpsellProduct.description"></div>
                            </div>
                            <div class="grid-cols-1 m-t-m">
                              <div class="mt-8">
                                <form>
                                  <!-- Colors picker -->
                                  <div class="" v-if="upsellColors.length > 0">
                                    <h3 class="text-12">Color</h3>
                                    <fieldset class="mt-2">
                                      <legend class="sr-only">Choose a color</legend>
                                      <!--
                                        In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
                                        Active: "ring-2 ring-green-500 ring-offset-2"
                                        Checked: "border-transparent bg-green-600 text-white hover:bg-green-700",
                                        Not Checked: "border-gray-200 bg-white text-gray-900 hover:bg-gray-50"
                                      -->
                                      <div class="flex flex-wrap gap-x-sm gap-y-sm">
                                        <label v-for="(color,index) in upsellColors"
                                               :key="index"
                                               class="upsell-color-button flex align-items-center justify-center rounded-sm border border-solid p-sm text-sm uppercase cursor-pointer"
                                               :class="[color === upsellProduct.activeColor ? 'border-transparent' : 'border-gray-200']"
                                               :style="[color === upsellProduct.activeColor ? selectedBtnStyles : btnStyles]"
                                               @click="upsellProduct.activeColor = color"
                                        >
                                          <input type="radio" name="color-choice" :value="color" class="sr-only"
                                                 :aria-labelledby="`color-choice-${index}-label`"
                                                 v-on:click="upsellProduct.activeColor = color"
                                                 :checked="color === upsellProduct.activeColor">
                                          <span class="font-normal" :id="`color-choice-${index}-label`">{{ color }}</span>
                                        </label>
                                      </div>
                                    </fieldset>
                                  </div>
                                  <!-- Size picker -->
                                  <div class="mt-8" v-if="upsellSizes.length > 0">
                                    <div class="flex items-center justify-between">
                                      <h3 class="text-12">Size</h3>
                                    </div>
                                    <fieldset class="mt-2">
                                      <legend class="sr-only">Choose a size</legend>
                                      <!--
                                        In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
                                        Active: "ring-2 ring-green-500 ring-offset-2"
                                        Checked: "border-transparent bg-green-600 text-white hover:bg-green-700",
                                        Not Checked: "border-gray-200 bg-white text-gray-900 hover:bg-gray-50"
                                      -->
                                      <div class="grid grid-cols-4 gap-3">
                                        <label v-for="(size,index) in upsellSizes"
                                               :key="index"
                                               class="upsell-size-button flex items-center justify-center rounded-sm border border-solid p-sm text-sm font-semi uppercase cursor-pointer"
                                               :class="[size === upsellProduct.activeSize ? 'border-transparent' : 'border-gray-200']"
                                               :style="[size === upsellProduct.activeSize ? selectedBtnStyles : btnStyles]"
                                               @click="upsellProduct.activeSize = size"
                                        >
                                          <input type="radio" name="size-choice" :value="size" class="sr-only"
                                                 :aria-labelledby="`size-choice-${index}-label`"
                                                 v-on:click="upsellProduct.activeSize = size"
                                                 :checked="size === upsellProduct.activeSize">
                                          <span class="font-normal" :id="`size-choice-${index}-label`">{{ size }}</span>
                                        </label>
                                      </div>
                                    </fieldset>
                                  </div>
                                  <p class="font-small m-t-m text-red-600" v-if="upsellPrice === ''">Please select color/size to see price and add to order.</p>
                                  <ValidationProvider rules="required" v-slot="{ errors }">
                                    <div class="card-bordered upsell-card donation-card m-y-m flex gap-x-sm"
                                         :class="upsellProduct.addedToCart ? selectedPillStyles : pillStyles"
                                         @click.prevent="addUpsellToCart">
                                      <div>
                                        <input type="radio" :value="true" v-model="upsellProduct.addedToCart">
                                      </div>
                                      <div class="flex flex-col">
                                        <div class="text-semi text-16">
                                          Add to order
                                          <span v-if="upsellPrice !== ''">&nbsp;-&nbsp;{{ upsellPrice }}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card-bordered donation-card upsell-decline-card m-y-m flex gap-x-sm"
                                         :class="upsellProduct.addedToCart === false ? 'border-1 border-gray-600' : pillStyles"
                                         @click.prevent="upsellProduct.addedToCart = false">
                                      <div>
                                        <input type="radio" :value="false" v-model="upsellProduct.addedToCart">
                                      </div>
                                      <div class="flex flex-col">
                                        <div class="text-16">
                                          No, thank you
                                        </div>
                                      </div>
                                    </div>
                                    <p class="m-t-xxs text-red-600" v-if="errors[0]">
                                      Please accept or decline this add-on to continue.
                                    </p>
                                  </ValidationProvider>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div v-if="showGapMedicalInsurance || showSeasonInterruptionInsurance" class="ibox">
                  <div class="ibox-content relative">
                    <div
                        class="absolute w-full bg-gray-200 rounded-t-xxs"
                        style="top: 0; left: 0;"
                    >
                      <div class="upper-label text-14 text-heavy p-y-sm p-l-md text-gray-800">Protect your camp
                        experience
                      </div>
                    </div>
                    <p class="p-t-xxl">
                      Consider adding insurance to protect your investment in case of injury or cancellation.
                    </p>
                    <div class="m-t-md" v-if="showSeasonInterruptionInsurance">
                      <ValidationProvider v-slot="{ validate, errors }" ref="seasonInterruptionProvider"
                                          rules="required|insuranceOffer">
                        <season-interruption
                            :client-id="vertClientId"
                            :customer-email-address="email"
                            :customer-first-name="customerInfo.first_name"
                            :customer-last-name="customerInfo.last_name"
                            :customer-street-address="addressLine1"
                            :customer-city="city"
                            :customer-state="state"
                            :customer-postal-code="zip"
                            :insurable-amount="insurableTotal"
                            :event-start-date="session.start_at"
                            :event-end-date="session.end_at"
                            :participant-first-name="coveredWrestler.first_name"
                            :participant-last-name="coveredWrestler.last_name"
                            @offer-state-change="insuranceOptionPicked"
                            debug
                        ></season-interruption>
                        <p class="m-t-xxs text-red-600" v-if="errors[0]">
                          Please accept or decline season interruption insurance.
                        </p>
                      </ValidationProvider>
                    </div>
                    <div class="m-t-md" v-if="showGapMedicalInsurance">
                      <ValidationProvider v-slot="{ validate, errors }" ref="gapMedicalProvider"
                                          rules="required|insuranceOffer">
                        <gap-medical
                            :client-id="vertClientId"
                            :customer-email-address="email"
                            :customer-first-name="customerInfo.first_name"
                            :customer-last-name="customerInfo.last_name"
                            :customer-street-address="addressLine1"
                            :customer-city="city"
                            :customer-state="state"
                            :customer-postal-code="zip"
                            :coverage-start-date="session.start_at"
                            :coverage-end-date="session.end_at"
                            coverage-type="WRESTLING"
                            :participant-first-name="coveredWrestler.first_name"
                            :participant-last-name="coveredWrestler.last_name"
                            :participant-state="state"
                            :participant-birth-date="formattedDateOfBirth"
                            @offer-state-change="insuranceOptionPicked"
                            debug
                        ></gap-medical>
                        <p class="m-t-xxs  text-red-600" v-if="errors[0]">
                          Please accept or decline gap medical insurance.
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div id="payment-details" class="ibox" v-else-if="showingPay">
                <div class="ibox-content">
                  <h1 class="p-t-m m-t-none font-heavy">Payment</h1>
                  <div class="panel-group" id="accordion">
                    <div v-if="free">
                      <div class="panel panel-success">
                        <div class="panel-heading">
                          <h5 class="panel-title">Free</h5>
                        </div>
                        <div class="panel-body">
                          <p>
                            Tap the register button to complete your free registration.
                          </p>
                          <p v-if="paid">
                            Thank you for registering! Check your email for your
                            receipt and additional information.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div :class="cashSelected ? 'panel panel-success' : 'panel panel-white'"
                           v-if="allowsCash() && acceptedInsuranceOffers.length === 0">
                        <div class="panel-heading" v-on:click="cashTapped()">
                          <h5 class="panel-title">
                            <label>
                              <input
                                  type="radio"
                                  value="cash"
                                  id="cash_payment"
                                  v-model="paymentMethod"
                              />&nbsp; Cash or check
                            </label>
                          </h5>
                        </div>
                        <div v-show="cashSelected">
                          <div class="panel-body">
                            <p>
                              Payment will be due at the next practice or event for
                              this session.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div :class="creditSelected && allowsCash() ? 'panel panel-success' : ''">
                        <div class="panel-heading" v-on:click="creditTapped()" v-if="allowsCash()">
                          <h5 class="panel-title">
                            <label>
                              <input
                                  type="radio"
                                  value="card"
                                  id="credit_payment"
                                  v-model="paymentMethod"
                              />&nbsp; Credit or debit
                            </label>
                          </h5>
                        </div>
                        <div v-show="creditSelected">
                          <div id="credit-body" :class="creditSelected && allowsCash() ? 'panel-body' : ''">
                            <div v-if="hasSavedCard && !updatingSavedCard">
                              <div class="flex align-items-top justify-space-between m-t-sm">
                                <h3 class="font-semi m-t-none m-b-none" style="margin-top: 0; margin-bottom: 0;">Saved Card</h3>
                                <a @click.prevent="updatingSavedCard = true" class="flex align-items-baseline gap-x-xs">
                                  <i class="fa fa-pencil"/>
                                  <span class="">Edit card</span>
                                </a>
                              </div>
                              <div class="flex flex-wrap gap-x-sm align-items-baseline m-t-xs">
                                <div>
                                  <input type="radio" value="saved-card" checked>
                                </div>
                                <div class="flex align-items-center gap-x-sm">
                                  <div v-html="cardLogo"></div>
                                  <div class="font-heavy">* * * * {{
                                      billingProfile.default_payment_method_last4
                                    }}
                                  </div>
                                  <div class="">
                                    Expires {{billingProfile.default_payment_method_exp_month }} / {{ billingProfile.default_payment_method_exp_year }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Display a payment form -->
                            <form v-else role="form" id="payment-form">
                              <a v-if="hasSavedCard && updatingSavedCard" @click.prevent="updatingSavedCard = false" class="flex align-items-baseline gap-x-xs m-t-sm m-b-lg">
                                <i class="fa fa-undo"/>
                                <span class="">Cancel - use saved card</span>
                              </a>
                              <div id="payment-element" v-if="useStripe">
                                <!--Stripe.js injects the Payment Element-->
                              </div>
                              <div v-else-if="useJustifi">
                                <div class="flex mt-2 flex-col space-y-4">
                                  <spinner v-if="justifiLoading"></spinner>
                                  <justifi-card-form @cardFormReady="justifiLoading = false"
                                                     validation-mode="onBlur"></justifi-card-form>
                                </div>
                              </div>
                            </form>
                            <p class="m-t-m">
                              <svg class="h-4 w-4 text-gray-500 inline" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd"
                                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                      clip-rule="evenodd"/>
                              </svg>
                              Secure payment processing provided by <span v-if="useStripe">Stripe</span><span v-else>WrestlingIQ</span>
                            </p>
                            <p v-if="paid">
                              Thank you for registering! Check your email for your
                              receipt and additional information.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-8 col-md-offset-2 col-lg-3 col-lg-offset-0" v-if="chosenPlanOrPaymentOption">
              <div id="summary-card" class="affix" data-offset-top="0">
                <div class="ibox p-x-m">
                  <div class="ibox-content">
                    <div>
                      <div>
                        <div class="flex flex-col">
                          <h1 class="m-t-none m-b-none-important p-t-md">Summary</h1>
                          <h2 class="m-b-m">
                            <span v-if="recurringSession">Subscription</span>
                            <span v-else>
                              Registration
                            </span>
                            for {{ session.name }}
                          </h2>
                          <!-- for one time registrations -->
                          <spinner v-if="loadingPreview"></spinner>
                          <div class="m-b-xs" v-for="(invoice, index) in invoices" v-if="!loadingPreview">
                            <template v-if="invoices.length > 1">
                              <div
                                  class="flex flex-wrap justify-space-between gap-x-sm m-b-xxs"
                              >
                                <p class="m-b-xxs font-semi">
                                  {{ due(invoice) }}
                                </p>
                                <p class="m-b-none text-right">
                                  {{ formatAsCurrency(invoice.amount_due) }}
                                </p>
                              </div>
                            </template>
                            <template v-else>
                              <div
                                  class="flex flex-wrap justify-space-between gap-x-sm m-b-xxs"
                                  v-for="(item, index) in invoice.invoice_items"
                              >
                                <p class="m-b-none">
                                  {{ shortDescription(item) }}
                                </p>
                                <p class="m-b-none text-right">
                                  {{ formatAsCurrency(item.amount) }}
                                </p>
                              </div>
                            </template>
                          </div>
                          <!-- for subscriptions -->
                          <template v-if="selectedBillingPlan && previewRequest && !loadingPreview">
                            <div class="m-b-m" v-html="planDescription(selectedBillingPlan)">
                            </div>
                            <div class="-m-t-sm m-b-xs" v-if="selectedWrestlers.length > 1">You are signing up for
                              <span class="font-semi">{{ selectedWrestlers.length }} recurring charges</span>, one for
                              each wrestler.
                            </div>
                            <div>
                              <hr/>
                            </div>
                            <div :class="{ 'm-t-md': index > 0 }"
                                 v-for="(subRequest, index) in subscriptionsRequested">
                              <p class="m-b-none font-semi m-b-sm text-16">
                                {{ subRequest.wrestler_profile.first_name }}
                                {{ subRequest.wrestler_profile.last_name }}
                              </p>
                              <div v-for="plan in subRequest.plan_requests"
                                   class="flex justify-space-between align-items-baseline">
                                <p class="m-b-none">{{ plan.billing_plan.name }}</p>
                                <p class="m-b-none flex-shrink-0">
                                  {{ formatAsCurrency(plan.billing_plan.amount) }}
                                </p>
                              </div>
                              <div v-if="subRequest.coupon != null"
                                   class="flex justify-space-between align-items-baseline m-t-xs">
                                <div class="m-b-none flex align-items-center flex-wrap gap-x-xs">
                                  <p class="m-b-none">
                                    Scholarship
                                  </p>
                                  <div
                                      class="text-sm bg-gray-50 text-gray-600 rounded-xxs flex gap-x-xs align-items-center"
                                      style="padding: 2px 4px;">
                                    <div>
                                      {{ subRequest.coupon.code }}
                                    </div>
                                    <i class="fa fa-times text-gray-300 cursor-pointer"
                                       @click="removeCoupon(subRequest)"></i>
                                  </div>
                                </div>
                                <p class="m-b-none flex-shrink-0">
                                  - {{ formatAsCurrency(subRequest.discount_total) }}
                                </p>
                              </div>
                              <div class="m-t-sm flex justify-space-between align-items-baseline text-heavy">
                                <p class="m-b-none font-semi text-heavy text-14">Recurring total for
                                  {{ subRequest.wrestler_profile.first_name }}</p>
                                <p class="m-b-none flex-shrink-0 text-14">
                                  {{ formatAsCurrency(subRequest.total_per_interval) }} {{ planPriceExtra(subRequest) }}
                                </p>
                              </div>
                              <div v-if="subRequest.one_time_fee_total > 0">
                                <hr/>
                                <div class="m-t-m flex justify-space-between align-items-baseline">
                                  <p class="m-b-none">One-time Registration Fee</p>
                                  <p class="m-b-none flex-shrink-0">
                                    {{ formatAsCurrency(subRequest.one_time_fee_total) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                        <hr/>
                        <div v-if="acceptedInsuranceOffers.length > 0">
                          <h2 class="-m-t-m m-b-none-important p-t-md">Insurance Coverage</h2>
                          <p class="m-t-sm m-b-md text-12">
                            Insurance will show up as a separate charge on your card. You will receive a separate email
                            with policy documents.
                          </p>
                          <div
                              class="flex flex-wrap justify-space-between gap-x-sm m-b-xxs"
                              v-for="offer in acceptedInsuranceOffers">
                            <p class="m-b-none-important">
                              {{ offer.product.friendly_name }}
                            </p>
                            <p class="m-b-none text-right">
                              {{ formatAsCurrency(offer.total) }}
                            </p>
                          </div>
                          <hr/>
                        </div>
                        <div class="justify-space-between flex m-b-m">
                          <h2 class="text-18 m-t-none m-b-none-important font-heavy">
                            Due now
                          </h2>
                          <p class="text-18 m-t-none font-heavy m-b-none text-right">
                            <spinner v-if="loadingPreview"></spinner>
                            <template v-if="recurringSession && !loadingPreview">
                              {{ totalSubscriptionPrice }}
                            </template>
                            <template v-else-if="!loadingPreview">
                              {{
                                selectedPaymentOption.payment_strategy === 'installments' ? installmentTotalDueNow(acceptedInsuranceTotal) : total(acceptedInsuranceTotal)
                              }}
                            </template>
                          </p>
                        </div>
                        <ladda-button
                            cypress-class="register-next-btn"
                            @lbClicked="handleSubmit(nextStep)"
                            el-class="btn-primary block w-full"
                            el-identifier="register-next-btn"
                            :disabled="savingBillingInfo || creatingCharge || creatingToken || creatingSubscription || registrationIsClosed"
                            :loading="registering || savingBillingInfo || creatingCharge || creatingToken || creatingSubscription || loadingPreview"
                        >
                          {{ summaryCTA }}
                        </ladda-button>
                        <p v-if="failed" data-cy="validation-error-text" class="text-red-500 text-12 text-center py-2 m-t-xs">{{
                            validateFailedMsg
                          }}</p>
                        <p v-if="error" data-cy="error-text" class="text-red-500 text-12 text-center py-2 m-t-xs">{{
                            errorMessage
                          }}</p>
                        <div class="flex justify-space-between align-items-center py-2 m-t-xs">
                          <a @click="previousStep" v-if="currentStep !== 'review'">
                            <div class="flex gap-x-xxs align-items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                                   class="w-4 h-4">
                                <path fill-rule="evenodd"
                                      d="M14 8a.75.75 0 0 1-.75.75H4.56l1.22 1.22a.75.75 0 1 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1 0-1.06l2.5-2.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8Z"
                                      clip-rule="evenodd"/>
                              </svg>
                              <p class="text-600 text-12 m-t-none m-b-none">Back</p>
                            </div>
                          </a>
                          <p class="text-gray-600 text-12 m-t-none m-b-none"
                             :class="currentStep === 'review' ? 'text-center w-full' : ''">Step {{ currentStepCount }}
                            of {{ totalSteps }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Spinner from './spinner.vue';
import LaddaButton from '../../shared/ladda_button.vue';
import { formattingMixin } from './mixins/formatting_mix';
import { funnelMixin } from './mixins/funnel_mix';
import { paymentOptionsMix } from './mixins/payment_options_mix';
import { billingPlanMix } from './mixins/billing_plan_mix';
import { errorableMix } from './mixins/errorable_mix';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { billingPartnerMix } from '../../shared/billing_partner_mix';
import { TheMask } from 'vue-the-mask';
import WrestlerIcon from '../../onlinestore/wrestler-icon.vue';
import swal from 'sweetalert2';

export default {
  name: 'membership-form',
  mixins: [formattingMixin, funnelMixin, paymentOptionsMix, billingPlanMix, errorableMix, billingPartnerMix],
  components: {
    WrestlerIcon,
    LaddaButton,
    Spinner,
    ValidationProvider,
    ValidationObserver,
    TheMask,
  },
  props: {
    guardianId: {
      type: Number,
      default: null,
    },
    guardianType: {
      type: String,
      default: null,
    },
    // Information about the billing customer
    customerInfo: {
      type: Object,
      required: true,
    },
    funnel: {
      type: Boolean,
      default: true,
    },
    teamId: {
      type: Number,
      required: true
    },
    teamName: {
      type: String,
      required: true
    },
    showDonation: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: String,
      default: '/calendar'
    },
    selectedWrestlers: {
      type: Array,
      required: true,
    },
    session: {
      type: Object,
      required: true,
    },
    allowSeasonInterruptionInsurance: {
      type: Boolean,
      default: false,
    },
    allowGapMedicalInsurance: {
      type: Boolean,
      default: false,
    },
    vertClientId: {
      type: String,
      default: null,
    },
    brandColor: {
      type: String,
      default: '#27AA83',
    },
    brandTextColor: {
      type: String,
      default: '#FFF'
    },
    customUpsellProduct: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      selectedBillingPlanId: null, // recurring subscriptions
      wrestlerCoupons: {
        // key is wrestler id, payload is coupon code string
        // used for recurring subscription auto application
      },
      previewRequest: null,
      currentStep: 'review', // review, upsells, pay
      loadingPreview: false,
      registering: false,
      savingBillingInfo: false,
      creatingCharge: false,
      creatingToken: false,
      creatingSubscription: false,
      card: null,
      updatingSavedCard: false,
      paymentMethod: 'card',
      previewUrl: this.$apiService.paymentPlanPreviewUrl(),
      insurance: {
        gapMedical: {
          state: 'NOT_RENDERED', // NOTRENDERED ACCEPTED, DECLINED, UNKNOWN
          quote: null,
          forceHide: false,
        },
        seasonInterruption: {
          state: 'NOT_RENDERED',
          quote: null,
          forceHide: false,
        },
      },
      upsellProduct: {
        addedToCart: null,
        selectedBillingPlan: null,
        activeSize: null,
        activeColor: null,
        photos: [],
        activePhoto: null,
      },
      // Button styles for upsells
      selectedBtnStyles: {
        color: this.brandTextColor,
        backgroundColor: this.brandColor,
      },
      btnStyles: {
        color: '#324D67', // text-gray-700
        backgroundColor: 'white',
      },
      selectedPillStyles: 'border-2 border-green-500',
      pillStyles: 'border border-gray-200',
      checkboxStyles: {
        accentColor: this.brandColor, // accentColor is broken due to tailwind forms plugin. For now we hard code color if tick box is not white.
        color: this.brandTextColor === '#FFF' ? this.brandColor : '#2563eb',
      },

      paid: false,
      donationTiers: [
        {
          amount: 2500,
          example: 'Buys a workout shirt for one child in need.'
        },
        {
          amount: 5000,
          example: 'Provides a pair of headgear to a wrestler.'
        },
        {
          amount: 10000,
          example: 'Covers the cost of two pairs of shoes.'
        },
        {
          amount: 20000,
          example: 'Supports a wrestler for the entire season.'
        },
      ]
    };
  },
  computed: {
    acceptedInsuranceOffers() {
      let offers = [];

      if (this.insurance.gapMedical.state === 'ACCEPTED') {
        offers.push(this.insurance.gapMedical.quote);
      }

      if (this.insurance.seasonInterruption.state === 'ACCEPTED') {
        offers.push(this.insurance.seasonInterruption.quote);
      }

      return offers;
    },
    acceptedInsuranceTotal() {
      let total = 0;

      this.acceptedInsuranceOffers.forEach((offer) => {
        total += offer.total;
      });

      return total;
    },
    summaryCTA() {
      if (this.registrationIsClosed) {
        return 'Registration is closed';
      }

      switch (this.currentStep) {
        case 'review':
          return 'Continue';
        case 'upsells':
          return 'Proceed to Checkout';
        case 'pay':
          if (this.free || this.cashSelected) {
            return 'Register';
          }

          return 'Register & Pay';
      }
    },
    totalSteps() {
      return this.hasUpsells ? 3 : 2;
    },
    currentStepCount() {
      switch (this.currentStep) {
        case 'review':
          return 1;
        case 'upsells':
          return 2;
        case 'pay':
          return this.totalSteps === 3 ? 3 : 2;
      }
    },
    // V1 hack, Vertical Insurance only has one participant
    showGapMedicalInsurance() {
      return this.useJustifi && this.allowGapMedicalInsurance && this.selectedWrestlers.length === 1 && this.vertClientId && !this.free && !this.insurance.gapMedical.forceHide;
    },
    showSeasonInterruptionInsurance() {
      return this.useJustifi && this.allowSeasonInterruptionInsurance && this.selectedWrestlers.length === 1 && this.vertClientId && !this.free && !this.insurance.seasonInterruption.forceHide;
    },
    coveredWrestler() {
      return this.selectedWrestlers[0];
    },
    formattedDateOfBirth() {
      return moment(this.coveredWrestler.dob)
          .format('YYYY-MM-DD');
    },
    insurableTotal() {
      if (!this.pendingPlan) {
        return 0;
      }

      let total = 0;

      this.pendingPlan.manual_invoices.forEach((mi) => {
        total += mi.amount_due;
      });

      return total;
    },
    hasCustomUpsellProduct() {
      return Object.keys(this.customUpsellProduct).length !== 0;
    },
    hasUpsells() {
      return (this.showDonation && !this.recurringSession) || this.showGapMedicalInsurance || this.showSeasonInterruptionInsurance || this.hasCustomUpsellProduct;
    },
    isWrestleLikeAGirl() {
      return this.teamName.toLowerCase() === 'wrestle like a girl';
    },
    showingReview() {
      return this.currentStep === 'review';
    },
    showingUpsells() {
      return this.currentStep === 'upsells';
    },
    showingPay() {
      return this.currentStep === 'pay';
    },
    recurringSession() {
      return this.session.product !== null;
    },
    activeBillingPlans() {
      if (this.recurringSession) {
        return _.filter(this.session.product.billing_plans, {
          active: true,
          is_wiq_fee: false,
          is_tax_item: false,
          is_one_time_fee: false,
        });
      }

      return [];
    },
    chosenPlanOrPaymentOption() {
      return this.selectedPaymentOption || this.selectedBillingPlan;
    },
    selectedBillingPlan() {
      if (this.selectedBillingPlanId) {
        return _.find(this.activeBillingPlans, ['id', this.selectedBillingPlanId]);
      }

      return null;
    },
    totalSubscriptionPrice() {
      if (!this.previewRequest) {
        return '––';
      }
      const subTotal = _.sum(_.map(this.subscriptionsRequested, 'total_per_interval')) + _.sum(_.map(this.subscriptionsRequested, 'one_time_fee_total'));
      return (subTotal / 100.00).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    subscriptionsRequested() {
      if (!this.previewRequest) {
        return [];
      }

      return this.previewRequest.subscriptions_requested;
    },
    validateFailedMsg() {
      if (this.currentStep === 'review') {
        return 'Please fill out your billing info to continue.';
      } else if (this.currentStep === 'upsells') {
        return 'Please accept or decline all add-ons to continue.';
      } else if (this.currentStep === 'pay') {
        return 'Please fix issues on the form to continue.';
      }
    },
    upsellColors() {
      return _.uniq(_.compact(_.flatMap(this.customUpsellProduct.billing_plans, 'color')));
    },
    upsellSizes() {
      const sizeMap = {
        'YXXXS': 1,
        'Y3XS': 2,
        'Y2XS': 3,
        'YXXS': 4,
        'YXS': 5,
        'YS': 6,
        'YM': 7,
        'YL': 8,
        'YXL': 9,
        'XXXS': 10,
        '3XS': 11,
        '2XS': 12,
        'XXS': 13,
        'XS': 14,
        'S': 15,
        'SM': 16,
        'M': 17,
        'L': 18,
        'LG': 19,
        'XL': 20,
        'XXL': 21,
        '2XL': 22,
        '3XL': 23,
        '4XL': 24,
        'BXXXS': 25,
        'B3XS': 26,
        'B2XS': 27,
        'BXXS': 28,
        'BXS': 29,
        'BS': 30,
        'BM': 31,
        'BL': 32,
        'BXL': 33,
        'MXXXS': 34,
        'M3XS': 35,
        'M2XS': 36,
        'MXXS': 37,
        'MXS': 38,
        'MS': 39,
        'MM': 40,
        'ML': 41,
        'MXL': 42,
        'M2XL': 43,
        'M3XL': 44,
        'M4XL': 45,
        'WXXXS': 46,
        'W3XS': 47,
        'W2XS': 48,
        'WXXS': 49,
        'WXS': 50,
        'WS': 51,
        'WM': 52,
        'WL': 53,
        'WXL': 54,
        'W2XL': 55,
        'W3XL': 56,
        'W4XL': 57,
        'AXXXS': 58,
        'A3XS': 59,
        'A2XS': 60,
        'AXXS': 61,
        'AXS': 62,
        'AS': 63,
        'AM': 64,
        'AL': 65,
        'AXL': 66,
        'A2XL': 67,
        'AXXL': 68,
        'A3XL': 69,
        'A4XL': 70,
      }

      return _.sortBy(_.uniq(_.compact(_.flatMap(this.customUpsellProduct.billing_plans, 'size'))), function (size) {
        return sizeMap[size.toUpperCase()] || 100;
      });
    },
    upsellPrice() {
      if (!this.upsellProduct.selectedBillingPlan) {
        return '';
      }

      let total = this.upsellProduct.selectedBillingPlan.amount;
      return this.formatAsCurrency(total, 2);
    },
  },
  watch: {
    upsellProduct: {
      handler(val) {
        this.findAndSetUpsellPlan();
        // Only fire a preview of the charge if we are actively selecting stuff, not initializing.
        const paymentOptionId = _.get(this.selectedPaymentOption, 'id', null);
        if (paymentOptionId) {
          this.previewCharge(this.previewUrl);
        }
      },
      deep: true
    },
    chosenPlanOrPaymentOption() {
      this.scrollTo('your-selection');
    },
    donationAmount() {
      this.previewCharge(this.previewUrl);
    },
    selectedBillingPlan(newPlan) {
      if (newPlan) {
        this.previewSubscription();
        // Check for duplicates
        this.checkForDuplicateSubs(newPlan);
      }
    },
    currentStep() {
      if (this.currentStep === 'upsells') {
        // Basically, if the insurance is not rendering in a reasonable time frame, we want to skip it.
        let vm = this;
        setTimeout(function () {
          if (vm.insurance.seasonInterruption.state === 'NOT_RENDERED') {
            vm.insurance.seasonInterruption.forceHide = true;
          }

          if (vm.insurance.gapMedical.state === 'NOT_RENDERED') {
            vm.insurance.gapMedical.forceHide = true;
          }

          // At this point, there is a chance we have no more upsells. If that is true, just move on.
          if (!vm.hasUpsells) {
            vm.nextStep();
          }
        }, 3000);
      }

      // Scroll
      if (this.currentStep === 'review') {
        this.scrollTo('review-order');
      } else if (this.currentStep === 'upsells') {
        this.scrollTo('upsells');
      } else if (this.currentStep === 'pay') {
        this.scrollTo('payment-details');
        if (this.useStripe && (!this.hasSavedCard || this.updatingSavedCard) && !this.free) {
          this.$forceNextTick(() => {
            this.setupPaymentElement(this.billingIntentSecret);
          });
        }
      }
    },
    updatingSavedCard() {
      if (this.updatingSavedCard && this.useStripe) {
        this.$forceNextTick(() => {
          this.setupPaymentElement(this.billingIntentSecret);
        });
      }
    },
    loadingPreview() {
      if (!this.loadingPreview) {
        this.checkForAffixOverflow();
      }
    },
    paymentMethod() {
      this.previewCharge(this.previewUrl);
    }
  },
  created() {
    // Debounce slightly to prevent request race conditions
    this.previewCharge = _.debounce(this.previewCharge, 150);
    if (this.recurringSession) {
      this.initWrestlerCouponMap();
      // If there is only 1 recurring plan, select it by default
      if (this.activeBillingPlans.length === 1) {
        this.selectedBillingPlanId = this.activeBillingPlans[0].id;
      }
    } else {
      // init custom upsells
      this.findAndSetUpsellPlan();
      // init upsell photos
      if (this.hasCustomUpsellProduct) {
        this.upsellProduct.photos = _.uniqBy(_.flatMap(this.customUpsellProduct.billing_plans, 'photos'), 'url');
        this.upsellProduct.activePhoto = _.head(this.upsellProduct.photos) || { url: 'https://mktg-cdn.wrestlingiq.com/placeholders/placeholder.png' };
      }
      // If there is only one payment option, select it by default
      if (this.activePaymentOptions.length === 1) {
        this.selectOption(this.activePaymentOptions[0]);
      }
    }
    // We always show coupon forms now instead of making them click
    this.showCouponForm = true;

    history.replaceState({ currentStep: this.currentStep }, '');
    window.addEventListener('popstate', this.popStateHandler);
  },
  destroyed() {
    window.removeEventListener('popstate', this.popStateHandler);
  },
  methods: {
    checkForAffixOverflow() {
      // check for viewport issues so that we do not affix really big purchases and disable the purchase button
      this.$nextTick(() => {
        const element = document.getElementById('summary-card');
        if (element) {
          const rectHeight = element.getBoundingClientRect().height;
          const innerHeight = window.innerHeight;
          // If element inner height is > sreen height - 200, remove the affix class
          if (rectHeight > innerHeight - 250) {
            $('#summary-card').removeClass('affix');
          }
        }
      });
    },
    addUpsellToCart() {
      if (!this.upsellProduct.selectedBillingPlan) {
        return;
      }

      this.upsellProduct.addedToCart = true
    },
    popStateHandler(event) {
      if (event.state && 'currentStep' in event.state) {
        console.log(event.state);
        this.currentStep = event.state.currentStep;
      }
    },
    initWrestlerCouponMap() {
      const couponMap = {};
      this.selectedWrestlers.forEach((w) => {
        couponMap[w.id] = {
          coupon: { code: null },
          previously_tried_coupon_code: '',
          show_form: false,
          auto_apply_coupon: true
        };
      });
      // odd assignment due to https://v2.vuejs.org/v2/guide/reactivity.html
      this.wrestlerCoupons = Object.assign({}, this.wrestlerCoupons, couponMap);
    },
    findAndSetUpsellPlan() {
      if (!this.hasCustomUpsellProduct) {
        return;
      }

      if (this.customUpsellProduct.billing_plans.length === 1) {
        this.upsellProduct.selectedBillingPlan = _.head(this.customUpsellProduct.billing_plans);
        return;
      }

      // First check to make sure we have all the required information to set this
      const mustSelectColors = this.upsellColors.length > 0 && this.upsellProduct.activeColor == null;
      const mustSelectSizes = this.upsellSizes.length > 0 && this.upsellProduct.activeSize == null;
      if (mustSelectSizes|| mustSelectColors) {
        this.upsellProduct.selectedBillingPlan = null;
        return;
      }

      // Build up the filter to find the right billing plan, depending on whether we have colors or sizes
      let predicate = {}
      if (this.upsellSizes.length > 0) {
        predicate.size = this.upsellProduct.activeSize;
      }
      if (this.upsellColors.length > 0) {
        predicate.color = this.upsellProduct.activeColor;
      }

      this.upsellProduct.selectedBillingPlan = _.head(_.filter(this.customUpsellProduct.billing_plans, predicate));
    },
    insuranceOptionPicked(event) {
      const state = event.detail.selectedState;
      const quote = event.detail.quote;

      if (quote.product.name === 'gap-medical') {
        this.insurance.gapMedical.state = state;
        this.insurance.gapMedical.quote = quote;
        if (state !== 'UNKNOWN') {
          this.$refs.gapMedicalProvider.validate(state);
        }
      } else if (quote.product.name === 'season-interruption') {
        this.insurance.seasonInterruption.state = state;
        this.insurance.seasonInterruption.quote = quote;
        if (state !== 'UNKNOWN') {
          this.$refs.seasonInterruptionProvider.validate(state);
        }
      }

      this.checkForAffixOverflow();
    },
    shortDescription(item) {
      // if item.description contains the words 'Sibling discount' just return sibling discount, nothing else
      if (item.description.includes('Sibling discount')) {
        return 'Sibling discount';
      }

      // Use the item.description, but remove the string 'Registration for'
      return item.description.replace('Registration for ', '');
    },
    scrollTo(elementId) {
      this.$nextTick(function () {
        console.log('scrolling to ' + elementId);
        const element = document.getElementById(elementId);
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });

    },
    inputClasses(error) {
      const normalClasses = 'form-control';
      if (!error) {
        return normalClasses;
      }

      return `text-danger border-red-500 ${normalClasses}`;
    },
    previousStep() {
      history.back();
    },
    nextStep() {
      if (this.registrationIsClosed) {
        return;
      }

      if (this.currentStep === 'review') {
        // Update billing profile with saved information
        const params = {
          billing_profile: {
            default_payment_method_reusable: this.reusePaymentMethodInFuture,
            name_on_card: this.nameOnCard,
            address_line_1: this.addressLine1,
            address_line_2: this.addressLine2,
            city: this.city,
            state: this.state,
            zip: this.zip
          },
        };
        this.savingBillingInfo = true;
        const url = this.$apiService.billingProfileUrl(this.billingProfile.id);
        axios.put(url, params)
            .then((response) => {
              this.savingBillingInfo = false;
              if (this.hasUpsells) {
                this.currentStep = 'upsells';
              } else {
                this.currentStep = 'pay';
              }
              history.pushState({ currentStep: this.currentStep }, '');
            })
            .catch((error) => {
              this.savingBillingInfo = false;
              this.error = true;
              this.errorMessage = `Error saving billing info. ${error.toString()}`;
            });
      } else if (this.currentStep === 'upsells') {
        this.currentStep = 'pay';
        history.pushState({ currentStep: this.currentStep }, '');
      } else if (this.currentStep === 'pay') {
        if (this.cashSelected || this.free) {
          this.createCharge('cash', null);
        } else {
          // If we already have a saved card, use that.
          if (this.hasSavedCard && !this.updatingSavedCard) {
            this.tokenized(this.billingProfile.default_payment_method_id);
          } else {
            this.getPaymentToken(this.nameOnCard, this.email, this.zip);
          }
        }
      }
    },
    tokenized(token) {
      this.registering = true;
      if (this.recurringSession) {
        this.createSubscription(token);
      } else {
        this.createCharge('card', token);
      }
    },
    createCharge: _.throttle(function (payment_method_type, payment_method_id) {
      const vm = this;
      if (vm.creatingCharge) {
        return;
      }

      vm.creatingCharge = true;

      const url = vm.$apiService.paymentPlansUrl();
      const params = {
        payment_method_type,
        payment_method_id,
        payment_plan: {
          registrations: vm.buildRegistrationPayload(),
          coupon: vm.couponCode,
          donation_amount: vm.donationAmount || 0,
          insurance_quotes: vm.acceptedInsuranceOffers,
          billing_plans_added: vm.buildProductPayload(),
        },
      };

      axios
          .post(url, params)
          .then((response) => {
            vm.$notificationManager.$emit(
                'show-toast',
                'Registration completed',
                true
            );
            vm.paid = true;
            if (vm.funnel) {
              vm.goToNextFunnelStep();
            } else if (vm.redirect) {
              vm.registering = false;
              window.location.assign(vm.redirect);
            } else {
              vm.registering = false;
              window.location.assign('/calendar');
            }
          })
          .catch((error) => {
            vm.creatingCharge = false;
            vm.registering = false;
            vm.errorMessage = vm.getErrorMessage(error);
            vm.error = true;
          });
    }, 500),
    buildRegistrationPayload() {
      const registrations = [];
      const vm = this;
      vm.selectedWrestlers.forEach((wp) => {
        const payment_option_id = _.get(vm.selectedPaymentOption, 'id', null);
        const mPayload = {
          payment_option_id,
          registerable: {
            id: wp.id,
            type: 'WrestlerProfile',
          },
        };
        registrations.push(mPayload);
      });
      return registrations;
    },
    buildProductPayload() {
      if (!this.upsellProduct.addedToCart || this.upsellProduct.selectedBillingPlan === null) {
        return [];
      }

      return [{
        billing_plan_id: this.upsellProduct.selectedBillingPlan.id,
      }];
    },
    // Subscription methods
    removeCoupon(subRequest) {
      if (subRequest) {
        this.wrestlerCoupons[subRequest.wrestler_profile.id].coupon.code = null;
        // if we explicilty remove, stop showing the previously tried coupon since it was removed and is not invalid
        this.wrestlerCoupons[subRequest.wrestler_profile.id].previously_tried_coupon_code = '';
        // If we explicitly remove a coupon, we need to tell the backend not to apply it automatically
        this.wrestlerCoupons[subRequest.wrestler_profile.id].auto_apply_coupon = false;
        subRequest.coupon = null;
        this.$nextTick(() => {
          this.previewSubscription();
        });
      } else {
        // For removing coupons on other registrations
        this.couponCode = '';
        this.previouslyTriedCouponCode = '';
        this.showCouponForm = true;
        this.$nextTick(() => {
          this.previewCharge(this.previewUrl);
        });
      }
    },
    applyCoupon(subRequest) {
      // If we explicitly add a coupon, we also tell the backend not to apply it other coupons automatically
      this.wrestlerCoupons[subRequest.wrestler_profile.id].auto_apply_coupon = false;
      this.wrestlerCoupons[subRequest.wrestler_profile.id].show_form = false;
      // Cache this so we can make an intelligent ui to the user about whether it was applied
      this.wrestlerCoupons[subRequest.wrestler_profile.id].previously_tried_coupon_code = this.wrestlerCoupons[subRequest.wrestler_profile.id].coupon.code;
      this.previewSubscription();
    },
    checkForDuplicateSubs(billingPlan) {
      this.selectedWrestlers.forEach((wp) => {
        const url = this.$apiService.wrestlerBillingSubscriptions(wp.id);
        axios.get(url)
            .then((response) => {
              const { data } = response;
              let activeSubs = _.filter(data.subscriptions, { status: 'paid' });
              let existingSubBillingPlans = _.flatMap(activeSubs, 'billing_plans');
              let existingProductIds = _.flatMap(existingSubBillingPlans, 'product.id');
              if (_.includes(existingProductIds, billingPlan.product.id)) {
                this.confirmWrestler(wp, billingPlan, existingSubBillingPlans);
              }
            });
      });
    },
    confirmWrestler(wp, billingPlanSelected, existingBillingPlans) {
      let existingNames = _.join(_.compact(_.map(existingBillingPlans, (plan) => {
        if (plan.is_wiq_fee || plan.is_tax_item) {
          return null;
        }

        return `${plan.product.name} - ${plan.name}`;
      })));

      swal.fire({
        type: 'warning',
        title: `${wp.first_name} ${wp.last_name} is already signed up for ${existingNames}`,
        text: `Are you sure you want to sign up ${wp.first_name} ${wp.last_name} for ${billingPlanSelected.product.name} ${billingPlanSelected.name}?`,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonClass: 'btn btn-danger btn-block',
        confirmButtonText: `Cancel sign up`,
        cancelButtonClass: 'btn btn-success btn-block',
        cancelButtonText: `Sign up ${wp.first_name} again`,
        buttonsStyling: false,
      })
          .then((result) => {
            if (result.value) {
              // They've elected to cancel this sign up, bail.
              window.location.assign('/cookie/exit_registration');
            }
          });
    },
    toggleShowCouponFor(subRequest) {
      this.wrestlerCoupons[subRequest.wrestler_profile.id].show_form = !this.wrestlerCoupons[subRequest.wrestler_profile.id].show_form;
    },
    feeFor(plan) {
      if (!plan) {
        return null;
      }

      if (!plan.associated_fee_id) {
        return null;
      }

      return _.find(this.session.product.billing_plans, {
        id: plan.associated_fee_id,
        is_wiq_fee: true,
        is_tax_item: false,
        is_one_time_fee: false,
      });
    },
    taxFor(plan) {
      if (!plan) {
        return null;
      }

      if (!plan.associated_tax_id) {
        return null;
      }

      return _.find(this.session.product.billing_plans, {
        id: plan.associated_tax_id,
        is_wiq_fee: false,
        is_tax_item: true,
        is_one_time_fee: false,
      });
    },
    oneTimeFeeFor(plan) {
      if (!plan) {
        return null;
      }

      if (!plan.associated_one_time_fee_id) {
        return null;
      }

      return _.find(this.session.product.billing_plans, {
        id: plan.associated_one_time_fee_id,
        is_wiq_fee: false,
        is_tax_item: false,
        is_one_time_fee: true
      });
    },
    subscriptionPayload() {
      let vm = this;
      const fee = this.feeFor(this.selectedBillingPlan); // wiq fee
      const tax = this.taxFor(this.selectedBillingPlan);
      const oneTimeFee = this.oneTimeFeeFor(this.selectedBillingPlan); // user created one time fee
      return _.map(this.selectedWrestlers, (w) => {
        let plans = [{
          plan_id: this.selectedBillingPlan.id,
          quantity: 1,
        }];
        if (fee) {
          plans.push({
            plan_id: fee.id,
            quantity: 1,
          });
        }
        if (tax) {
          plans.push({
            plan_id: tax.id,
            quantity: 1,
          });
        }
        if (oneTimeFee) {
          plans.push({
            plan_id: oneTimeFee.id,
            quantity: 1,
          });
        }
        return {
          wrestler_profile_id: w.id,
          billing_plans: plans,
          coupon: vm.wrestlerCoupons[w.id].coupon.code,
          auto_apply_coupon: vm.wrestlerCoupons[w.id].auto_apply_coupon,
        };
      });
    },
    membershipParams(payment_method_id) {
      return {
        membership: {
          client_version: 'web-2.0',
          billable: {
            id: this.guardianId,
            type: this.guardianType,
          },
          subscriptions: this.subscriptionPayload(),
          payment_method_id,
        },
      };
    },
    createSubscription: _.throttle(function (payment_method_id) {
      const vm = this;
      if (vm.creatingSubscription) {
        return;
      }

      vm.creatingSubscription = true;
      axios.post(this.$apiService.membershipsUrl(), this.membershipParams(payment_method_id))
          .then((response) => {
            vm.$notificationManager.$emit('show-toast', 'Registration completed', true);
            vm.paid = true;
            if (vm.funnel) {
              vm.goToNextFunnelStep();
            } else if (vm.redirect) {
              vm.registering = false;
              window.location.assign(vm.redirect);
            } else {
              vm.registering = false;
              window.location.assign('/calendar');
            }
          })
          .catch((error) => {
            vm.creatingSubscription = false;
            vm.registering = false;
            vm.errorMessage = vm.getErrorMessage(error);
            vm.error = true;
            if (vm.clientUpdateRequired(error)) {
              vm.errorMessage = 'A new version of WIQ is needed to complete your request, please refresh the page and retry. Page will auto refresh in 5 seconds...';
              setTimeout(function () {
                window.location.reload();
              }, 5000);
            }
          });
    }, 500),
    previewSubscription() {
      const vm = this;
      vm.loadingPreview = true;
      axios.post(this.$apiService.previewMembershipUrl(), this.membershipParams(''))
          .then((response) => {
            vm.previewRequest = response.data;
            vm.loadingPreview = false;
          })
          .catch((error) => {
            vm.loadingPreview = false;
            vm.errorMessage = vm.parseErrorResponse(error);
            vm.error = true;
            if (vm.clientUpdateRequired(error)) {
              vm.errorMessage = 'A new version of WIQ is needed to complete your request, please refresh the page and retry. Page will auto refresh in 5 seconds...';
              setTimeout(function () {
                window.location.reload();
              }, 5000);
            }
          });
    },
  },
};
</script>
