<template>
    <div class="date-pair-container">
        <date-picker v-model="start" :date="start" placeholder="Start"></date-picker>
        <div class="m-r-sm m-l-sm">
            to
        </div>
        <date-picker v-model="end" :date="end" placeholder="End"></date-picker>
    </div>
</template>

<script>
import DatePicker from './date_picker.vue';

export default {
  name: 'date-pair-picker',
  props: {
    initialStart: {
      type: Object,
      default: null,
    },
    initialEnd: {
      type: Object,
      default: null,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      start: null,
      end: null,
    };
  },
  watch: {
    initialStart(initialStart) {
      this.start = initialStart;
    },
    initialEnd(initialEnd) {
      this.end = initialEnd;
    },
    start() {
      this.emitRangeChanged();
    },
    end() {
      this.emitRangeChanged();
    },
  },
  methods: {
    emitRangeChanged() {
      this.$emit('dateRangeChanged', this.start, this.end);
    },
  },
};
</script>
