// This mixin is used to communicate with the native app.
// It is used to show the save button, notify the app of validation errors, and pop to the root view.

// When you use this mixin please only use the specific methods for sending messages (like askAppToPop) and not the internal methods (like queueNativeMessage). 

import { configureSaveMessage, validationErrorMessage, popNotificationMessage, pushMessage, navigateMessage } from 'webviewembed/native_message_types';
export const appEmbedMix = {
  props: {
    forAppEmbed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isNativeAppReady: false,
      // When the app is not ready, we need to queue up messages to be sent to the native app.
      // This is because the native app is not ready to listen for messages until the class list is added to the app-wrapper element.
      nativeAppMessageQueue: [],
      // When the app is not ready, we need to queue up listeners to be attached to the native app.
      // This is because the native app is not ready to listen for messages until the class list is added to the app-wrapper element.
      nativeListenerQueue: []
    };
  },
  mounted() {
    this.$notificationManager.$on('native-app-ready', () => {
      this.isNativeAppReady = true;
      console.log('Native app ready, sending queued messages');
      this.nativeAppMessageQueue.forEach((message) => {
        console.log('Sending queued message:', message);
        this.sendMessageToNativeApp(message);
      });
      this.nativeListenerQueue.forEach((listener) => {
        console.log('Attaching queued listener:', listener);
        this.attachNativeListener(listener);
      });
    });
  },
  destroyed() {
    this.$notificationManager.$off('native-app-ready');
    // This is a bit of a hack. We need to remove the listeners that were queued up before the native app was ready.
    // But this does not take into account listeners that were added after the native app was ready. Deal with that when we need to.
    this.nativeListenerQueue.forEach((listener) => {
      console.log('Removing queued listener:', listener);
      this.removeNativeListener(listener.type);
    });
  },
  methods: {
    foriOS() {
      return this.forAppEmbed && document.getElementById('app-wrapper')?.classList.contains('ios');
    },
    forAndroid() {
      return this.forAppEmbed && document.getElementById('app-wrapper')?.classList.contains('android');
    },
    queueNativeListener(listener) {
      console.log('queueing native listener', listener);
      console.log('isNativeAppReady', this.isNativeAppReady);
      if (this.isNativeAppReady) {
        this.attachNativeListener(listener);
      } else {
        this.nativeListenerQueue.push(listener);
      }
    },
    attachNativeListener(listener) {
      // Both apps inject the same listener code
      console.log('attaching native listener', listener);
      window.nativeNavigationDelegate.addListener(listener.type, listener.callback);
    },
    removeNativeListener(type) {
      console.log('removing native listener', type);
      window.nativeNavigationDelegate.removeListener(type);
    },
    // Internally we should always use this method to send messages to the native app.
    // If the native app is not ready, the message will be queued up and sent when the native app is ready.
    queueNativeMessage(messageObject) {
      if (this.isNativeAppReady) {
        this.sendMessageToNativeApp(messageObject);
      } else {
        this.nativeAppMessageQueue.push(messageObject);
      }
    },
    // This is the actual method that sends the message to the native app.
    sendMessageToNativeApp(messageObject) {
      if (!this.forAppEmbed) {
        return;
      }

      if (this.foriOS()) {
        window.webkit.messageHandlers.navigationDelegate?.postMessage(messageObject);
      } else if (this.forAndroid()) {
        console.log('Sending message to Android:', messageObject);
        Android.postMessage(JSON.stringify(messageObject));
      }
    },
    // Public interface for sending messages to the native app
    setupNativeSaveButton(saveCallback) {
      if (!this.forAppEmbed) {
        return;
      }

      console.log('queueing native save button message');
      // Tell the native app to show the save button.
      this.queueNativeMessage(configureSaveMessage(true));

      // Attach the native save listener so that the native app can call the saveCallback when the user clicks the save button.
      this.queueNativeListener({ type: 'save', callback: saveCallback });
    },
    notifyAppOfValidationError(errorMessage) {
      this.queueNativeMessage(validationErrorMessage(errorMessage));
    },
    askAppToPop(notification) {
      this.queueNativeMessage(popNotificationMessage(notification));
    },
    askAppToPush(url, title) {
      this.queueNativeMessage(pushMessage(url, title));
    }
  }
};
