<template>
    <div>
        <div>
            <p v-if="error" class="text-danger">
                {{ errorMessage }}
            </p>
        </div>
        <div>
          <div v-if="infoMissing" class="alert alert-danger">
            <h3 class="text-semi m-t-none">
              Missing Required Info
            </h3>
            <p>
              The following wrestlers are marked present, but need updated info before they can be paired.
            </p>
            <ul>
              <li v-for="wp in wrestlersMissingInfo">
                {{ wp.first_name }} {{ wp.last_name }}
              </li>
            </ul>
          </div>
          <div v-if="!infoMissing && !finalized">
            <div class="alert alert-info">
              <h3 class="text-semi m-t-none m-b-none">
                {{ presentCount }} wrestlers checked-in
              </h3>
            </div>
            <ladda-button @lbClicked="finalizeRoster"
                          el-class="btn-primary btn-lg btn-block text-heavy m-t-md"
                          :loading="finalizingRoster">
              Finalize Roster
            </ladda-button>
            <div class="text-center">
              <small>Once finalized, you can't change your roster for this round</small>
            </div>
          </div>
          <div v-if="finalized" class="alert alert-info">
            <strong>The roster is finalized for this round. No further edits can be made.</strong>
          </div>
        </div>
        <spinner v-if="loading"></spinner>
        <div v-if="!loading">
          <div class="form-group m-t-xl">
              <label>Search wrestlers</label>
              <div class="" style="position: relative">
                <input
                    v-model="search"
                    type="text"
                    id="wrestler_search"
                    placeholder="Find by name or weight..."
                    class="form-control"
                    maxlength="25"
                />
                <i
                    @click="search = ''"
                    v-if="search && search.length > 0"
                    class="cursor-pointer fa fa-times color-gray-400"
                    style="
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        transform: translate(-66%, 29%);
                      "
                ></i>
              </div>
            </div>
          <match-check-in-form v-for="wrestler in searchedProfiles"
                               :key="wrestler.id"
                               :wrestler-profile="wrestler"
                               :check-in="checkInFor(wrestler)"
                               :event-id="event.id"
                               :editable="!finalized"
          ></match-check-in-form>
        </div>
        <div>
            <div v-if="infoMissing" class="alert alert-danger">
                <h3 class="text-semi m-t-none">
                    Missing Required Info
                </h3>
                <p>
                    The following wrestlers are marked present, but need updated info before they can be paired.
                </p>
                <ul>
                    <li v-for="wp in wrestlersMissingInfo">
                        {{ wp.first_name }} {{ wp.last_name }}
                    </li>
                </ul>
            </div>
            <div v-if="!infoMissing && !finalized">
                <div class="alert alert-info m-t-xl">
                    <h3 class="text-semi m-t-none m-b-none">
                        {{ presentCount }} wrestlers checked-in
                    </h3>
                </div>
                <ladda-button @lbClicked="finalizeRoster"
                              el-class="btn-primary btn-lg btn-block text-heavy m-t-md"
                              :loading="finalizingRoster">
                    Finalize Roster
                </ladda-button>
                <div class="text-center">
                    <small>Once finalized, you can't change your roster for this round</small>
                </div>
            </div>
            <div v-if="finalized" class="alert alert-info">
                <strong>The roster is finalized for this round. No further edits can be made.</strong>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from './spinner.vue';
import MatchCheckInForm from './match_check_in_form.vue';
import LaddaButton from '../../shared/ladda_button.vue';

export default {
  name: 'match-check-in-list',
  components: { LaddaButton, MatchCheckInForm, Spinner },
  props: [
    'event',
    'teamScore',
  ],
  computed: {
    searchedProfiles() {
      if (!this.search || this.search === "") {
        return this.wrestlers;
      }

      if (isNaN(this.search)) {
        return this.wrestlers.filter((wp) =>
            wp.full_name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.wrestlers.filter((wp) =>
            wp.weight_class.includes(this.search)
        );
      }
    },
    presentCount() {
      const checkInCount = _.countBy(this.checkIns, (check_in) => check_in.status === 'present');

      return checkInCount.true || 0;
    },
    infoMissing() {
      return this.wrestlersMissingInfo.length > 0;
    },
    wrestlersMissingInfo() {
      return _.filter(this.checkedInWrestlers, (wp) => {
        const age = _.get(wp, 'dob', null);
        const experience = _.get(wp, 'experience', null);
        const weight = _.get(wp, 'weight_class', null);
        return (!age || !experience || !weight);
      });
    },
    checkedInWrestlers() {
      const vm = this;
      return _.filter(vm.wrestlers, (wp) => {
        const checkIn = vm.checkInFor(wp);
        if (!checkIn) {
          return false;
        }

        return checkIn.status === 'present';
      });
    },
  },
  data() {
    return {
      loading: false,
      wrestlers: [],
      checkIns: [],
      error: false,
      errorMessage: '',
      finalized: false,
      search: '',
      finalizingRoster: false,
      finalizeError: false,
      finalizeErrorMessage: 'Error finalizing roster',
    };
  },
  created() {
    const vm = this;
    vm.loadAllWrestlers();
    if (vm.teamScore && vm.teamScore.roster_status === 'final') {
      vm.finalized = true;
    }
    vm.$notificationManager.$on('scramble-wrestler-updated', vm.updateWrestler);
    vm.$notificationManager.$on('wrestler-checked-in', vm.updateCheckIn);
  },
  destroyed() {
    const vm = this;
    vm.$notificationManager.$off('scramble-wrestler-updated', vm.updateWrestler);
    vm.$notificationManager.$off('wrestler-checked-in', vm.updateCheckIn);
  },
  methods: {
    updateWrestler(wrestlerId, wrestlerProfile) {
      let vm = this;
      let indexToReplace = null;

      for (let i = 0; i < vm.wrestlers.length; i++) {
        let wp = vm.wrestlers[i];
        if (wp.id === wrestlerId) {
          indexToReplace = i;
          break;
        }
      }

      vm.$set(vm.wrestlers, indexToReplace, wrestlerProfile);
    },
    loadAllWrestlers: _.throttle(function () {
      // load all pages of wrestlers convert them to pending invites
      const vm = this;
      if (vm.loading) {
        return;
      }

      vm.loading = true;
      vm.error = false;

      let url = this.$apiService.filteredWrestlers(false, [], null, 100, 'union');

      vm.$apiService.loadAllPages(url, 'wrestlers')
        .then((objects) => {
          vm.wrestlers = _.sortBy(objects, ['last_name']);
          vm.loadAllCheckIns();
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving roster ${error.toString()}`;
          vm.error = true;
        });
    }, 500),
    loadAllCheckIns() {
      const vm = this;
      const url = vm.$apiService.checkInsUrl(vm.event.id);

      vm.$apiService.loadAllPages(url, 'check_ins')
        .then((objects) => {
          vm.checkIns = objects;
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
          vm.errorMessage = `Error retrieving attendance check-ins ${error.toString()}`;
          vm.error = true;
        });
    },
    checkInFor(wrestler) {
      const vm = this;
      const wrestlerId = wrestler.id;

      return _.find(vm.checkIns, (check) => check.wrestler_profile_id === wrestlerId);
    },
    buildLocalCheckIn(id, status, wrestler) {
      return {
        id,
        status,
        wrestler_profile_id: wrestler.id,
        event_id: this.event.id,
        notes: '',
      };
    },
    updateCheckIn(checkInId, status, wrestlerProfile, notes) {
      const vm = this;
      let checkIn = vm.checkInFor(wrestlerProfile);

      if (!checkIn) {
        checkIn = vm.buildLocalCheckIn(checkInId, status, wrestlerProfile);
      }

      // set the new status regardless of new or updated
      checkIn.status = status;
      checkIn.notes = notes;

      const index = _.findIndex(vm.checkIns, { id: checkInId });
      if (index !== -1) {
        vm.checkIns.splice(index, 1, checkIn);
      } else {
        vm.checkIns.push(checkIn);
      }
    },
    finalizeRoster: _.throttle(function () {
      const vm = this;
      if (vm.finalizingRoster) {
        return;
      }

      vm.finalizingRoster = true;
      const url = vm.$apiService.teamScoreUrl(vm.teamScore.id);
      const params = {
        team_score: {
          roster_status: 'final',
        },
      };
      axios.put(url, params)
        .then((response) => {
          vm.finalizingRoster = false;
          vm.finalized = true;
          vm.$notificationManager.$emit('roster-finalized');
        })
        .catch((error) => {
          vm.finalizingRoster = false;
          vm.finalizeError = true;
        });
    }, 500),
  },
};
</script>
